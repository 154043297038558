import "./NewQuote.css";
import React from "react";

import NewQuoteForm from "../../components/NewQuoteForm";
import { MDBContainer } from "mdbreact";

const NewQuote = () => {
  return (
    <div className="main-content">
      <MDBContainer style={{ paddingTop: "100px", paddingBottom: "100px" }}>
        <NewQuoteForm />
      </MDBContainer>
    </div>
  );
};

export default NewQuote;
