import "./EditQuote.css";
import React from "react";
import EditQuoteForm from "../../components/EditQuoteForm";
import { MDBContainer } from "mdbreact";

const EditQuote = (props) => {
  return (
    <div>
      <MDBContainer fluid style={{ paddingLeft: "7%", paddingRight: "7%" }}>
        <EditQuoteForm {...props} />
      </MDBContainer>
    </div>
  );
};

export default EditQuote;
