import React, { useContext, useState } from "react";
import UserContext from "../../context/userContext";

import "./Navbar.css";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBContainer,
} from "mdbreact";

const Navbar = () => {
  const userContext = useContext(UserContext);

  const [isOpen, setOpen] = useState(false);

  return (
    <div>
      <MDBNavbar dark expand="md" fixed="top" scrolling color="indigo">
        <MDBContainer>
          <MDBNavbarBrand>
            <a href="/">
              <img
                src="https://merchant-pricing-solutions.s3.amazonaws.com/Merchant-Pricing-Tool-Logo-v9.png"
                className="img-fluid"
                width="250px"
                alt="MPS"
              />
            </a>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={() => setOpen(!isOpen)} />
          <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
            {userContext.currentUser() && (
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <MDBIcon icon="user" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu
                      color="indigo"
                      className="dropdown-indigo mr-5"
                    >
                      {/* If user is admin user */}
                      {userContext.currentUser().role === 1 && (
                        <React.Fragment>
                          <MDBDropdownItem href="/Dashboard">
                            <div style={{ fontWeight: "300" }}>Dashboard</div>
                          </MDBDropdownItem>

                          <MDBDropdownItem href="/Admin/Upload">
                            <div style={{ fontWeight: "300" }}>
                              Comparsion Settings
                            </div>
                          </MDBDropdownItem>

                          <MDBDropdownItem href="/Admin/Settings">
                            <div style={{ fontWeight: "300" }}>
                              Cost Settings
                            </div>
                          </MDBDropdownItem>
                          <MDBDropdownItem href={"/logout"}>
                            <div style={{ fontWeight: "300" }}>Log Out</div>
                          </MDBDropdownItem>
                        </React.Fragment>
                      )}

                      {/* If user is support user */}
                      {userContext.currentUser().role === 2 && (
                        <React.Fragment>
                          <MDBDropdownItem href="/Dashboard">
                            <div style={{ fontWeight: "300" }}>Dashboard</div>
                          </MDBDropdownItem>

                          <MDBDropdownItem href={"/logout"}>
                            <div style={{ fontWeight: "300" }}>Log Out</div>
                          </MDBDropdownItem>
                        </React.Fragment>
                      )}
                      {/* If user is sales user (ISO Agent)*/}
                      {userContext.currentUser().role === 3 && (
                        <React.Fragment>
                          <MDBDropdownItem href="/Dashboard">
                            <div style={{ fontWeight: "300" }}>Dashboard</div>
                          </MDBDropdownItem>

                          <MDBDropdownItem href={"/logout"}>
                            <div style={{ fontWeight: "300" }}>Log Out</div>
                          </MDBDropdownItem>
                        </React.Fragment>
                      )}
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="#!">
                    Hi, {userContext.currentUser().firstName}!
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            )}
            {/* If user is not logged in */}
            {!userContext.currentUser() && (
              <MDBNavbarNav right>
                <MDBNavItem>
                  <MDBNavLink to="/login">
                    <MDBIcon icon="sign-in-alt" /> Sign In
                  </MDBNavLink>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBNavLink to="/register">
                    <MDBIcon icon="user" /> Register
                  </MDBNavLink>
                </MDBNavItem>
              </MDBNavbarNav>
            )}
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </div>
  );
};

export default Navbar;
