import "./Home.css";
import React from "react";
import MDBParallaxWrapper from "./MDBParallaxWrapper";

import {
  MDBMask,
  MDBRow,
  MDBCol,
  MDBView,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBModalFooter,
  MDBIcon,
  MDBCardHeader,
  MDBBtn,
  MDBAnimation,
} from "mdbreact";

import Particles from "react-particles-js";

// import Login from "../../pages/Login";

const Home = (props) => {
  return (
    <>
      <MDBParallaxWrapper
        image="https://mdbootstrap.com/img/Photos/Others/images/89.jpg"
        speed={0.5}
        height="100vh"
        type="scale-opacity"
      >
        <div>
          <MDBView className="homepage-container">
            <MDBMask overlay="indigo-strong">
              {" "}
              <Particles
                params={{
                  particles: {
                    number: {
                      value: 80,
                    },
                    size: {
                      value: 3,
                    },
                  },
                  interactivity: {
                    events: {
                      onhover: {
                        enable: true,
                        mode: "repulse",
                      },
                    },
                  },
                }}
              />
            </MDBMask>

            <MDBContainer style={{ paddingTop: "17rem" }}>
              <MDBRow className="">
                <MDBCol className="col-md-12 col-lg-7 text-center text-lg-left white-text mt-5 mt-md-0">
                  <MDBAnimation type="fadeInLeft" delay=".3s">
                    <h1 className="intro-title font-weight-bold mt-sm-5 mt-0 mb-4 wow fadeInLeft">
                      Merchant Pricing Solutions
                    </h1>
                    <h6 className="mb-4 wow intro-subtitle">
                      <b>
                        The Only Merchant Quote pricing tool that you will ever
                        need! Our tool helps you generate quotes for prospective
                        merchants.
                      </b>
                    </h6>{" "}
                    <MDBBtn href="/register" rounded color="white">
                      <b>Register</b>
                    </MDBBtn>
                    <MDBBtn
                      href="/#awesome-features"
                      rounded
                      outline
                      color="white"
                    >
                      Learn More
                    </MDBBtn>
                  </MDBAnimation>
                </MDBCol>

                <MDBCol className="col-md-12 col-lg-5 mt-6 mt-md-0">
                  <MDBAnimation type="fadeInRight" delay=".3s">
                    <img
                      src="https://merchant-pricing-solutions.s3.amazonaws.com/home-image-2.png"
                      className="img-fluid"
                    ></img>
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <div className="white-text text-center text-md-left col-md-6 mt-xl-5 mb-5">
                  <MDBAnimation type="fadeInLeft" delay=".3s">
                    {/* <img
                      src="https://merchant-pricing-solutions.s3.amazonaws.com/login-people-image.png"
                      className="img-fluid mx-auto"
                      alt="FaithConnect"
                      width="400px"
                    ></img> */}
                    {/* <h1 className="display-4 font-weight-bold">Login Portal </h1> */}
                    {/* <hr className="hr-light" />
                    <h6 className="mb-4">
                      Merchant pricing tool which generates quotes for
                      prospective merchants. Please take the time to login in
                      order to utilize the quote tool. This tool will allow you
                      to perform various cost analysis scenearios to better
                      quote merchants.
                    </h6> */}
                    {/* <MDBBtn outline color="white">
                  Learn More
                </MDBBtn> */}
                  </MDBAnimation>
                </div>

                <MDBCol
                  md="6"
                  xl="5"
                  className="mb-4"
                  style={{ paddingTop: "6rem" }}
                >
                  <MDBAnimation type="fadeInRight" delay=".3s">
                    {/* <MDBCard>
                      <MDBCardBody>
                        <MDBCardHeader
                          color="indigo"
                          className="form-header rounded"
                        >
                          <h3 className="my-3">
                            <MDBIcon icon="lock" /> Login:
                          </h3>
                        </MDBCardHeader>

                        <MDBModalFooter>
                          <p className="font-small grey-text d-flex justify-content-end">
                            Not a member?
                            <a
                              href="/register"
                              className="blue-text ml-1 text-center"
                            >
                              Sign Up
                            </a>
                          </p>
                        </MDBModalFooter>
                      </MDBCardBody>
                    </MDBCard>{" "} */}
                  </MDBAnimation>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </MDBView>
        </div>
      </MDBParallaxWrapper>
      {/* <MDBParallaxWrapper
        image="https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(19).jpg"
        speed={0.5}
        height="50vh"
        type="scale"
      />
      <MDBParallaxWrapper
        image="https://mdbootstrap.com/img/Photos/Horizontal/Nature/12-col/img%20(18).jpg"
        speed={2}
        height="50vh"
        type="opacity"
      /> */}

      <MDBContainer id="awesome-features">
        <MDBRow center>
          {/* Section: Features v.4 */}

          <section id="features" className="text-center my-5">
            {/* Section heading */}
            <h2
              className="title font-weight-bold my-5 wow fadeIn"
              data-wow-delay="0.2s"
            >
              <strong>Awesome Features</strong>
            </h2>
            {/* Section description */}
            <p
              className="grey-text w-responsive mx-auto mb-5 wow fadeIn"
              data-wow-delay="0.2s"
            >
              Merchant pricing solutions enables your sales team to offer and
              compare quotes within seconds. Let your team close the deal when
              it's 'hot'. Its intuitive design allows even the most entry level
              sales agent to calculate and accurately price deals at all levels.
            </p>
            {/* Grid row */}
            <div className="row wow fadeIn" data-wow-delay="0.2s">
              {/* Grid column */}
              <div className="col-lg-4 text-center">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-people-carry blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    Lower Overhead
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Quote high volume of deals without having senior staff
                      manually analyzing low probability opportunities.
                    </p>
                  </div>
                </div>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-lg-4 text-center">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-store blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    Increase Customer Acquisition
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Offer and compare quotes within seconds using multiple
                      pricing models.
                    </p>
                  </div>
                </div>
              </div>
              {/* Grid column */}
              {/* Grid column */}
              <div className="col-lg-4 text-center mb-4">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-chart-line blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    Improved Profitability
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Visual aids allow sales agents to quickly see expected
                      margin and simulate profitability of alternative offers.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 text-center mb-4">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-search-dollar blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    Eliminate Guess Work
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Use up to date market data to validate if deals are within
                      market range.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center mb-4">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-clipboard-list blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    Cost Tracking
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Uses your Schedule A to calculate profitability, as well
                      as adding other custom merchant-level expenses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 text-center mb-4">
                <div className="icon-area">
                  <div className="circle-icon">
                    <i className="fas fa-cogs blue-text" />
                  </div>
                  <br />
                  <h5 className="dark-grey-text font-weight-bold mt-2">
                    White Label
                  </h5>
                  <div className="mt-1">
                    <p className="mx-3 grey-text">
                      Your own logo, colors, pricing models, and goals accross
                      all users.
                    </p>
                  </div>
                </div>
              </div>

              {/* Grid column */}
            </div>
            {/* Grid row */}
          </section>
          {/* Section: Features v.4 */}
          {/* Section: Pricing v.5 */}
        </MDBRow>
      </MDBContainer>

      <MDBParallaxWrapper
        image="https://faithconnect.s3.us-east-2.amazonaws.com/images/webservices-features-background.jpg"
        speed={0.2}
        height="80vh"
        type="scroll"
      >
        <div className="flex-center view services-view-features rgba-indigo-light">
          <div className="container py-3">
            {/* Section: Features v.4 */}
            <section className="wow fadeIn" data-wow-delay="0.2s">
              {/* Section heading */}
              <h2
                className="py-3 my-3 pb-5 white-text font-weight-bold text-center wow fadeIn"
                data-wow-delay="0.2s"
              >
                Why choose Merchant Pricing Solutions?
              </h2>

              {/* Grid row */}
              <div className="row mb-3">
                {/* Grid column */}
                <div className="col-md-12 col-lg-6">
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="fas fa-tablet-alt blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        Fully Responsive
                      </h4>
                      <p className="white-text">
                        MPS is functional in all screen sizes and devices, from
                        computers to mobile smartphones.
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="fas fa-level-up-alt blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        Latest Technologies
                      </h4>
                      <p className="white-text">
                        Our application is built using the latest technologies
                        to guarantee best performance.
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="fas fa-code blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        Practical Features
                      </h4>
                      <p className="white-text">
                        Practical features engineered for fast decision-making.
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                </div>
                {/* Grid column */}
                {/* Grid column */}
                <div className="col-md-12 col-lg-6">
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="far fa-object-group blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        White Label
                      </h4>
                      <p className="white-text">
                        Get the look and feel you want customized just for you.
                        If you can dream it, we can build it!
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="fas fa-rocket blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        We Grow with your business
                      </h4>
                      <p className="white-text">
                        From agents to full ISOs, we are here to grow with your
                        business goals.
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                  {/* Grid row */}
                  <div className="row mb-5">
                    <div className="col-2">
                      <a
                        type="button"
                        className="btn-floating white btn-lg my-0"
                      >
                        <i
                          className="fa fa-dollar-sign blue-text"
                          aria-hidden="true"
                        />
                      </a>
                    </div>
                    <div className="col-9 pl-5">
                      <h4 className="font-weight-bold white-text">
                        Affordable
                      </h4>
                      <p className="white-text">
                        Solutions for all size businesses and budgets. User
                        level licenses to enterprise level solutions.
                      </p>
                    </div>
                  </div>
                  {/* Grid row */}
                </div>
                {/* Grid column */}
              </div>
              {/* Grid row */}
            </section>
            {/* Section: Features v.4 */}
          </div>
        </div>
      </MDBParallaxWrapper>
      <div className="container pt-3">
        <section className="text-center my-5 ">
          {/* Section heading */}
          <h2
            className="title font-weight-bold my-5 wow fadeIn"
            data-wow-delay="0.2s"
          >
            <strong>Pricing</strong>
          </h2>
          {/* Section description */}
          <p className="grey-text w-responsive mx-auto mb-5">
            We offer many different pricing packages. Let us know what you need,
            and we can put something together that will fit your budget!
          </p>
          {/* Grid row */}
          <div className="row">
            {/* Grid column */}
            <div className="col-lg-4 col-md-12 mb-5">
              {/* Card */}
              <div className="pricing-card card">
                {/* Content */}
                <div className="card-body">
                  <h5 className="font-weight-bold mt-3">Sales Agent License</h5>
                  {/* Price */}
                  <div className="price pt-0">
                    <h2 className="number orange-text mb-0">25</h2>
                  </div>
                  {/* Price */}
                  <ul className="list-unstyled striped title darker-striped">
                    <li>
                      <p>
                        <strong>Free</strong> Set-Up
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Unlimited</strong> Merchant Quotes
                      </p>
                    </li>
                    <li>
                      <p>Professional Look and Feel</p>
                    </li>
                    <li>
                      <p>1 Year of Storage</p>
                    </li>

                    <li>
                      <p>
                        PDF <strong>Downloadable</strong> Quotes
                      </p>
                    </li>
                  </ul>
                  <a
                    href="mailto:ccustodio@endtimeinvestments.com"
                    className="btn pink-gradient btn-rounded mb-4 white-text"
                  >
                    {" "}
                    Contact Us!
                  </a>
                </div>
              </div>
              {/* Card */}
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-lg-4 col-md-12 mb-5">
              {/* Card */}
              <div
                className="card card-image mb-2"
                style={{
                  backgroundImage:
                    'url("https://mdbootstrap.com/img/Photos/Others/pricing-table%20(8).jpg")',
                }}
              >
                {/* Content */}
                <div className="text-white text-center pricing-card d-flex align-items-center rgba-indigo-strong py-3 px-3 rounded">
                  {/* Content */}
                  <div className="card-body">
                    <h5 className="font-weight-bold mt-2">ISO License*</h5>
                    {/* Price */}
                    <div className="price pt-0">
                      <h2 className="number mb-0">35</h2>
                    </div>
                    {/* Price */}
                    <ul className="list-unstyled striped">
                      <li>
                        <p>
                          <strong>$500</strong> Set-up Fee
                        </p>
                      </li>
                      <li>
                        <p>
                          Multiple <strong>User Roles</strong>
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Schedule A</strong> Management
                        </p>
                      </li>
                      <li>
                        <p>
                          <strong>Market Data</strong> Upload Tool
                        </p>
                      </li>
                      <li>
                        <p>3 Years of Storage</p>
                      </li>
                    </ul>
                    <a
                      href="mailto:ccustodio@endtimeinvestments.com"
                      className="btn btn-rounded btn-outline-white white-text"
                    >
                      {" "}
                      Contact Us!
                    </a>
                  </div>
                </div>
              </div>
              <text className="text-left ">
                *Minimum of 10 licenses required
              </text>
              {/* Card */}
            </div>
            {/* Grid column */}
            {/* Grid column */}
            <div className="col-lg-4 col-md-12 mb-5">
              {/* Card */}
              <div className="pricing-card card">
                {/* Content */}
                <div className="card-body">
                  <h5 className="font-weight-bold mt-3">Enterprise License</h5>
                  {/* Price */}
                  <div className="price pt-0">
                    <h2 className="number orange-text mb-0">--</h2>
                  </div>
                  {/* Price */}
                  <ul className="list-unstyled striped title darker-striped">
                    <li>
                      <p>
                        Fully <strong>Custom</strong> Application
                      </p>
                    </li>
                    <li>
                      <p>
                        <strong>Tailored</strong> Pricing Models
                      </p>
                    </li>
                    <li>
                      <p>Back-End Services</p>
                    </li>
                    <li>
                      <p>Amazon Web Services</p>
                    </li>
                    <li>
                      <p>Engineering Support</p>
                    </li>
                  </ul>
                  <a
                    href="mailto:ccustodio@endtimeinvestments.com"
                    className="btn pink-gradient btn-rounded mb-4 white-text"
                  >
                    Contact Us!
                  </a>
                </div>
              </div>

              {/* Card */}
            </div>
            {/* Grid column */}
          </div>
          {/* Grid row */}

          <h5>
            Have questions? Can't find what you are looking for?{" "}
            <strong>
              <a href="mailto:ccustodio@endtimeinvestments.com">Contact Us!</a>
            </strong>
          </h5>
        </section>

        {/* Section: Pricing v.5 */}
      </div>
    </>
  );
};

export default Home;
