import "./Register.css";
import React from "react";
import Register from "../../components/RegisterUser";

const RegisterPage = (props) => {
  return (
    <div>
      <Register />
    </div>
  );
};

export default RegisterPage;
