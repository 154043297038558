import React, { Component } from "react";

import "./Pdfpage.css";
import PdfGenerator from "../../components/PdfGenerator";

const PdfPage = (props) => {
  return (
    <div>
      <PdfGenerator {...props} />
    </div>
  );
};

export default PdfPage;
