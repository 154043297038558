module.exports = function (state) {
  //------------------------------------------------------------------------//
  //------------------------Cost Settings beginning-------------------------//
  //------------------------------------------------------------------------//

  let goalGrossMargin = Number(state.costGoalMargin);
  let costAuthorization = Number(state.costAuthorization);
  let costBasisPoints = Number(state.costBasisPoints);
  let costChargeback = Number(state.costChargeback);
  let costEquipment = Number(state.costEquipment);
  let costMonthlyGateway = Number(state.costMonthlyGateway);
  let costMonthlyStatement = Number(state.costMonthlyStatement);
  let costPciRegulatory = Number(state.costPciRegulatory);
  let costOtherMonthly = Number(state.costOtherMonthly);
  let costCustomMonthly = Number(state.costCustomMonthly);
  let costCustomPercent = Number(state.costCustomPercent);
  let costCustomPerTransaction = Number(state.costCustomPerTransaction);

  //------------------------------------------------------------------------//
  //---------------------------Cost Settings Ending-------------------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //------------------------Merchant Form beginning-------------------------//
  //------------------------------------------------------------------------//
  let averageTicket = Number(state.estimatedAverageTicket);
  let salesVolume = Number(state.estimatedSalesVolume);
  //------------------------------------------------------------------------//
  //-------------------------Merchant Form End------------------------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //---------------Competition Pricing Information beginning----------------//
  //------------------------------------------------------------------------//
  let effectiveRate = Number(state.effectiveRate);
  let grossMargin = Number(state.grossMargin);
  let discountRate = Number(state.discountRate);
  let transactionPercent = Number(state.transactionPercent);
  let miscTransactions = Number(state.miscTransactions);
  let interchangeCost = Number(state.interchangeCost);
  let duesCost = Number(state.duesCost);
  let competitionSalesVolume = Number(state.salesVolume);
  let competitionAverageTicket = Number(state.averageTicket);
  //------------------------------------------------------------------------//
  //----------------Competition Pricing Information End---------------------//
  //------------------------------------------------------------------------//

  //1 - INTERCHANGE PASSTHRU

  //------------------------------------------------------------------------//
  //-------------Interchange Pricing Form Variables beginning---------------//
  //------------------------------------------------------------------------//
  let interchangeAuthorizationFee = Number(state.interchangeAuthorizationFee);
  let interchangeChargebackFees = Number(state.interchangeChargebackFees);
  let interchangeDiscount = Number(state.interchangeDiscount);
  let interchangeMonthlyEquipmentFees = Number(
    state.interchangeMonthlyEquipmentFees
  );
  let interchangeMonthlyGatewayFees = Number(
    state.interchangeMonthlyGatewayFees
  );
  let interchangeMonthlyPciRegulatoryFees = Number(
    state.interchangeMonthlyPciRegulatoryFees
  );
  let interchangeMonthlyServiceFee = Number(state.interchangeMonthlyServiceFee);
  let interchangeNumberOfTransactions = Number(
    state.interchangeNumberOfTransactions
  );
  let interchangeOtherMonthlyFees = Number(state.interchangeOtherMonthlyFees);
  let interchangeOtherTransactionFees = Number(
    state.interchangeOtherTransactionFees
  );

  //------------------------------------------------------------------------//
  //-------------Interchange Pricing Form Variables End---------------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //--------Interchange Pass-thru SUMMARY Calculations beginning------------//
  //------------------------------------------------------------------------//
  let quoteInterchangeEffectiveRate =
    (interchangeDiscount / 10000 +
      interchangeAuthorizationFee / averageTicket +
      interchangeCost +
      duesCost +
      (interchangeOtherTransactionFees * interchangeNumberOfTransactions) /
        salesVolume) *
    100;

  let quoteInterchangeMargin =
    ((quoteInterchangeEffectiveRate - costBasisPoints) / 100 -
      interchangeCost -
      duesCost -
      costAuthorization / averageTicket -
      costCustomPercent -
      (costCustomPerTransaction / averageTicket) * 100) *
    100;
  // console.log("quoteInterChangeMargin: " + quoteInterchangeMargin);
  // console.log(
  //   "quoteInterchangeEffectiveRate: " + quoteInterchangeEffectiveRate
  // );
  // console.log("costBasisPoints: " + costBasisPoints);
  // console.log("interchangeCost: " + interchangeCost);
  // console.log("duesCost: " + duesCost);
  // console.log("costAuthorization: " + costAuthorization);
  // console.log("averageTicket: " + averageTicket);
  // console.log("costCustomPercent: " + costCustomPercent);
  // console.log("costCustomPerTransaction: " + costCustomPerTransaction);

  let quoteInterchangeEstMonthlyFees =
    (quoteInterchangeEffectiveRate / 100) * salesVolume +
    interchangeChargebackFees +
    interchangeMonthlyEquipmentFees +
    interchangeMonthlyGatewayFees +
    interchangeMonthlyPciRegulatoryFees +
    interchangeMonthlyServiceFee +
    interchangeOtherMonthlyFees;

  // let interchangeCompetitionScoreRatio =
  //   quoteInterchangeEffectiveRate / 0.5 / 100;

  let interchangeCompetitionScoreRatio = effectiveRate / 0.5;

  let interchangeCompetitionScore =
    quoteInterchangeEffectiveRate / 100 / interchangeCompetitionScoreRatio;

  let interchangeProfitabilityratio = grossMargin / 0.5;

  let interchangeProfitabilityScore =
    quoteInterchangeMargin / 100 / interchangeProfitabilityratio;

  //Radio Buttons Market || Goal

  let interchangeProfitabilityGoalScore =
    quoteInterchangeMargin / (goalGrossMargin / 0.5);

  let interchangeEstimatedProfit =
    (quoteInterchangeMargin / 100) * salesVolume +
    interchangeChargebackFees +
    interchangeMonthlyEquipmentFees +
    interchangeMonthlyGatewayFees +
    interchangeMonthlyPciRegulatoryFees +
    interchangeMonthlyServiceFee +
    interchangeOtherMonthlyFees -
    costChargeback -
    costEquipment -
    costMonthlyGateway -
    costMonthlyStatement -
    costOtherMonthly -
    costPciRegulatory -
    costCustomMonthly;
  //------------------------------------------------------------------------//
  //--------Interchange Pass-thru SUMMARY Calculations ENDING---------------//
  //------------------------------------------------------------------------//

  //2 - FLAT RATE

  //------------------------------------------------------------------------//
  //-------------Flat Rate Pricing Form Variables beginning-----------------//
  //------------------------------------------------------------------------//
  let flatRateAuthorizationFee = Number(state.flatRateAuthorizationFee);
  let flatRateChargebackFees = Number(state.flatRateChargebackFees);
  let flatRateDiscount = Number(state.flatRateDiscount);

  let flatRateMonthlyEquipmentFees = Number(state.flatRateMonthlyEquipmentFees);
  let flatRateMonthlyGatewayFees = Number(state.flatRateMonthlyGatewayFees);
  let flatRateMonthlyPciRegulatoryFees = Number(
    state.flatRateMonthlyPciRegulatoryFees
  );
  let flatRateMonthlyServiceFee = Number(state.flatRateMonthlyServiceFee);
  let flatRateNumberOfTransactions = Number(state.flatRateNumberOfTransactions);
  let flatRateOtherMonthlyFees = Number(state.flatRateOtherMonthlyFees);
  let flatRateOtherTransactionFees = Number(state.flatRateOtherTransactionFees);
  //------------------------------------------------------------------------//
  //-------------------Flat Rate Pricing Form Variables End-----------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //--------Flat Rate Pass-thru SUMMARY Calculations beginning------------//
  //------------------------------------------------------------------------//
  let quoteFlatRateEffectiveRate =
    (flatRateDiscount / 100 +
      flatRateAuthorizationFee / averageTicket +
      (flatRateOtherTransactionFees * flatRateNumberOfTransactions) /
        salesVolume) *
    100;

  let quoteFlatRateMargin =
    ((quoteFlatRateEffectiveRate - costBasisPoints) / 100 -
      interchangeCost -
      duesCost -
      costAuthorization / averageTicket -
      costCustomPercent -
      (costCustomPerTransaction / averageTicket) * 100) *
    100;

  let quoteFlatRateEstMonthlyFees =
    (quoteFlatRateEffectiveRate / 100) * salesVolume +
    flatRateChargebackFees +
    flatRateMonthlyEquipmentFees +
    flatRateMonthlyGatewayFees +
    flatRateMonthlyPciRegulatoryFees +
    flatRateMonthlyServiceFee +
    flatRateOtherMonthlyFees;

  let flatRateCompetitionScoreRatio = effectiveRate / 0.5;

  let flatRateCompetitionScore =
    quoteFlatRateEffectiveRate / 100 / flatRateCompetitionScoreRatio;

  let flatRateProfitabilityratio = grossMargin / 0.5;

  let flatRateProfitabilityScore =
    quoteFlatRateMargin / flatRateProfitabilityratio / 100;

  //Radio Buttons Market || Goal
  let flatRateProfitabilityGoalScore =
    quoteFlatRateMargin / (goalGrossMargin / 0.5);

  let flatRateEstimatedProfit =
    (quoteFlatRateMargin / 100) * salesVolume +
    flatRateMonthlyEquipmentFees +
    flatRateMonthlyGatewayFees +
    flatRateMonthlyPciRegulatoryFees +
    flatRateOtherMonthlyFees +
    flatRateChargebackFees +
    flatRateMonthlyServiceFee -
    costChargeback -
    costEquipment -
    costMonthlyStatement -
    costOtherMonthly -
    costPciRegulatory -
    costMonthlyGateway -
    costCustomMonthly;
  //------------------------------------------------------------------------//
  //--------Flat Rate Pass-thru SUMMARY Calculations ENDING---------------//
  //------------------------------------------------------------------------//

  // 3 - FLAT RATE Downgrade

  //------------------------------------------------------------------------//
  //---------Flat Rate Downgrade Pricing Form Variables beginning-----------//
  //------------------------------------------------------------------------//

  let flatRateDowngradeAuthorizationFeeDowngrade = Number(
    state.flatRateDowngradeAuthorizationFeeDowngrade
  );
  let flatRateDowngradeAuthorizationFeeFlatRate = Number(
    state.flatRateDowngradeAuthorizationFeeFlatRate
  );
  let flatRateDowngradeChargebackFees = Number(
    state.flatRateDowngradeChargebackFees
  );
  let flatRateDowngradeDowngrade = Number(state.flatRateDowngradeDowngrade);
  let flatRateDowngradeDowngradePercent = Number(
    state.flatRateDowngradeDowngradePercent
  );
  let flatRateDowngradeEstimateNumberOfTransactions = Number(
    state.flatRateDowngradeEstimateNumberOfTransactions
  );
  let flatRateDowngradeFlatRate = Number(state.flatRateDowngradeFlatRate);
  let flatRateDowngradeFlatRatePercent = Number(
    state.flatRateDowngradeFlatRatePercent
  );
  let flatRateDowngradeMonthlyEquipmentFees = Number(
    state.flatRateDowngradeMonthlyEquipmentFees
  );
  let flatRateDowngradeMonthlyGatewayFees = Number(
    state.flatRateDowngradeMonthlyGatewayFees
  );
  let flatRateDowngradeMonthlyPciRegulatoryFees = Number(
    state.flatRateDowngradeMonthlyPciRegulatoryFees
  );
  let flatRateDowngradeMonthlyServiceFee = Number(
    state.flatRateDowngradeMonthlyServiceFee
  );
  let flatRateDowngradeOtherMonthlyFees = Number(
    state.flatRateDowngradeOtherMonthlyFees
  );

  let flatRateDowngradeOtherTransactionFee = Number(
    state.flatRateDowngradeOtherTransactionFee
  );

  //------------------------------------------------------------------------//
  //------------Flat Rate Downgrade Pricing Form Variables End--------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //----------Flat Rate Downgrade SUMMARY Calculations beginning------------//
  //------------------------------------------------------------------------//
  let quoteFlatRateDowngradeEffectiveRate =
    (flatRateDowngradeDowngrade * flatRateDowngradeDowngradePercent +
      flatRateDowngradeFlatRate * flatRateDowngradeFlatRatePercent) /
      100 +
    (flatRateDowngradeAuthorizationFeeDowngrade *
      flatRateDowngradeDowngradePercent +
      flatRateDowngradeAuthorizationFeeFlatRate *
        flatRateDowngradeFlatRatePercent) /
      averageTicket +
    ((flatRateDowngradeOtherTransactionFee *
      flatRateDowngradeEstimateNumberOfTransactions) /
      salesVolume) *
      100;

  let quoteFlatRateDowngradeMargin =
    ((quoteFlatRateDowngradeEffectiveRate - costBasisPoints) / 100 -
      interchangeCost -
      duesCost -
      costAuthorization / averageTicket -
      costCustomPercent -
      (costCustomPerTransaction / averageTicket) * 100) *
    100;

  let quoteFlatRateDowngradeEstMonthlyFees =
    (quoteFlatRateDowngradeEffectiveRate / 100) * salesVolume +
    flatRateDowngradeChargebackFees +
    flatRateDowngradeMonthlyEquipmentFees +
    flatRateDowngradeMonthlyGatewayFees +
    flatRateDowngradeMonthlyPciRegulatoryFees +
    flatRateDowngradeMonthlyServiceFee +
    flatRateDowngradeOtherMonthlyFees;

  let flatRateDowngradeCompetitionScoreRatio = effectiveRate / 0.5;

  let flatRateDowngradeCompetitionScore =
    quoteFlatRateDowngradeEffectiveRate /
    100 /
    flatRateDowngradeCompetitionScoreRatio;

  let flatRateDowngradeProfitabilityratio = grossMargin / 0.5;

  let flatRateDowngradeProfitabilityScore =
    quoteFlatRateDowngradeMargin / flatRateDowngradeProfitabilityratio / 100;

  //Radio Buttons Market || Goal
  let flatRateDowngradeProfitabilityGoalScore =
    quoteFlatRateDowngradeMargin / (goalGrossMargin / 0.5);

  let flatRateDowngradeEstimatedProfit =
    (quoteFlatRateDowngradeMargin / 100) * salesVolume +
    flatRateDowngradeMonthlyGatewayFees +
    flatRateDowngradeMonthlyPciRegulatoryFees +
    flatRateDowngradeMonthlyServiceFee +
    flatRateDowngradeOtherMonthlyFees +
    flatRateDowngradeMonthlyEquipmentFees +
    flatRateDowngradeChargebackFees -
    costChargeback -
    costEquipment -
    costMonthlyGateway -
    costMonthlyStatement -
    costPciRegulatory -
    costOtherMonthly -
    costCustomMonthly;
  //------------------------------------------------------------------------//
  //----------Flat Rate Downgrade SUMMARY Calculations ENDING---------------//
  //------------------------------------------------------------------------//

  // 4 - TIER PRICING

  //------------------------------------------------------------------------//
  //-------------------TIER Pricing Form Variables beginning----------------//
  //------------------------------------------------------------------------//

  let tierPricingAuthorizationFee = Number(state.tierPricingAuthorizationFee);
  let tierPricingChargebackFees = Number(state.tierPricingChargebackFees);
  let tierPricingEstimateNumberOfTransactions = Number(
    state.tierPricingEstimateNumberOfTransactions
  );
  let tierPricingMidQualified = Number(state.tierPricingMidQualified);
  let tierPricingMidQualifiedPercent = Number(
    state.tierPricingMidQualifiedPercent
  );
  let tierPricingMonthlyEquipmentFees = Number(
    state.tierPricingMonthlyEquipmentFees
  );
  let tierPricingMonthlyGatewayFees = Number(
    state.tierPricingMonthlyGatewayFees
  );
  let tierPricingMonthlyPciRegulatoryFees = Number(
    state.tierPricingMonthlyPciRegulatoryFees
  );
  let tierPricingMonthlyServiceFee = Number(state.tierPricingMonthlyServiceFee);
  let tierPricingNonQualified = Number(state.tierPricingNonQualified);
  let tierPricingNonQualifiedPercent = Number(
    state.tierPricingNonQualifiedPercent
  );
  let tierPricingOtherMonthlyFees = Number(state.tierPricingOtherMonthlyFees);
  let tierPricingOtherTransactionFees = Number(
    state.tierPricingOtherTransactionFees
  );
  let tierPricingQualified = Number(state.tierPricingQualified);
  let tierPricingQualifiedPercent = Number(state.tierPricingQualifiedPercent);

  //------------------------------------------------------------------------//
  //------------Flat Rate Downgrade Pricing Form Variables End--------------//
  //------------------------------------------------------------------------//

  //------------------------------------------------------------------------//
  //----------Tier Pricing SUMMARY Calculations beginning------------//
  //------------------------------------------------------------------------//
  let quoteTierPricingEffectiveRate =
    (tierPricingQualified * tierPricingQualifiedPercent +
      tierPricingMidQualified * tierPricingMidQualifiedPercent +
      tierPricingNonQualified * tierPricingNonQualifiedPercent) /
      100 +
    (tierPricingAuthorizationFee / averageTicket +
      (tierPricingOtherTransactionFees *
        tierPricingEstimateNumberOfTransactions) /
        salesVolume) *
      100;

  let quoteTierPricingMargin =
    ((quoteTierPricingEffectiveRate - costBasisPoints) / 100 -
      interchangeCost -
      duesCost -
      costAuthorization / averageTicket -
      costCustomPercent -
      (costCustomPerTransaction / averageTicket) * 100) *
    100;

  let quoteTierPricingEstMonthlyFees =
    (quoteTierPricingEffectiveRate / 100) * salesVolume +
    tierPricingChargebackFees +
    tierPricingMonthlyEquipmentFees +
    tierPricingMonthlyGatewayFees +
    tierPricingMonthlyPciRegulatoryFees +
    tierPricingMonthlyServiceFee +
    tierPricingOtherMonthlyFees;

  let tierPricingCompetitionScoreRatio = effectiveRate / 0.5;

  let tierPricingCompetitionScore =
    quoteTierPricingEffectiveRate / 100 / tierPricingCompetitionScoreRatio;

  let tierPricingProfitabilityratio = grossMargin / 0.5;

  let tierPricingProfitabilityScore =
    quoteTierPricingMargin / tierPricingProfitabilityratio / 100;

  //Radio Buttons Market || Goal
  let tierPricingProfitabilityGoalScore =
    quoteTierPricingMargin / (goalGrossMargin / 0.5);

  let tierPricingEstimatedProfit =
    (quoteTierPricingMargin / 100) * salesVolume +
    tierPricingChargebackFees +
    tierPricingMonthlyEquipmentFees +
    tierPricingMonthlyServiceFee +
    tierPricingMonthlyGatewayFees +
    tierPricingMonthlyPciRegulatoryFees +
    tierPricingOtherMonthlyFees -
    costChargeback -
    costEquipment -
    costMonthlyStatement -
    costMonthlyGateway -
    costPciRegulatory -
    costOtherMonthly -
    costCustomMonthly;
  //------------------------------------------------------------------------//
  //-----------------Tier Pricing SUMMARY Calculations ENDING---------------//
  //------------------------------------------------------------------------//

  return {
    quoteInterchangeEffectiveRate,
    quoteInterchangeMargin,
    quoteInterchangeEstMonthlyFees,
    interchangeCompetitionScore,
    interchangeProfitabilityScore,
    interchangeProfitabilityGoalScore,
    interchangeEstimatedProfit,
    quoteFlatRateEffectiveRate,
    quoteFlatRateMargin,
    quoteFlatRateEstMonthlyFees,
    flatRateCompetitionScore,
    flatRateProfitabilityScore,
    flatRateProfitabilityGoalScore,
    flatRateEstimatedProfit,
    quoteFlatRateDowngradeEffectiveRate,
    quoteFlatRateDowngradeMargin,
    quoteFlatRateDowngradeEstMonthlyFees,
    flatRateDowngradeCompetitionScore,
    flatRateDowngradeProfitabilityScore,
    flatRateDowngradeProfitabilityGoalScore,
    flatRateDowngradeEstimatedProfit,
    quoteTierPricingEffectiveRate,
    quoteTierPricingMargin,
    quoteTierPricingEstMonthlyFees,
    tierPricingCompetitionScore,
    tierPricingProfitabilityScore,
    tierPricingProfitabilityGoalScore,
    tierPricingEstimatedProfit,
  };
};
