import "./SettingsPage.css";
import React from "react";
import Settings from "../../components/SettingsComponent";

const SettingsPage = (props) => {
  return (
    <div>
      <Settings />
    </div>
  );
};

export default SettingsPage;
