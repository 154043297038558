import "./Upload.css";
import React from "react";
import UploadCsv from "../../components/UploadCsv";

const RegisterPage = (props) => {
  return (
    <div>
      <UploadCsv />
    </div>
  );
};

export default RegisterPage;
