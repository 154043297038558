import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";

import "./UploadCsv.css";
import MDBFileupload from "mdb-react-fileupload";
import { UploadCSV } from "../../services/uploadService";
import {
  MDBIcon,
  MDBSpinner,
  MDBRow,
  MDBCol,
  MDBCardTitle,
  MDBCardBody,
  MDBCardText,
  MDBContainer,
  MDBCard,
  MDBBtn,
  MDBModal,
  MDBCardImage,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";

class UploadCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      redirectLogin: false,
      response: "Please select a CSV file to upload",
      loading: false,
    };
  }

  componentDidMount() {
    const token = localStorage.getItem("jwtToken");
    if (token == null) {
      this.setRedirectLogin();
      this.renderRedirectLogin();
    }
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  setRedirectLogin = () => {
    this.setState({
      redirectLogin: true,
    });
  };

  renderRedirectLogin = () => {
    if (this.state.redirectLogin) {
      return <Redirect to="/" />;
    }
  };

  getUploadedFile = (file) => {
    this.setState(
      {
        selectedFile: file,
      },
      () => {
        console.log(this.state);
      }
    );
  };

  onClickHandler = () => {
    if (this.state.selectedFile === null) {
      this.toggle();
    } else {
      this.setState(
        {
          loading: true,
        },
        () => {
          const data = new FormData();
          data.append("file", this.state.selectedFile);
          //Post CSV File to API
          UploadCSV(data)
            .then((res) => {
              if (res.status !== 200) {
                console.log(res);
                this.setState(
                  {
                    response: res.data,
                  },
                  () => {
                    this.setState({ loading: false });
                    this.toggle();
                  }
                );
              } else {
                this.setState(
                  {
                    response: res.data,
                  },
                  () => {
                    this.setState({ loading: false });
                    this.toggle();
                  }
                );
              }
            })
            .catch((err) => {
              alert(err);
            });
        }
      );
    }
  };

  render() {
    return (
      <div>
        {this.state.loading == true ? (
          <MDBContainer style={{ paddingTop: "" }}>
            <MDBRow style={{ padding: "300px" }} className="text-center">
              <MDBCol>
                <MDBSpinner big />
              </MDBCol>
            </MDBRow>{" "}
          </MDBContainer>
        ) : (
          <MDBContainer style={{ paddingTop: "100px" }} className="text-center">
            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>
                File Upload Status{" "}
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow center>
                  {this.state.response ==
                  "Comparison Tables Were Updated Successfully!" ? (
                    <MDBIcon
                      className="green-text"
                      size="3x"
                      icon="check-circle"
                    />
                  ) : (
                    <MDBIcon
                      className="amber-text"
                      size="3x"
                      icon="exclamation-triangle"
                    />
                  )}
                </MDBRow>
                <p className="mt-3">{this.state.response}</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>{" "}
            <MDBRow className=" pb-3">
              <MDBCol>
                <MDBCard narrow className="pt-5 mb-5">
                  <MDBCardImage
                    className="view view-cascade gradient-card-header primary-color"
                    tag="div"
                  >
                    <h3 className="h3-responsive">Table Management</h3>
                  </MDBCardImage>
                  <MDBCardBody>
                    <MDBCardTitle className="indigo-text h5 m-4">
                      Sample File and Instructions
                    </MDBCardTitle>
                    <MDBCardText>
                      Use this tool to update the default comparison tables with
                      custom data. Please find a sample csv file below.
                    </MDBCardText>

                    <MDBCol
                      className="d-flex justify-content-center mt-4"
                      md="12"
                    >
                      <MDBCol md="3" className="d-flex justify-content-around">
                        <MDBBtn href="/sample.csv" color="primary">
                          Sample CSV
                        </MDBBtn>
                      </MDBCol>
                    </MDBCol>
                    <MDBCardTitle className="card-title h4 pb-2 pt-5">
                      <strong>Competition Comparison Table File Upload</strong>
                    </MDBCardTitle>

                    <MDBFileupload
                      maxFileSize="3M"
                      allowedFileExtensions={["csv"]}
                      getValue={this.getUploadedFile}
                      showRemove={false}
                    />
                    <MDBBtn onClick={this.onClickHandler}>Update Table</MDBBtn>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
      </div>
    );
  }
}

export default UploadCsv;
