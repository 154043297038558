import "./Loading.css";
import React, { Component } from "react";
import { MDBContainer, MDBCol, MDBSpinner, MDBRow } from "mdbreact";

class Loading extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <MDBContainer style={{ padding: "50px" }}>
          <MDBRow>
         
            <MDBCol
              style={{ textAlign: "center", paddingTop: "200px", paddingBottom:"200px" }}
              middle
              md="12"
            >
              <MDBSpinner big />
              <h2 style={{ paddingTop: "20px", color: "#3f51b5 " }}>
                {this.props.message
                  ? this.props.message + "..."
                  : "Loading Page..."}
              </h2>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </div>
    );
  }
}

export default Loading;
