import http from "./httpService";

const apiEndPoint = "/settings";

export function getCostSettings(id) {
  return http.get(apiEndPoint + "/cost/retrieve/" + id);
}

export function UpdateCostSettings(id, json) {
  return http.post(apiEndPoint + "/cost/update/" + id, json, {
    headers: { "Content-Type": "application/json" },
  });
}
