import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "../pages/Home";
import Register from "../pages/Register";
import Login from "../pages/LoginPage";
import Dashboard from "../pages/Dashboard";
import NewQuote from "../pages/NewQuote";
import EditQuote from "../pages/EditQuote";
import Upload from "../pages/Upload";
import Settings from "../pages/SettingsPage";
import PdfPage from "../pages/PdfPage";
import Logout from "../components/Logout";
import ProtectedRoute from "./../components/common/protectedRoutes";

class Routes extends React.Component {
  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/Login" component={Login} />
        <Route exact path="/Logout" component={Logout} />
        <Route exact path="/Register" component={Register} />
        <Route exact path="/Dashboard" component={Dashboard} />
        <Route exact path="/Quote/New" component={NewQuote} />
        <Route
          exact
          path="/Quote/:id/Edit"
          component={(props) => <EditQuote {...props} />}
        />

        {/*Admin Protected Routes */}
        <ProtectedRoute exact path="/Admin/Upload" component={Upload} />
        <ProtectedRoute exact path="/Admin/Settings" component={Settings} />

        <Route path="/Dashboard" component={Dashboard} />
        <Route
          exact
          path="/Quote/:id/pdf"
          component={(props) => <PdfPage {...props} />}
        />
        <Redirect to="/Dashboard" />
      </Switch>
    );
  }
}

export default Routes;
