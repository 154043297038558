import React, { Component } from "react";
import MDBCustomStep from "../../components/Step";
import { Redirect } from "react-router-dom";
import { registerQuote } from "../../services/quoteService";
import {
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSelect,
  MDBCardImage,
} from "mdbreact";

import "./NewQuoteForm.css";

class NewQuoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isLoggedIn: false,
      formActivePanel3: 1,
      formActivePanel1Changed: false,
      redirectLogin: false,
      redirectQuote: false,
      cityOptions: [
        {
          text: "Miami",
          value: "1",
        },
        {
          text: "Tampa",
          value: "2",
        },
        {
          text: "Orlando",
          value: "3",
        },
      ],
      previousProcessorPricingModelOptions: [
        {
          text: "Interchange Plus",
          value: "1",
        },
        {
          text: "Flat Rate",
          value: "2",
        },
        {
          text: "Flat Rate Plus Downgrade",
          value: "3",
        },
        {
          text: "Tier Pricing",
          value: "4",
        },
      ],

      stateOptions: [
        { text: "AL", value: "AL" },
        { text: "AK", value: "AK" },
        { text: "AZ", value: "AZ" },
        { text: "AR", value: "AR" },
        { text: "CA", value: "CA" },
        { text: "CO", value: "CO" },
        { text: "CT", value: "CT" },
        { text: "DE", value: "DE" },
        { text: "FL", value: "FL" },
        { text: "GA", value: "GA" },
        { text: "HI", value: "HI" },
        { text: "ID", value: "ID" },
        { text: "IL", value: "IL" },
        { text: "IN", value: "IN" },
        { text: "IA", value: "IA" },
        { text: "KS", value: "KS" },
        { text: "KY", value: "KY" },
        { text: "LA", value: "LA" },
        { text: "ME", value: "ME" },
        { text: "MD", value: "MD" },
        { text: "MA", value: "MA" },
        { text: "MI", value: "MI" },
        { text: "MN", value: "MN" },
        { text: "MS", value: "MS" },
        { text: "MO", value: "MO" },
        { text: "MT", value: "MT" },
        { text: "NE", value: "NE" },
        { text: "NV", value: "NV" },
        { text: "NH", value: "NH" },
        { text: "NJ", value: "NJ" },
        { text: "NM", value: "NM" },
        { text: "NY", value: "NY" },
        { text: "NC", value: "NC" },
        { text: "ND", value: "ND" },
        { text: "OH", value: "OH" },
        { text: "OK", value: "OK" },
        { text: "OR", value: "OR" },
        { text: "PA", value: "PA" },
        { text: "RI", value: "RI" },
        { text: "SC", value: "SC" },
        { text: "SD", value: "SD" },
        { text: "TN", value: "TN" },
        { text: "TX", value: "TX" },
        { text: "UT", value: "UT" },
        { text: "VT", value: "VT" },
        { text: "VA", value: "VA" },
        { text: "WA", value: "WA" },
        { text: "WV", value: "WV" },
        { text: "WI", value: "WI" },
        { text: "WY", value: "WY" },
      ],

      mccCategoryOptions: [
        {
          text: "Amusement and Entertainment",
          value: "1",
        },
        {
          text: "Associations",
          value: "2",
        },
        {
          text: "Business Services",
          value: "3",
        },
        {
          text: "Clothing and Accessories",
          value: "4",
        },
        {
          text: "Contracted Services",
          value: "5",
        },
        {
          text: "Direct Marketing",
          value: "6",
        },
        {
          text: "Eating and Drinking Places",
          value: "7",
        },
        {
          text: "Education",
          value: "8",
        },
        {
          text: "Government Services",
          value: "9",
        },
        {
          text: "Home and Garden",
          value: "10",
        },
        {
          text: "Miscellaneous Stores",
          value: "11",
        },
        {
          text: "Personal Services",
          value: "12",
        },
        {
          text: "Professional Services",
          value: "13",
        },
        {
          text: "Repair",
          value: "14",
        },
        {
          text: "Retail Store",
          value: "15",
        },
        {
          text: "Telecommunications",
          value: "16",
        },
        {
          text: "Transportation",
          value: "17",
        },
        {
          text: "Utilities",
          value: "18",
        },
        {
          text: "Wholesale",
          value: "19",
        },
      ],
      merchantTypeOptions: [
        {
          text: "Retail",
          value: "1",
        },
        {
          text: "E-Commerce",
          value: "2",
        },
        {
          text: "Both",
          value: "3",
        },
      ],
      mccCode: [],
      firstStepperStatus: "valid",
      secondStepperStatus: "valid",
      thirdStepperStatus: "valid",
    };
  }

  componentDidMount() {}

  setRedirectQuote = () => {
    this.setState({
      redirectQuote: true,
    });
  };

  renderRedirectQuote = () => {
    if (this.state.redirectQuote) {
      return <Redirect to={`${this.state.quoteId}/Edit`} />;
    }
  };

  swapFormActive = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  handleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  calculateAutofocus = (a) => {
    if (this.state["formActivePanel" + a + "Changed"]) {
      return true;
    }
  };

  handleCitySelectChange = (city) => {
    this.setState({ city: city });
  };

  handlePreviousProcessorPricingModelOptions = (
    previousProcessorPricingModelOptionsType
  ) => {
    this.setState({ previousProcessorPricingModelOptionsType });
  };

  handleStateSelectChange = (state) => {
    if (this.state.state == undefined) {
      this.setState({ state: state });
    } else {
      this.setState(
        {
          state: state,
        },
        () => {
          if (this.state.state.length > 0 && this.state.state != "invalid") {
            this.setState({ secondStepperStatus: "valid" });
          }
        }
      );
    }
  };

  handleZipSelectChange = (zipcode) => {
    this.setState({ zipcode: zipcode });
  };

  handleMccCategorySelectChange = (mccCategory) => {
    switch (mccCategory) {
      case "Amusement and Entertainment":
        this.setState({
          mccDescriptionOptions: [
            { text: "Sporting and Recreational Camps", value: "7032-1" },
            { text: "Trailer Parks and Campgrounds", value: "7032-2" },
            {
              text: "Motion Picture & Video Tape Production and Distribution",
              value: "7829",
            },
            {
              text: "Recreation Services - Not Elsewhere Classified",
              value: "7999",
            },
            { text: "Motion Picture Theater", value: "7832-1" },
            { text: "DVD/Video Tape Rental Stores", value: "7832-2" },
            { text: "Dance Halls, Studios & Schools", value: "7911" },
            { text: "Bands, Orchestras & Misc Entertainment", value: "7929" },
            { text: "Billiards & Pool Establishments", value: "7941-1" },
            { text: "Bowling Alleys", value: "7941-2" },
            { text: "Commercial Sports", value: "7941-3" },
            { text: "Professional Sports Clubs", value: "7941-4" },
            { text: "Athletic Fields", value: "7941-5" },
            { text: "Sports Promoters", value: "7941-6" },
            { text: "Public Golf Courses", value: "7941-7" },
            {
              text: "Theatrical Producers (except Motion Pictures) and Ticket Agencies",
              value: "7922",
            },
          ],
        });
        break;
      case "Associations":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Charitable and Social Service Organizations",
              value: "8398",
            },
            {
              text: "Civic, Social, and Fraternal Associations",
              value: "8641",
            },
            { text: "Political Organizations", value: "8651" },
            { text: "Religious Organizations", value: "8661" },
            { text: "Automobile Associations", value: "8699" },
          ],
        });
        break;
      case "Business Services":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Miscellaneous Publishing and Printing Services",
              value: "2741-1",
            },
            {
              text: "Typesetting, Plate Making and Related Services",
              value: "2741-2",
            },
            { text: "Advertising Services", value: "7311" },
            {
              text: "Commercial Photography, Art, and Graphics",
              value: "7333-1",
            },
            {
              text: "Photofinishing Laboratories and Photo Developing",
              value: "7333-2",
            },
            { text: "Quick Copy, Reproduction Service", value: "7333-3" },
            {
              text: "Computer Programming, Data Processing, and Integrated Systems Design Services",
              value: "7372-1",
            },
            { text: "Information Retrieval Services", value: "7372-2" },
            { text: "Stenographic Service", value: "7339" },
            {
              text: "Exterminating and Disinfecting Services",
              value: "7349-1",
            },
            {
              text: "Cleaning, Maintenance, and Janitorial Services",
              value: "7349-2",
            },
            {
              text: "Specialty Cleaning, Polishing, and Sanitation Preparations",
              value: "7349-3",
            },
            {
              text: "Management, Consulting, and Public Relations Services",
              value: "7392",
            },
            {
              text: "Detective Agencies, Protective Agencies, and Security Services, including Armored Cars and Guard Dogs",
              value: "7393",
            },
            {
              text: "Business Services - Not Elsewhere Classified",
              value: "7399",
            },
          ],
        });
        break;
      case "Clothing and Accessories":
        this.setState({
          mccDescriptionOptions: [
            { text: "Children's and Infants' Wear Stores", value: "5691-1" },
            { text: "Family Clothing Stores", value: "5691-2" },
            { text: "Furriers & Fur Shops", value: "5691-3" },
            {
              text: "Men's & Boys' Clothing and Accessory Stores",
              value: "5691-4",
            },
            { text: "Men's and Women's Clothing Stores", value: "5691-5" },
            {
              text: "Miscellaneous Apparel and Accessory Stores",
              value: "5691-6",
            },
            { text: "Shoe Stores", value: "5691-7" },
            { text: "Sports and Riding Apparel Stores", value: "5691-8" },
            {
              text: "Tailors, Seamstresses, Mending, and Alterations",
              value: "5691-9",
            },
            { text: "Wig & Toupee Shops", value: "5691-10" },
            {
              text: "Women's Accessory and Specialty Stores",
              value: "5691-11",
            },
            { text: "Women's Ready-to-Wear Stores", value: "5691-12" },
          ],
        });
        break;
      case "Contracted Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Veterinary Services", value: "742" },
            { text: "Agricultural Cooperatives", value: "763" },
            { text: "Landscaping and Horticultural Services", value: "780" },
            { text: "Carpentry", value: "1520-1" },
            { text: "Contractors, Concrete", value: "1520-2" },
            { text: "Electrical Contractors", value: "1520-3" },
            {
              text: "General Contractor/Residential Building",
              value: "1520-4",
            },
            {
              text: "Heating, Plumbing, and Air Conditioning Contractors",
              value: "1520-5",
            },
            {
              text: "Masonry, Stonework, Tile Setting, Plastering, and Insulation Contractors",
              value: "1520-6",
            },
            {
              text: "Roof, Siding, and Sheet Metal Work Contractors",
              value: "1520-7",
            },
            {
              text: "Special Trade Contractor - Not Elsewhere Classified",
              value: "1520-8",
            },
          ],
        });
        break;
      case "Direct Marketing":
        this.setState({
          mccDescriptionOptions: [
            { text: "Catalog Merchant", value: "5964" },
            { text: "Combined Catalog and Retail Merchant", value: "5969-1" },
            { text: "Continuity/Subscription Merchants", value: "5969-2" },
            { text: "Inbound Telemarketing Merchants", value: "5969-3" },
            { text: "Travel Related Arrangement Services", value: "5969-4" },
            { text: "Insurance Services", value: "5969-5" },
            {
              text: "Direct Marketing/Direct Marketers - Not Elsewhere Classified",
              value: "5969-6",
            },
            {
              text: "Direct Selling Establishments/Door to Door Sales",
              value: "5969-7",
            },
            { text: "Mail Order", value: "5969-8" },
            { text: "Outbound Telemarketing Merchant", value: "5969-9" },
          ],
        });
        break;
      case "Eating and Drinking Places":
        this.setState({
          mccDescriptionOptions: [
            { text: "Caterers - Prepare & Delivery", value: "5811-1" },
            { text: "Eating Places and Restaurants", value: "5811-2" },
            {
              text: "Quick Payment Service-Fast Food Restaurants",
              value: "5811-3",
            },
            {
              text: "Drinking Places (Alcoholic Beverages) - Bars, Taverns, Nightclubs, Cocktail Lounges, and Discotheques",
              value: "5813",
            },
          ],
        });
        break;
      case "Education":
        this.setState({
          mccDescriptionOptions: [
            { text: "Business and Secretarial Schools", value: "8299-1" },
            {
              text: "Colleges, Universities, Professional Schools, and Junior Colleges",
              value: "8299-2",
            },
            { text: "Correspondence Schools", value: "8299-3" },
            { text: "Elementary and Secondary Schools", value: "8299-4" },
            {
              text: "Schools and Educational Services - Not Elsewhere Classified",
              value: "8299-5",
            },
            { text: "Trade and Vocational Schools", value: "8299-6" },
          ],
        });
        break;
      case "Government Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Tax Payments", value: "9311" },
            {
              text: "Court Costs, including Alimony and Child Support",
              value: "9399-1",
            },
            { text: "Bail & Bond Payments", value: "9399-2" },
            {
              text: "Government Services - Not Elsewhere Classified",
              value: "9399-3",
            },
            { text: "U.S. Federal Government Agencies", value: "9399-4" },
          ],
        });
        break;
      case "Home and Garden":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Furniture, Home Furnishings, and Equipment Stores, except Appliances",
              value: "5712",
            },
            {
              text: "Drapery, Window Covering, and Upholstery Stores",
              value: "5719-1",
            },
            {
              text: "Fireplaces, Fireplace Screens, and Accessories Stores",
              value: "5719-2",
            },
            { text: "Floor Coverings and Rugs", value: "5719-3" },
            {
              text: "Miscellaneous Home Furnishing Specialty Stores",
              value: "5719-4",
            },
            { text: "Household Appliance Stores", value: "5722" },
          ],
        });
        break;
      case "Miscellaneous Stores":
        this.setState({
          mccDescriptionOptions: [
            { text: "Electronics Stores", value: "5732" },
            {
              text: "Music Stores - Musical Instruments, Pianos, and Sheet Music",
              value: "5733",
            },
            { text: "Record Stores", value: "5735" },
            {
              text: "Sewing, Needlework, Fabric, and Piece Good Stores",
              value: "5970-1",
            },
            { text: "Artist Supply and Craft Stores", value: "5970-2" },
            { text: "Bicycle Shop-Sales and Services", value: "5941-1" },
            { text: "Sporting Goods Stores", value: "5941-2" },
            {
              text: "Stationery, Office, and School Supply Stores",
              value: "5943",
            },
            {
              text: "Jewelry, Watch, Clock, and Silverware Stores",
              value: "5944",
            },
            { text: "Hobby, Toy, and Game Stores", value: "5945" },
            { text: "Cosmetic Stores", value: "5977-1" },
            { text: "Electric Razor Stores Sales & Services", value: "5977-2" },
            { text: "Book Stores", value: "5942" },
            { text: "Camera and Photographic Supply Stores", value: "5999-1" },
            { text: "Florists", value: "5999-2" },
            {
              text: "Gift, Card, Novelty, and Souvenir Stores",
              value: "5999-3",
            },
            { text: "Glassware and Crystal Stores", value: "5999-4" },
            {
              text: "Hearing Aids - Sales, Service, and Supplies",
              value: "5999-5",
            },
            { text: "Luggage and Leather Goods Stores", value: "5999-6" },
            {
              text: "Miscellaneous & Specialty Retail Stores",
              value: "5999-7",
            },
            { text: "News Dealers & Newsstands", value: "5999-8" },
            {
              text: "Orthopedic Goods and Prosthetic Devices",
              value: "5999-9",
            },
            { text: "Pet Shops, Pet Food, and Supplies", value: "5999-10" },
            { text: "Religious Goods Stores", value: "5999-11" },
            {
              text: "Swimming Pools - Sales, Supplies, and Services",
              value: "5999-12",
            },
            { text: "Tent and Awning Stores", value: "5999-13" },
            {
              text: "Typewriter Stores - Sales, Service, and Rentals",
              value: "5999-14",
            },
            { text: "Wrecking and Salvage Yards", value: "5999-15" },
          ],
        });
        break;
      case "Personal Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Buying and Shopping Services and Clubs", value: "7299-1" },
            {
              text: "Counseling Services - Debt, Marriage, and Personal",
              value: "7299-2",
            },
            {
              text: "Miscellaneous Personal Services - Not Elsewhere Classified",
              value: "7299-3",
            },
          ],
        });
        break;
      case "Professional Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Chiropractors", value: "8041" },
            { text: "Dentists and Orthodontists", value: "8021" },
            {
              text: "Doctors and Physicians - Not Elsewhere Classified",
              value: "8011",
            },
            { text: "Hospitals", value: "8099-1" },
            { text: "Medical and Dental Laboratories", value: "8099-2" },
            {
              text: "Medical Services Health Practitioners - Not Elsewhere Classified",
              value: "8099-3",
            },
            { text: "Nursing and Personal Care Facilities", value: "8099-4" },
            {
              text: "Opticians, Optical Goods and Eyeglasses",
              value: "8099-5",
            },
            { text: "Optometrists and Ophthalmologists", value: "8099-6" },
            { text: "Osteopathic Physicians", value: "8099-7" },
            { text: "Podiatrists and Chiropodists", value: "8099-8" },
            { text: "Legal Services and Attorneys", value: "8111" },
            { text: "Child Care Services", value: "8351" },
            {
              text: "Accounting, Auditing, and Bookkeeping Services",
              value: "8931",
            },
            {
              text: "Architectural, Engineering, and Surveying Services",
              value: "8911",
            },
            {
              text: "Professional Services - Not Elsewhere Classified",
              value: "8999-1",
            },
            { text: "Testing Laboratories (Not Medical)", value: "8999-2" },
          ],
        });
        break;
      case "Repair":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Furniture - Reupholster, Repair, and Refinishing",
              value: "7641",
            },
            {
              text: "Air Conditioning and Refrigeration Repair Shops",
              value: "7699-1",
            },
            {
              text: "Electrical and Small Appliance Repair Shops",
              value: "7699-2",
            },
            { text: "Electronic Repair Shops", value: "7699-3" },
            {
              text: "Miscellaneous Repair Shops and Related Services",
              value: "7699-4",
            },
            { text: "Watch, Clock, and Jewelry Repair Shops", value: "7699-5" },
            { text: "Welding Services", value: "7699-6" },
          ],
        });
        break;
      case "Retail Store":
        this.setState({
          mccDescriptionOptions: [
            { text: "Candy, Nut, and Confectionery Stores", value: "5499-1" },
            { text: "Dairy Product Stores", value: "5499-2" },
            { text: "Freezer & Locker Meat Provisions", value: "5499-3" },
            {
              text: "Miscellaneous Food Stores-Convenience Stores and Specialty Markets",
              value: "5499-4",
            },
          ],
        });
        break;
      case "Telecommunications":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Telecommunication Equipment and Telephone Sales",
              value: "4812",
            },
          ],
        });
        break;
      case "Transportation":
        this.setState({
          mccDescriptionOptions: [
            { text: "Taxicabs and Limousines", value: "4121" },
            {
              text: "Motor Freight Carriers and Trucking-Local and Long Distance, Moving & Storage Companies, and Local Delivery",
              value: "4214",
            },
          ],
        });
        break;
      case "Utilities":
        this.setState({
          mccDescriptionOptions: [
            { text: "Electric, Gas, Water, and Sanitary", value: "4900" },
          ],
        });
        break;
      case "Wholesale":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Computers, Computer Peripheral Equipment, and Software",
              value: "5045",
            },
            {
              text: "Dental/Laboratory/Medical/Ophthalmic Hospital Equipment and Supplies",
              value: "5047",
            },
            {
              text: "Precious Stones, Metals, Watches and Jewelry",
              value: "5094",
            },
            { text: "Durable goods - Not Elsewhere Classified", value: "5099" },
            {
              text: "Books, Periodicals, and Newspapers Distributors",
              value: "5192",
            },
            {
              text: "Florist Suppliers, Nursery Stock & Flower",
              value: "5193",
            },
          ],
        });
        break;
      default:
      // code block
    }

    this.setState({ mccCategory: mccCategory });
  };

  handleMccCodeSelectChange = (mccCode) => {
    if (mccCode.length > 0) {
      let code = mccCode[0].substring(0, 4);
      this.setState({ mccCode: code });
    }
  };

  handleMCCDescriptionSelectChange = (mccCategoryDescription) => {
    this.setState(
      {
        mccCategoryDescription,
      },
      () => {
        if (
          (this.state.dba != "invalid" || this.state.dba.length > 0) &&
          this.state.mccCategory != "invalid" &&
          this.state.mccCategoryDescription != "First Select MCC Category"
        ) {
          this.setState({ firstStepperStatus: "valid" });
        }
      }
    );
  };

  handleMerchantTypeSelectChange = (merchantType) => {
    this.setState({ merchantType });
  };

  handleMerchantCheckboxChange = () => {
    this.setState({
      merchantServices: !this.state.merchantServices,
    });
    // this.refs[e.target].checked = !this.refs[e.target].checked;
  };

  handlePreviousProcessorCheckboxChange = () => {
    this.setState({
      previousProcessor: !this.state.previousProcessor,
    });
    // this.refs[e.target].checked = !this.refs[e.target].checked;
  };

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.setState(
          { cardNotPresentPercentage: 100 - this.state.cardPresentPercentage },
          () => {
            if (
              (this.state.dba != "invalid" || this.state.dba.length > 0) &&
              this.state.mccCategory != "invalid" &&
              this.state.mccCategoryDescription != "invalid"
            ) {
              this.setState({
                firstStepperStatus: "valid",
              });
            }

            if (this.state.estimatedSalesVolume != undefined) {
              if (
                this.state.estimatedSalesVolume.length > 0 &&
                this.state.estimatedAverageTicket > 0
              ) {
                this.setState({ thirdStepperStatus: "valid" });
              }
            }
          }
        );
      }
    );
  };

  //Save Quote to Database
  callRegisterInitialQuoteApi = async () => {
    const json = JSON.stringify({
      dba: this.state.dba,
      mccCode: this.state.mccCode[0],
      contactName: this.state.contactName,
      email: this.state.email,
      merchantType: this.state.merchantType,
      phoneNumber: this.state.phoneNumber,
      mccCategory: this.state.mccCategory,
      mccCategoryDescription: this.state.mccCategoryDescription,
      physicalLocation: this.state.physicalLocation,
      city: this.state.city,
      state: this.state.state,
      zipcode: this.state.zipcode,
      estimatedSalesVolume: this.state.estimatedSalesVolume,
      estimatedAverageTicket: this.state.estimatedAverageTicket,
      cardPresentPercentage: this.state.cardPresentPercentage,
      cardNotPresentPercentage: this.state.cardNotPresentPercentage,
      previousProcessor: this.state.previousProcessor,
      previousProcessorSalesVolume: this.state.previousProcessorSalesVolume,
      previousProcessorDiscountRate: this.state.previousProcessorDiscountRate,
      previousProcessorTransactionFees:
        this.state.previousProcessorTransactionFees,
      previousProcessorOtherFees: this.state.previousProcessorOtherFees,
      previousProcessorTotalBillAmount:
        this.state.previousProcessorTotalBillAmount,
      previousProcessorPricingModelOptionsType:
        this.state.previousProcessorPricingModelOptionsType,
    });
    await registerQuote(json).then((response) => {
      console.log(response);
      if (response.status === 201) {
        this.setState({ quoteId: response.data._id }, () => {
          this.setRedirectQuote();
          this.renderRedirectQuote();
        });
      } else if (response.status !== 200) {
          alert("An error has occurred, please contact technical support.");
        // throw Error(response.message);
      }
    });
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    let firstStepperStatus = "";
    let secondStepperStatus = "";
    let thirdStepperStatus = "";

    let state = "";

    let mccCategory = "";
    let mccCategoryDescription = "";

    //First Stepper Validation
    if (
      this.state.dba == undefined ||
      this.state.dba == "" ||
      this.state.mccCode == 0
    ) {
      firstStepperStatus = "invalid";
    } else {
      firstStepperStatus = "valid";
    }

    if (this.state.mccCategory == undefined || this.state.mccCategory == "") {
      mccCategory = "invalid";
    } else {
      mccCategory = this.state.mccCategory;
    }

    if (
      this.state.mccCategoryDescription == undefined ||
      this.state.mccCategoryDescription == ""
    ) {
      mccCategoryDescription = "invalid";
    } else {
      mccCategoryDescription = this.state.mccCategoryDescription;
    }

    //Second Stepper Validation
    if (
      this.state.state == undefined ||
      this.state.state == "" ||
      this.state.state == "invalid"
    ) {
      secondStepperStatus = "invalid";
      state = "invalid";
    } else {
      secondStepperStatus = "valid";
      state = this.state.state;
    }

    //Third Stepper Validation

    if (
      this.state.estimatedAverageTicket == undefined ||
      this.state.estimatedAverageTicket == ""
    ) {
      thirdStepperStatus = "invalid";
    } else {
      thirdStepperStatus = "valid";
    }

    this.setState(
      {
        firstStepperStatus: firstStepperStatus,
        secondStepperStatus: secondStepperStatus,
        thirdStepperStatus: thirdStepperStatus,
        state: state,
        mccCategory: mccCategory,
        mccCategoryDescription: mccCategoryDescription,
      },
      () => {
        if (
          this.state.firstStepperStatus == "valid" &&
          this.state.secondStepperStatus == "valid" &&
          this.state.thirdStepperStatus == "valid"
        ) {
          this.callRegisterInitialQuoteApi();
        }
      }
    );
  };

  render() {
    return (
      <div>
        <h2 className="h1-responsive font-weight-bold text-center my-5">
          New Merchant Quote
          <p className="lead w-responsive text-center mx-auto mb-5 mt-3">
            Please enter all the required information in order to generate a
            quote.
          </p>
        </h2>

        <MDBCard narrow>
          <MDBCardImage
            className="view view-cascade gradient-card-header primary-color"
            tag="div"
          >
            <h3 className="h3-responsive">Merchant Information</h3>
          </MDBCardImage>
          <MDBCardBody>
            <MDBRow className="pt-5 justify-content-center">
              <MDBCol md="2" className="pl-5 pl-md-0 pb-5">
                <MDBStepper icon vertical>
                  {this.state.firstStepperStatus === "valid" ? (
                    <MDBCustomStep
                      far
                      icon="user"
                      iconStyle=""
                      stepName="General Information"
                      onClick={this.swapFormActive(3)(1)}
                      vertical
                    />
                  ) : (
                    <MDBCustomStep
                      far
                      icon="user"
                      iconStyle="red-text"
                      stepName="General Information"
                      onClick={this.swapFormActive(3)(1)}
                      vertical
                    />
                  )}

                  {this.state.secondStepperStatus === "valid" ? (
                    <MDBCustomStep
                      icon="map-marker-alt"
                      stepName="Business Location"
                      onClick={this.swapFormActive(3)(2)}
                      vertical
                    />
                  ) : (
                    <MDBCustomStep
                      icon="map-marker-alt"
                      iconStyle="red-text"
                      stepName="Business Location"
                      onClick={this.swapFormActive(3)(2)}
                      vertical
                    />
                  )}

                  {this.state.thirdStepperStatus === "valid" ? (
                    <MDBCustomStep
                      far
                      icon="credit-card"
                      stepName="Merchant Processing Information"
                      onClick={this.swapFormActive(3)(3)}
                      vertical
                    />
                  ) : (
                    <MDBCustomStep
                      far
                      icon="credit-card"
                      stepName="Merchant Processing Information"
                      onClick={this.swapFormActive(3)(3)}
                      iconStyle="red-text"
                      vertical
                    />
                  )}

                  <MDBStep
                    icon="check"
                    stepName="Save and Continue"
                    onClick={this.swapFormActive(3)(4)}
                    vertical
                  />
                </MDBStepper>
              </MDBCol>

              <MDBCol md="7">
                <form
                  className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                >
                  {this.state.formActivePanel3 === 1 && (
                    <MDBCol md="12">
                      <h3 className="font-weight-bold pl-0 my-4">
                        <strong>General Information</strong>
                      </h3>

                      <MDBInput
                        name="dba"
                        value={this.state.dba}
                        onChange={this.changeHandler}
                        label="DBA"
                        type="text"
                        autoFocus={this.calculateAutofocus(3)}
                        required
                        id="materialInvalidCheck"
                        className="mt-4"
                      >
                        <div className="invalid-feedback">
                          DBA Name is Required
                        </div>
                      </MDBInput>

                      <MDBInput
                        name="contactName"
                        value={this.state.contactName}
                        onChange={this.changeHandler}
                        label="Merchant Contact"
                        type="text"
                        className="mt-4"
                      />

                      <MDBInput
                        name="email"
                        value={this.state.email}
                        onChange={this.changeHandler}
                        label="Email"
                        type="email"
                        className="mt-4"
                      />

                      <MDBInput
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.changeHandler}
                        label="Phone Number"
                        type="text"
                        className="mt-4"
                      />
                      <MDBRow center>
                        <p className="mcc">
                          {" "}
                          {!this.state.mccCode === undefined ||
                          !this.state.mccCode.length == 0
                            ? "MCC Code Assigned: " + this.state.mccCode
                            : ""}
                        </p>{" "}
                      </MDBRow>

                      <MDBSelect
                        options={this.state.mccCategoryOptions}
                        search
                        required
                        id="materialInvalidCheck"
                        label="MCC Category"
                        // getValue={this.handleMccCategorySelectChange}
                        getTextContent={this.handleMccCategorySelectChange}
                      ></MDBSelect>

                      {this.state.mccCategory == "invalid" ? (
                        <div className="invalid-feedback2">
                          MCC Category is Required
                        </div>
                      ) : (
                        ""
                      )}

                      <MDBSelect
                        options={this.state.mccDescriptionOptions}
                        search
                        required
                        selected="First Select MCC Category"
                        label="MCC Description"
                        getValue={this.handleMccCodeSelectChange}
                        getTextContent={this.handleMCCDescriptionSelectChange}
                      />

                      {this.state.mccCategoryDescription == "invalid" ? (
                        <div className="invalid-feedback2">
                          MCC Category is Required
                        </div>
                      ) : (
                        ""
                      )}

                      {/* <MDBInput
                        label="MCC Number"
                        className="mcc"
                        value={
                          !this.state.mccCode === undefined ||
                          !this.state.mccCode.length == 0
                            ? this.state.mccCode
                            : ""
                        }
                        required
                        disabled={
                          this.state.firstStepperStatus == "invalid"
                            ? false
                            : true
                        }
                      >
                        <div className="invalid-feedback">
                          Please select "MCC Category" and "MCC Description"
                        </div>
                      </MDBInput> */}

                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-right"
                        onClick={this.handleNextPrevClick(3)(2)}
                      >
                        next
                      </MDBBtn>
                    </MDBCol>
                  )}
                  {this.state.formActivePanel3 === 2 && (
                    <MDBCol md="12">
                      <h3 className="font-weight-bold pl-0 my-4">
                        <strong>Business Location</strong>
                      </h3>
                      <MDBInput
                        name="physicalLocation"
                        value={this.state.physicalLocation}
                        onChange={this.changeHandler}
                        label="Physical Address"
                        type="text"
                        className="mt-4"
                        autoFocus={this.calculateAutofocus(3)}
                      />

                      <MDBInput
                        name="city"
                        value={this.state.city}
                        onChange={this.changeHandler}
                        label="City"
                        type="text"
                        id="materialInvalidCheck"
                        className="mt-4"
                      ></MDBInput>

                      <MDBSelect
                        className="customSelect"
                        options={this.state.stateOptions}
                        search
                        required
                        selected="Choose your option"
                        label="State"
                        getTextContent={this.handleStateSelectChange}
                      />

                      {this.state.state == "invalid" ? (
                        <div className="invalid-feedback2">
                          State is Required
                        </div>
                      ) : (
                        ""
                      )}

                      {/* {this.state.city == undefined || this.state.city ? (
                        ""
                      ) : (
                        <div className="invalid-feedback2">
                          City is Required
                        </div>
                      )} */}

                      <MDBInput
                        name="zipcode"
                        value={this.state.zipcode}
                        onChange={this.changeHandler}
                        label="Zipcode"
                        type="text"
                        id="materialInvalidCheck"
                        className="mt-4"
                      ></MDBInput>

                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-left"
                        onClick={this.handleNextPrevClick(3)(1)}
                      >
                        previous
                      </MDBBtn>
                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-right"
                        onClick={this.handleNextPrevClick(3)(3)}
                      >
                        next
                      </MDBBtn>
                    </MDBCol>
                  )}
                  {this.state.formActivePanel3 === 3 && (
                    <MDBCol md="12">
                      <h3 className="font-weight-bold pl-0 my-4">
                        <strong>Merchant Processing Information</strong>
                      </h3>
                      <MDBInput
                        name="estimatedSalesVolume"
                        value={this.state.estimatedSalesVolume}
                        onChange={this.changeHandler}
                        label="Estimated Sales Volume"
                        type="number"
                        className="mt-4"
                        required
                        icon="dollar-sign"
                        iconClass="green-text"
                      >
                        {" "}
                        <div className="invalid-feedback">
                          Estimated Sales Volume Required
                        </div>
                      </MDBInput>
                      <MDBInput
                        name="estimatedAverageTicket"
                        value={this.state.estimatedAverageTicket}
                        onChange={this.changeHandler}
                        label="Estimated Average Ticket"
                        type="number"
                        className="mt-4"
                        iconClass="green-text"
                        icon="file-invoice-dollar"
                        required
                      >
                        {" "}
                        <div className="invalid-feedback">
                          Estimated Average Ticket Required
                        </div>
                      </MDBInput>
                      <MDBInput
                        name="cardPresentPercentage"
                        value={this.state.cardPresentPercentage}
                        onChange={this.changeHandler}
                        label="Card Present Trasactions %"
                        type="number"
                        className="mt-4"
                        iconClass="green-text"
                        icon="cash-register"
                      ></MDBInput>
                      <MDBInput
                        disabled
                        name="cardNotPresentPercentage"
                        value={this.state.cardNotPresentPercentage}
                        label="Card NOT Present Trasactions %"
                        type="number"
                        className="mt-4"
                        iconClass="green-text"
                        icon="laptop"
                      ></MDBInput>
                      <MDBSelect
                        options={this.state.merchantTypeOptions}
                        id="materialInvalidCheck"
                        label={"Merchant Type"}
                        getTextContent={this.handleMerchantTypeSelectChange}
                      ></MDBSelect>
                      <MDBRow center className="mb-4">
                        <MDBInput
                          label="Comparison quote with previous processor"
                          checked={this.state.previousProcessor}
                          type="checkbox"
                          id="previousProcessorCheckboxChange"
                          getValue={this.handlePreviousProcessorCheckboxChange}
                        />
                      </MDBRow>
                      {this.state.previousProcessor === true ? (
                        <MDBRow>
                          <MDBCol>
                            <h4>Previous Processor Information</h4>
                            <MDBInput
                              name="previousProcessorSalesVolume"
                              value={this.state.previousProcessorSalesVolume}
                              onChange={this.changeHandler}
                              label="Sales Volume"
                              type="number"
                              className="mt-4"
                              required
                              icon="dollar-sign"
                              iconClass="green-text"
                            />

                            <MDBInput
                              name="previousProcessorDiscountRate"
                              value={this.state.previousProcessorDiscountRate}
                              onChange={this.changeHandler}
                              label="Discount Rate (%)"
                              type="number"
                              className="mt-4"
                              required
                              icon="percent"
                              iconClass="green-text"
                            />
                            <MDBInput
                              name="previousProcessorTransactionFees"
                              value={
                                this.state.previousProcessorTransactionFees
                              }
                              onChange={this.changeHandler}
                              label="Transaction Fees"
                              type="number"
                              className="mt-4"
                              required
                              icon="cash-register"
                              iconClass="green-text"
                            />
                            <MDBInput
                              name="previousProcessorOtherFees"
                              value={this.state.previousProcessorOtherFees}
                              onChange={this.changeHandler}
                              label="Other Fees"
                              type="number"
                              className="mt-4"
                              required
                              icon="hand-holding-usd"
                              iconClass="green-text"
                            />
                            <MDBInput
                              name="previousProcessorTotalBillAmount"
                              value={
                                this.state.previousProcessorTotalBillAmount
                              }
                              onChange={this.changeHandler}
                              label="Total Bill Amount"
                              type="number"
                              className="mt-4"
                              required
                              icon="file-invoice-dollar"
                              iconClass="green-text"
                            />
                            <MDBSelect
                              options={
                                this.state.previousProcessorPricingModelOptions
                              }
                              id="materialInvalidCheck"
                              label={"Pricing Model"}
                              getTextContent={
                                this.handlePreviousProcessorPricingModelOptions
                              }
                            ></MDBSelect>
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ""
                      )}

                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-left"
                        onClick={this.handleNextPrevClick(3)(2)}
                      >
                        previous
                      </MDBBtn>
                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-right"
                        onClick={this.handleNextPrevClick(3)(4)}
                      >
                        next
                      </MDBBtn>
                    </MDBCol>
                  )}
                  {this.state.formActivePanel3 === 4 && (
                    <MDBCol md="12">
                      <h3 className="font-weight-bold pl-0 my-4">
                        <strong>Save and Continue</strong>
                      </h3>
                      <h2 className="teal-text text-center  my-4 mt-5 mb-5">
                        Please save the merchant information to continue to
                        product and pricing selection.
                      </h2>

                      <MDBBtn
                        color="mdb-color"
                        rounded
                        className="float-left"
                        onClick={this.handleNextPrevClick(3)(3)}
                      >
                        previous
                      </MDBBtn>
                      <MDBBtn
                        color="success"
                        rounded
                        className="float-right"
                        type="submit"
                        // onClick={this.submitHandler}
                      >
                        Save Quote
                      </MDBBtn>
                    </MDBCol>
                  )}
                </form>
              </MDBCol>
            </MDBRow>
            <hr />
          </MDBCardBody>
        </MDBCard>
        {/* {this.renderRedirectLogin()} */}
        {this.renderRedirectQuote()}
      </div>
    );
  }
}

export default NewQuoteForm;
