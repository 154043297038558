//3rd Party Library
import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes/Routes";
import UserContext from "./context/userContext";
//Application
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
// import Footer from "./components/Footer";
import auth from "./services/authService";
//Stylesheets and Css
import "./App.css";

class App extends Component {
  componentDidMount() {
    const currentUser = auth.getCurrentUser();
    this.setState({ currentUser });
  }

  render() {
    return (
      <Router>
        <UserContext.Provider
          value={{
            currentUser: auth.getCurrentUser,
            handleLogin: auth.login,
          }}
        >
          <div className="App">
            <Navbar />
            <Routes />
            <Footer />
          </div>
        </UserContext.Provider>
      </Router>
    );
  }
}

export default App;
