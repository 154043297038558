import http from "./httpService";

const apiEndPoint = "/reports";

export function getCompetitionPricing(mcc, state) {
  return http.get(apiEndPoint + "/competitor/pricing/" + mcc + "/" + state);
}

export function getAdminDashboardWeeklyStats() {
  return http.get(apiEndPoint + "/dashboard/admin");
}
