import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import "./Settings.css";
import {
  MDBIcon,
  MDBSpinner,
  MDBRow,
  MDBCol,
  MDBCardTitle,
  MDBCardBody,
  MDBCardText,
  MDBContainer,
  MDBCardImage,
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBSelect,
  MDBCard,
} from "mdbreact";

import {
  getCostSettings,
  UpdateCostSettings,
} from "../../services/settingsService";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: null,
      redirectLogin: false,
      response: "Please select a CSV file to upload",
      loading: false,
      costSettingsId: "5f64f69bb5f88c9b0a603fbe",
      customCost: false,
      customCosts: [],
      currentCustomCostType: "Monthly",
      customCostTypeOptions: [
        {
          checked: true,
          text: "Monthly",
          value: "1",
        },
        {
          text: "Percent",
          value: "2",
        },
        {
          text: "Per Transaction",
          value: "3",
        },
      ],
    };
  }

  componentDidMount() {
    this.retrieveCostSettings();
  }

  retrieveCostSettings = () => {
    getCostSettings(this.state.costSettingsId).then((response) => {
      const data = response.data;
      if (response.status !== 200) {
        alert(
          "An error has occured retrieving cost settings, please contact technical support."
        );
      } else {
        if (data.customCosts.length > 0) {
          this.setState({ customCost: true });
        }
        this.setState({
          costSettingsId: data._id,
          customCosts: data.customCosts,
          profileName: data.profileName,
          basisPoint: data.basisPoint,
          goalMargin: data.goalMargin,
          authorization: data.authorization,
          monthlyStatement: data.monthlyStatement,
          monthlyGateway: data.monthlyGateway,
          chargeback: data.chargeback,
          pciRegulatory: data.pciRegulatory,
          equipment: data.equipment,
          otherMonthly: data.otherMonthly,
        });
      }
    });
  };

  handleCostTypeSelectChange = (event) => {
    this.setState({
      currentCustomCostType: event,
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleCustomCost = () => {
    this.setState({
      modalCustomCost: !this.state.modalCustomCost,
    });
  };

  toggleAddCustomCost = () => {
    let joined = this.state.customCosts.concat({
      name: this.state.currentCustomCostName,
      rate: this.state.currentCustomCostRate,
      type: this.state.currentCustomCostType,
    });

    this.setState(
      {
        modalCustomCost: !this.state.modalCustomCost,
        customCost: true,
        customCosts: joined,
      },
      () => {
        this.setState({
          currentCustomCostName: "",
          currentCustomCostRate: "",
          // currentCustomCostType: "",
        });
      }
    );
  };

  setRedirectLogin = () => {
    this.setState({
      redirectLogin: true,
    });
  };

  renderRedirectLogin = () => {
    if (this.state.redirectLogin) {
      return <Redirect to="/" />;
    }
  };

  changeHandler = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = () => {
    //this.toggle();

    this.setState(
      {
        loading: true,
      },
      () => {
        const json = JSON.stringify({
          basisPoint: this.state.basisPoint,
          goalMargin: this.state.goalMargin,
          authorization: this.state.authorization,
          monthlyStatement: this.state.monthlyStatement,
          monthlyGateway: this.state.monthlyGateway,
          chargeback: this.state.chargeback,
          pciRegulatory: this.state.pciRegulatory,
          equipment: this.state.equipment,
          otherMonthly: this.state.otherMonthly,
          customCosts: this.state.customCosts,
        });

        console.log(json);

        UpdateCostSettings(this.state.costSettingsId, json).then((response) => {
          const data = response.data;

          if (response.statusText === "OK") {
            this.setState(
              {
                response: data,
              },
              () => {
                this.setState({ loading: false });
                this.toggle();
              }
            );
          } else if (response.status !== 200) {
            response.json().then((json) => {
              alert("An error has occurred, please contact technical support.");
            });
            // throw Error(response.message);
          }

          console.log(response);
        });
      }
    );
  };

  retrieveCostSettingsId = () => {
    //Get costSetings ID from database to send for the update
  };

  deleteCustomCost = (customCostId) => {
    var array = [...this.state.customCosts];
    array.splice(customCostId, 1);
    this.setState({ customCosts: array }, () => {
      if (this.state.customCosts.length == 0) {
        this.setState({ customCost: false });
      }
    });
  };

  render() {
    return (
      <div>
        {this.state.loading == true ? (
          <MDBContainer style={{ paddingTop: "" }}>
            <MDBRow style={{ padding: "300px" }} className="text-center">
              <MDBCol>
                <MDBSpinner big />
              </MDBCol>
            </MDBRow>{" "}
          </MDBContainer>
        ) : (
          <MDBContainer style={{ paddingTop: "100px" }} className="text-center">
            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>
                Update Status
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow center>
                  {this.state.response ==
                  "Cost settings have been updated successfully." ? (
                    <MDBIcon
                      className="green-text"
                      size="3x"
                      icon="check-circle"
                    />
                  ) : (
                    <MDBIcon
                      className="amber-text"
                      size="3x"
                      icon="exclamation-triangle"
                    />
                  )}
                </MDBRow>
                <p className="mt-3">{this.state.response}</p>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>
                  Close
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>{" "}
            <MDBModal
              centered
              isOpen={this.state.modalCustomCost}
              toggle={this.toggleCustomCost}
            >
              <MDBModalHeader toggle={this.toggleCustomCost}>
                Add Custom Cost
              </MDBModalHeader>
              <MDBModalBody>
                <MDBRow>
                  <MDBCol>
                    <MDBInput
                      name="currentCustomCostName"
                      value={this.state.currentCustomCostName}
                      onChange={this.changeHandler}
                      label="Cost Name"
                      type="text"
                    />
                    <MDBInput
                      name="currentCustomCostRate"
                      value={this.state.currentCustomCostRate}
                      onChange={this.changeHandler}
                      label="Cost Rate"
                      type="number"
                    />
                    <MDBSelect
                      options={this.state.customCostTypeOptions}
                      // selected={"Monthly"}
                      id="materialInvalidCheck"
                      getTextContent={this.handleCostTypeSelectChange}
                    ></MDBSelect>
                  </MDBCol>
                </MDBRow>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggleCustomCost}>
                  Close
                </MDBBtn>
                <MDBBtn color="primary" onClick={this.toggleAddCustomCost}>
                  Add Cost
                </MDBBtn>
              </MDBModalFooter>
            </MDBModal>
            <MDBRow></MDBRow>
            <MDBRow className="pb-3">
              <MDBCol>
                <MDBCard narrow className="pt-5 mb-4">
                  <MDBCardImage
                    className="view view-cascade gradient-card-header primary-color"
                    tag="div"
                  >
                    <h3 className="h3-responsive">Cost Management</h3>
                  </MDBCardImage>
                  <MDBCardBody>
                    <MDBCardTitle className="indigo-text h5 m-4">
                      Please enter your portfolio level cost and goals
                    </MDBCardTitle>
                    <MDBRow className="pb-5" center>
                      <MDBCol md="4">
                        <MDBInput
                          value={this.state.goalMargin}
                          name="goalMargin"
                          onChange={this.changeHandler}
                          type="Number"
                          id="materialFormRegisterEmailEx2"
                          label="Goal Margin"
                          required
                        />
                      </MDBCol>
                    </MDBRow>
                    <MDBCardText>
                      The rates below will be used when calculating gross margin
                      and profit.
                    </MDBCardText>
                    <MDBCol
                      className="d-flex justify-content-center mt-4"
                      md="12"
                    >
                      <form onSubmit={this.submitHandler}>
                        <MDBRow>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.basisPoint}
                              name="basisPoint"
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterNameEx"
                              label="Basis Points"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.authorization}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterConfirmEx3"
                              name="authorization"
                              label="Authorization"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.monthlyStatement}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="monthlyStatement"
                              label="Monthly Statement"
                              required
                            />
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.chargeback}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="chargeback"
                              label="Chargeback"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.monthlyGateway}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="monthlyGateway"
                              label="Monthly Gateway"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.pciRegulatory}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="pciRegulatory"
                              label="PCI/Regulatory"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.equipment}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="equipment"
                              label="Equipment"
                              required
                            />
                          </MDBCol>
                          <MDBCol md="4">
                            <MDBInput
                              value={this.state.otherMonthly}
                              onChange={this.changeHandler}
                              type="Number"
                              id="materialFormRegisterPasswordEx4"
                              name="otherMonthly"
                              label="Other Monthly Cost"
                              required
                            />
                          </MDBCol>
                        </MDBRow>

                        <MDBRow>
                          <MDBCol>
                            <MDBRow center>
                              {this.state.customCost == true ? (
                                <MDBCardTitle className="indigo-text h5 m-4">
                                  Custom Costs
                                </MDBCardTitle>
                              ) : (
                                ""
                              )}
                            </MDBRow>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow>
                          {this.state.customCost == true
                            ? this.state.customCosts.map(
                                (customCost, index) => (
                                  <MDBCol md="4">
                                    <div className="red-text">
                                      <MDBInput
                                        containerClass="text-left"
                                        id={index}
                                        value={customCost.rate}
                                        icon="minus-circle"
                                        iconSize="sm"
                                        disabled
                                        onIconClick={(e) =>
                                          this.deleteCustomCost(index)
                                        }
                                        type="Number"
                                        name="customCost"
                                        label={
                                          customCost.name +
                                          ` (${customCost.type})`
                                        }
                                      />
                                    </div>
                                  </MDBCol>
                                )
                              )
                            : ""}
                        </MDBRow>
                        <MDBRow className="pt-5">
                          <MDBCol>
                            <MDBBtn
                              color="primary"
                              onClick={this.toggleCustomCost}
                            >
                              Add Custom Cost
                            </MDBBtn>
                            <MDBBtn color="secondary" type="submit">
                              Update Costs
                            </MDBBtn>
                          </MDBCol>
                        </MDBRow>
                      </form>
                    </MDBCol>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}
        {this.renderRedirectLogin()}
      </div>
    );
  }
}

export default Settings;
