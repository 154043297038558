import http from "./httpService";

const apiEndPoint = "/quotes";

export function getAllQuotes() {
  return http.get(apiEndPoint + "/" + "admin/retrieve/all");
}

export function getRecentQuotes() {
  return http.get(apiEndPoint + "/" + "recent");
}

export function searchQuotes(json) {
  return http.post(apiEndPoint + "/" + "search", json, {
    headers: { "Content-Type": "application/json" },
  });
}

export function registerQuote(json) {
  return http.post(apiEndPoint, json, {
    headers: { "Content-Type": "application/json" },
  });
}

export function UpdateInitialQuoteInformation(id, json) {
  return http.post(apiEndPoint + "/update/" + id, json, {
    headers: { "Content-Type": "application/json" },
  });
}

export function UpdateQuotePricingInformation(id, json) {
  return http.post(apiEndPoint + "/pricing/update/" + id, json, {
    headers: { "Content-Type": "application/json" },
  });
}

export function RetrieveSingleQuoteById(id) {
  return http.get(apiEndPoint + "/" + id);
}

export function UpdateQuoteStatusCommentById(id, json) {
  return http.post(apiEndPoint + "/" + "approval/" + id, json);
}
