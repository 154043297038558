import React, { Component, useEffect, useState } from "react";

import { MDBRow, MDBCol, MDBContainer } from "mdbreact";

import {
  ReactPDF,
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";

import { RetrieveSingleQuoteById } from "../../services/quoteService";
import UserContext from "../../context/userContext";

class PdfGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = { quoteId: props.match.params.id };
  }

  componentDidMount() {
    this.retrieveQuoteById(this.state.quoteId);
  }

  retrieveQuoteById = (quoteId) => {
    RetrieveSingleQuoteById(quoteId)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          this.setState({ authorized: false });
          alert("An error has occured, please contact technical support.");
        } else if (response.data.length == 0) {
          this.setState({ authorized: false });
        } else {
          console.log(response);
          let quote = response.data;
          this.setState({
            //-------------------------------------------------------//
            //-----------------Merchant Information------------------//
            //-------------------------------------------------------//
            authorized: true,
            city: quote.city,
            state: quote.state,
            contactName: quote.contactName,
            email: quote.email,
            merchantType: quote.merchantType,
            dba: quote.dba,
            estimatedAverageTicket: quote.estimatedAverageTicket.toFixed(2),
            estimatedSalesVolume: quote.estimatedSalesVolume,
            mccCategory: quote.mccCategory,
            mccCategoryDescription: quote.mccCategoryDescription,
            mccCode: [quote.mccCode],
            phoneNumber: quote.phoneNumber,
            physicalLocation: quote.physicalLocation,
            previousProcessor: quote.previousProcessor,
            zipcode: quote.zipcode,
            quoteGenerated: quote.quoteGenerated,
            quoteNumber: quote.quoteId,
            interchangePassThru: quote.interchangePassThru,
            flatRate: quote.flatRate,
            flatRatePlusDowngrade: quote.flatRatePlusDowngrade,
            tierPricing: quote.tierPricingOption,
            merchantServices: quote.merchantServices,
            cardPresentPercentage: quote.cardPresentPercentage,
            cardNotPresentPercentage: quote.cardNotPresentPercentage,
            interchangeQuoteTotals: quote.interchangeQuoteTotals,
            flatRateQuoteTotals: quote.flatRateQuoteTotals,
            flatRateDowngradeQuoteTotals:
              quote.flatRatePlusDowngradeQuoteTotals,
            tierPricingQuoteTotals: quote.tierPricingQuoteTotals,
            //-------------------------------------------------------//
            //----------------------Interchange----------------------//
            //-------------------------------------------------------//
            interchangeDiscount:
              quote.interchangePassthruPricing.discount == 0
                ? 50
                : quote.interchangePassthruPricing.discount,
            interchangeAuthorizationFee:
              quote.interchangePassthruPricing.authorizationFee == 0
                ? 0.08
                : quote.interchangePassthruPricing.authorizationFee,
            interchangeChargebackFees:
              quote.interchangePassthruPricing.chargebackFees,
            interchangeMonthlyEquipmentFees:
              quote.interchangePassthruPricing.equipmentFees,
            interchangeMonthlyGatewayFees:
              quote.interchangePassthruPricing.monthlyGatewayFees,
            interchangeMonthlyPciRegulatoryFees:
              quote.interchangePassthruPricing.monthlyPciRegulatoryFees,
            interchangeMonthlyServiceFee:
              quote.interchangePassthruPricing.monthlyStatementFeeServiceFee,
            interchangeNumberOfTransactions:
              quote.interchangePassthruPricing.numberOfTransactions,
            interchangeOtherMonthlyFees:
              quote.flatRatePassthruPricing.otherMonthlyFees,
            interchangeOtherTransactionFees:
              quote.interchangePassthruPricing.otherTransactionFees == 0
                ? 0.05
                : quote.interchangePassthruPricing.otherTransactionFees,
            //-------------------------------------------------------//
            //----------------------Flat Rate------------------------//
            //-------------------------------------------------------//
            flatRateDiscount:
              quote.flatRatePassthruPricing.discount == 0
                ? 2.95
                : quote.flatRatePassthruPricing.discount,
            flatRateAuthorizationFee:
              quote.flatRatePassthruPricing.authorizationFee == 0
                ? 0.08
                : quote.flatRatePassthruPricing.authorizationFee,
            flatRateChargebackFees:
              quote.flatRatePassthruPricing.chargebackFees,
            flatRateMonthlyEquipmentFees:
              quote.flatRatePassthruPricing.equipmentFees,
            flatRateMonthlyGatewayFees:
              quote.flatRatePassthruPricing.monthlyGatewayFees,
            flatRateMonthlyPciRegulatoryFees:
              quote.flatRatePassthruPricing.monthlyPciRegulatoryFees,
            flatRateMonthlyServiceFee:
              quote.flatRatePassthruPricing.monthlyStatementFeeServiceFee,
            flatRateNumberOfTransactions:
              quote.flatRatePassthruPricing.numberOfTransactions,
            flatRateOtherMonthlyFees:
              quote.flatRatePassthruPricing.otherMonthlyFees,
            flatRateOtherTransactionFees:
              quote.flatRatePassthruPricing.otherTransactionFees,
            //-------------------------------------------------------//
            //-----------------Flat Rate Downgrade-------------------//
            //-------------------------------------------------------//
            flatRateDowngradeAuthorizationFeeDowngrade:
              quote.flatRatePlusDowngradePricing.authorizationFeeDowngrade == 0
                ? 0.15
                : quote.flatRatePlusDowngradePricing.authorizationFeeDowngrade,
            flatRateDowngradeAuthorizationFeeFlatRate:
              quote.flatRatePlusDowngradePricing.authorizationFeeFlatRate == 0
                ? 0.08
                : quote.flatRatePlusDowngradePricing.authorizationFeeFlatRate,
            flatRateDowngradeChargebackFees:
              quote.flatRatePlusDowngradePricing.chargeBackFees,
            flatRateDowngradeDowngrade:
              quote.flatRatePlusDowngradePricing.downgrade == 0
                ? 3.5
                : quote.flatRatePlusDowngradePricing.downgrade,
            flatRateDowngradeDowngradePercent:
              quote.flatRatePlusDowngradePricing.downgradePercent == 0
                ? 20
                : quote.flatRatePlusDowngradePricing.downgradePercent,
            flatRateDowngradeEstimateNumberOfTransactions:
              quote.flatRatePlusDowngradePricing.estimateNumberOfTransactions,
            flatRateDowngradeFlatRate:
              quote.flatRatePlusDowngradePricing.flatRate == 0
                ? 2.5
                : quote.flatRatePlusDowngradePricing.flatRate,
            flatRateDowngradeFlatRatePercent:
              quote.flatRatePlusDowngradePricing.flatRatePercent == 0
                ? 80
                : quote.flatRatePlusDowngradePricing.flatRatePercent,
            flatRateDowngradeMonthlyEquipmentFees:
              quote.flatRatePlusDowngradePricing.equipmentFees,
            flatRateDowngradeMonthlyGatewayFees:
              quote.flatRatePlusDowngradePricing.monthlyGatewayFees,
            flatRateDowngradeMonthlyPciRegulatoryFees:
              quote.flatRatePlusDowngradePricing.monthlyPciRegulatoryFees,
            flatRateDowngradeMonthlyServiceFee:
              quote.flatRatePlusDowngradePricing.monthlyStatementServiceFee,
            flatRateDowngradeOtherMonthlyFees:
              quote.flatRatePlusDowngradePricing.otherMonthlyFees,
            flatRateDowngradeOtherTransactionFee:
              quote.flatRatePlusDowngradePricing.otherTransactionFees,
            //-------------------------------------------------------//
            //---------------------Tier Pricing----------------------//
            //-------------------------------------------------------//
            tierPricingAuthorizationFee:
              quote.tierPricing.authorizationFee == 0
                ? 0.08
                : quote.tierPricing.authorizationFee,
            tierPricingChargebackFees: quote.tierPricing.chargebackFees,
            tierPricingEstimateNumberOfTransactions:
              quote.tierPricing.estimateNumberOfTransactions,
            tierPricingMidQualified:
              quote.tierPricing.midQualified == 0
                ? 2.85
                : quote.tierPricing.midQualified,
            tierPricingMidQualifiedPercent:
              quote.tierPricing.midQualifiedPercent == 0
                ? 25
                : quote.tierPricing.midQualifiedPercent,
            tierPricingMonthlyEquipmentFees: quote.tierPricing.equipmentFees,
            tierPricingMonthlyGatewayFees: quote.tierPricing.monthlyGatewayFees,
            tierPricingMonthlyPciRegulatoryFees:
              quote.tierPricing.monthlyPciRegulatoryFees,
            tierPricingMonthlyServiceFee:
              quote.tierPricing.monthlyStatementServiceFee,
            tierPricingNonQualified:
              quote.tierPricing.nonQualified == 0
                ? 3.15
                : quote.tierPricing.nonQualified,
            tierPricingNonQualifiedPercent:
              quote.tierPricing.nonQualifiedPercent == 0
                ? 20
                : quote.tierPricing.nonQualifiedPercent,
            tierPricingOtherMonthlyFees: quote.tierPricing.otherMonthlyFees,
            tierPricingOtherTransactionFees:
              quote.tierPricing.otherTransactionFees,
            tierPricingQualified:
              quote.tierPricing.qualified == 0
                ? 2.55
                : quote.tierPricing.qualified,
            tierPricingQualifiedPercent:
              quote.tierPricing.qualifiedPercent == 0
                ? 55
                : quote.tierPricing.qualifiedPercent,
            selectedQuotePricing: quote.quotePricingModel,
            quoteStatus: quote.quoteStatus,
            quoteApprovalNotes: quote.quoteApprovalNotes,
            previousProcessorSalesVolume: quote.previousProcessorSalesVolume,
            previousProcessorDiscountRate: quote.previousProcessorDiscountRate,
            previousProcessorTransactionFees:
              quote.previousProcessorTransactionFees,
            previousProcessorOtherFees: quote.previousProcessorOtherFees,
            previousProcessorTotalBillAmount:
              quote.previousProcessorTotalBillAmount,
            previousProcessorPricingModelOptionsType:
              quote.previousProcessorPricingModelOptionsType,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        alert(
          "An error has occured retrieving single quote by id, please contact technical support."
        );
      });
  };

  render() {
    return (
      <MDBContainer style={{ marginTop: "100px" }}>
        <MDBRow>
          <MDBCol>
            <UserContext.Consumer>
              {(userContext) => (
                <PDFViewer width={"1000px"} height={"1000px"}>
                  <MyDocument data={this.state} userContext={userContext} />
                </PDFViewer>
              )}
            </UserContext.Consumer>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
    paddingBottom: 25,
    paddingTop: 15,
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "left",
  },

  textBottomNote: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "justify",
    paddingTop: 15,
  },
  textRight: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "right",
    paddingRight: 140,
  },
  image: {
    marginVertical: 55,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  row: {
    flexDirection: "row",
    // backgroundColor: "#E4E4E4",
    // paddingTop: 20,
  },

  tableTotalRow: {
    paddingLeft: 300,
    flexDirection: "row",
  },
  col: {
    flexDirection: "col",
  },
  colRight: {
    flexDirection: "col",

    // paddingLeft: 190,
    // paddingRight: 148,
  },

  imageView: {
    paddingLeft: 300,
    paddingRight: 0,
    paddingBottom: 20,
  },

  tableSection: {
    width: 105,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
  },

  tableSectionSpecial: {
    width: 105,
    paddingTop: 6,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
  },

  tableSectionSpecialSecond: {
    width: 105,
    paddingTop: 12,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
  },

  tableTotalSection: {
    width: 105,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
    backgroundColor: "#4285f4",
  },
  tableMonthlyFeeSection: {
    width: 75,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
  },

  tableMonthlyFeeSectionSpecial: {
    paddingTop: 7,
    width: 75,
    borderLeft: 0.5,
    borderRight: 0.5,
    borderTop: 0.5,
    borderBottom: 0.5,
  },

  tableText: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
  },

  tableTextHeader: {
    fontSize: 12,
    fontFamily: "Times-Roman",
    textAlign: "center",
    color: "white",
  },
  tableHeaderRow: {
    backgroundColor: "#4285f4",
    flexDirection: "row",
  },
  tableTotalHeaderRow: {
    flexDirection: "row",
    paddingLeft: 300,
  },

  subTitle: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Oswald",
    paddingBottom: 13,
    paddingTop: 13,
  },
  subTitleTotals: {
    fontSize: 16,
    textAlign: "center",
    fontFamily: "Oswald",
    paddingBottom: 13,
    paddingTop: 13,
    paddingLeft: 280,
  },
});

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create Document Component
const MyDocument = (props) => (
  <Document>
    <Page style={styles.body}>
      <View style={styles.imageView}>
        <Image styles={styles.image} src="/images/mps-logo-black.png" />
      </View>

      <Text style={styles.title}>Merchant Services Quote</Text>
      <View style={styles.row}>
        <View style={styles.col}>
          <Text style={styles.text}>
            {props.data.contactName ? props.data.contactName : ""}
          </Text>
          <Text style={styles.text}>
            {props.data.dba ? props.data.dba : ""}
          </Text>
          <Text style={styles.text}>
            {props.data.physicalLocation ? props.data.physicalLocation : ""}
          </Text>
          <Text style={styles.text}>
            {(props.data.city ? props.data.city : "") +
              ", " +
              (props.data.state ? props.data.state : "") +
              ", " +
              (props.data.zipcode ? props.data.zipcode : "")}
          </Text>
        </View>

        <View style={styles.colRight}>
          <Text style={styles.textRight}>
            Sales Volume: $
            {props.data.estimatedSalesVolume
              ? props.data.estimatedSalesVolume.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : ""}
          </Text>
          <Text style={styles.textRight}>
            Average Ticket: $
            {props.data.estimatedSalesVolume
              ? props.data.estimatedAverageTicket.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : ""}
          </Text>

          {props.data.selectedQuotePricing === "Interchange Plus" ? (
            <Text style={styles.textRight}>
              Effective Rate:{" "}
              {props.data.interchangeQuoteTotals.effectiveRate.toFixed(2) + "%"}
            </Text>
          ) : (
            <Text style={styles.textRight}></Text>
          )}
          {props.data.selectedQuotePricing === "Flat Rate" ? (
            <Text style={styles.textRight}>
              Effective Rate:{" "}
              {props.data.flatRateQuoteTotals.effectiveRate.toFixed(2) + "%"}
            </Text>
          ) : (
            <Text style={styles.textRight}></Text>
          )}
          {props.data.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
            <Text style={styles.textRight}>
              Effective Rate:{" "}
              {props.data.flatRateDowngradeQuoteTotals.effectiveRate.toFixed(
                2
              ) + "%"}
            </Text>
          ) : (
            <Text style={styles.textRight}></Text>
          )}

          {props.data.selectedQuotePricing === "Tier Pricing" ? (
            <Text style={styles.textRight}>
              Effective Rate:{" "}
              {props.data.tierPricingQuoteTotals.effectiveRate.toFixed(2) + "%"}
            </Text>
          ) : (
            <Text style={styles.textRight}></Text>
          )}
        </View>
      </View>

      <View style={{ borderBottom: 1, paddingTop: 12 }}></View>

      <Text style={styles.subTitle}>{props.data.selectedQuotePricing}</Text>

      {/* Interchange Header Table Begins*/}
      <View style={styles.tableHeaderRow}>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}></Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableTextHeader}>Discount</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableTextHeader}>Authorization</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableTextHeader}>Other Transactions</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableTextHeader}>Effective Rate</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>Current Processor</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>
            {props.data.previousProcessorDiscountRate
              ? props.data.previousProcessorDiscountRate + "%"
              : "N/A"}
          </Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>
            {props.data.previousProcessorTransactionFees
              ? props.data.previousProcessorTransactionFees * 100 + "¢"
              : "N/A"}
          </Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>-</Text>
          {/* <Text style={styles.tableText}>
            {props.data.previousProcessorOtherFees
              ? props.data.previousProcessorOtherFees
              : "N/A"}
          </Text> */}
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>
            {props.data.previousProcessor === true ? (
              parseFloat(
                ((props.data.previousProcessorTotalBillAmount -
                  props.data.previousProcessorOtherFees) /
                  props.data.previousProcessorSalesVolume) *
                  100
              ).toFixed(2) + "%"
            ) : (
              <Text>N/A</Text>
            )}
          </Text>
        </View>
      </View>

      {props.data.selectedQuotePricing === "Interchange Plus" ? (
        <React.Fragment>
          <View style={styles.row}>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>New Quote</Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.interchangeDiscount
                  ? (props.data.interchangeDiscount / 100).toFixed(2) + "%"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.interchangeAuthorizationFee
                  ? props.data.interchangeAuthorizationFee * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.interchangeOtherTransactionFees
                  ? props.data.interchangeOtherTransactionFees * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.interchangeQuoteTotals.effectiveRate
                  ? props.data.interchangeQuoteTotals.effectiveRate.toFixed(2) +
                    "%"
                  : "N/A"}
              </Text>
            </View>
          </View>
        </React.Fragment>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Flat Rate" ? (
        <React.Fragment>
          <View style={styles.row}>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>New Quote</Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.flatRateDiscount
                  ? (props.data.flatRateDiscount / 100).toFixed(2) + "%"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.flatRateAuthorizationFee
                  ? props.data.flatRateAuthorizationFee * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.flatRateOtherTransactionFees
                  ? props.data.flatRateOtherTransactionFees * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                {props.data.flatRateQuoteTotals.effectiveRate
                  ? props.data.flatRateQuoteTotals.effectiveRate.toFixed(2) +
                    "%"
                  : "N/A"}
              </Text>
            </View>
          </View>
        </React.Fragment>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
        <React.Fragment>
          <View style={styles.row}>
            <View style={styles.tableSectionSpecial}>
              <Text style={styles.tableText}>New Quote</Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                Qual:{" "}
                {props.data.flatRateDowngradeFlatRate
                  ? props.data.flatRateDowngradeFlatRate.toFixed(2) + "%"
                  : "N/A"}
              </Text>
              <Text style={styles.tableText}>
                Non-Qual:{" "}
                {props.data.flatRateDowngradeDowngrade
                  ? props.data.flatRateDowngradeDowngrade.toFixed(2) + "%"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                Qual:{" "}
                {props.data.flatRateDowngradeAuthorizationFeeFlatRate
                  ? props.data.flatRateDowngradeAuthorizationFeeFlatRate * 100 +
                    "¢"
                  : "N/A"}
              </Text>
              <Text style={styles.tableText}>
                Non-Qual:{" "}
                {props.data.flatRateDowngradeAuthorizationFeeDowngrade
                  ? props.data.flatRateDowngradeAuthorizationFeeDowngrade *
                      100 +
                    "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSectionSpecial}>
              <Text style={styles.tableText}>
                {props.data.flatRateDowngradeOtherTransactionFee
                  ? props.data.flatRateDowngradeOtherTransactionFee * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSectionSpecial}>
              <Text style={styles.tableText}>
                {props.data.flatRateDowngradeQuoteTotals.effectiveRate
                  ? props.data.flatRateDowngradeQuoteTotals.effectiveRate.toFixed(
                      2
                    ) + "%"
                  : "N/A"}
              </Text>
            </View>
          </View>
        </React.Fragment>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Tier Pricing" ? (
        <React.Fragment>
          <View style={styles.row}>
            <View style={styles.tableSectionSpecialSecond}>
              <Text style={styles.tableText}>New Quote</Text>
            </View>
            <View style={styles.tableSection}>
              <Text style={styles.tableText}>
                Qual:{" "}
                {props.data.tierPricingQualified
                  ? props.data.tierPricingQualified.toFixed(2) + "%"
                  : "N/A"}
              </Text>
              <Text style={styles.tableText}>
                Mid:{" "}
                {props.data.tierPricingMidQualified
                  ? props.data.tierPricingMidQualified.toFixed(2) + "%"
                  : "N/A"}
              </Text>
              <Text style={styles.tableText}>
                Non:{" "}
                {props.data.tierPricingNonQualified
                  ? props.data.tierPricingNonQualified.toFixed(2) + "%"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSectionSpecialSecond}>
              <Text style={styles.tableText}>
                {props.data.tierPricingAuthorizationFee
                  ? props.data.tierPricingAuthorizationFee * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSectionSpecialSecond}>
              <Text style={styles.tableText}>
                {props.data.tierPricingOtherTransactionFees
                  ? props.data.tierPricingOtherTransactionFees * 100 + "¢"
                  : "N/A"}
              </Text>
            </View>
            <View style={styles.tableSectionSpecialSecond}>
              <Text style={styles.tableText}>
                {props.data.tierPricingQuoteTotals.effectiveRate
                  ? props.data.tierPricingQuoteTotals.effectiveRate.toFixed(2) +
                    "%"
                  : "N/A"}
              </Text>
            </View>
          </View>
        </React.Fragment>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      <Text style={styles.subTitle}>Monthly/Other Fees</Text>

      {/* Monthly Fees Header Table Begins*/}
      <View style={styles.tableHeaderRow}>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableText}></Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>Statement</Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>Gateway</Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>Chargeback</Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>PCI</Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>Equipment</Text>
        </View>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableTextHeader}>Other</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.tableMonthlyFeeSection}>
          <Text style={styles.tableText}>Current Processor</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>N/A</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>N/A</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>N/A</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>N/A</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>N/A</Text>
        </View>
        <View style={styles.tableMonthlyFeeSectionSpecial}>
          <Text style={styles.tableText}>
            {props.data.previousProcessorOtherFees
              ? "$" + props.data.previousProcessorOtherFees
              : "N/A"}
          </Text>
        </View>
      </View>

      {props.data.selectedQuotePricing === "Interchange Plus" ? (
        <View style={styles.row}>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>New Quote</Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeMonthlyServiceFee
                ? "$" + props.data.interchangeMonthlyServiceFee
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeMonthlyGatewayFees
                ? "$" + props.data.interchangeMonthlyGatewayFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeChargebackFees
                ? "$" + props.data.interchangeChargebackFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeMonthlyPciRegulatoryFees
                ? "$" + props.data.interchangeMonthlyPciRegulatoryFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeMonthlyEquipmentFees
                ? "$" + props.data.interchangeMonthlyEquipmentFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.interchangeOtherMonthlyFees
                ? "$" + props.data.interchangeOtherMonthlyFees
                : "N/A"}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Flat Rate" ? (
        <View style={styles.row}>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>New Quote</Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateMonthlyServiceFee
                ? "$" + props.data.flatRateMonthlyServiceFee
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateMonthlyGatewayFees
                ? "$" + props.data.flatRateMonthlyGatewayFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateChargebackFees
                ? "$" + props.data.flatRateChargebackFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateMonthlyPciRegulatoryFees
                ? "$" + props.data.flatRateMonthlyPciRegulatoryFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateMonthlyEquipmentFees
                ? "$" + props.data.flatRateMonthlyEquipmentFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateOtherMonthlyFees
                ? "$" + props.data.flatRateOtherMonthlyFees
                : "N/A"}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
        <View style={styles.row}>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>New Quote</Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateDowngradeMonthlyServiceFee
                ? "$" + props.data.flatRateDowngradeMonthlyServiceFee
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateDowngradeMonthlyGatewayFees
                ? "$" + props.data.flatRateDowngradeMonthlyGatewayFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateDowngradeChargebackFees
                ? "$" + props.data.flatRateDowngradeChargebackFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateDowngradeMonthlyPciRegulatoryFees
                ? "$" + props.data.flatRateDowngradeMonthlyPciRegulatoryFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateMonthlyEquipmentFees
                ? "$" + props.data.flatRateDowngradeMonthlyEquipmentFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.flatRateOtherMonthlyFees
                ? "$" + props.data.flatRateDowngradeOtherMonthlyFees
                : "N/A"}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {props.data.selectedQuotePricing === "Tier Pricing" ? (
        <View style={styles.row}>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>New Quote</Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingMonthlyServiceFee
                ? "$" + props.data.tierPricingMonthlyServiceFee
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingMonthlyGatewayFees
                ? "$" + props.data.tierPricingMonthlyGatewayFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingChargebackFees
                ? "$" + props.data.tierPricingChargebackFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingMonthlyPciRegulatoryFees
                ? "$" + props.data.tierPricingMonthlyPciRegulatoryFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingMonthlyEquipmentFees
                ? "$" + props.data.tierPricingMonthlyEquipmentFees
                : "N/A"}
            </Text>
          </View>
          <View style={styles.tableMonthlyFeeSection}>
            <Text style={styles.tableText}>
              {props.data.tierPricingOtherMonthlyFees
                ? "$" + props.data.tierPricingOtherMonthlyFees
                : "N/A"}
            </Text>
          </View>
        </View>
      ) : (
        <Text style={styles.textRight}></Text>
      )}

      {/* Quote Totals*/}
      <Text style={styles.subTitleTotals}>Merchant Totals</Text>
      <View style={styles.tableTotalHeaderRow}>
        <View style={styles.tableTotalSection}>
          <Text style={styles.tableTextHeader}></Text>
        </View>
        <View style={styles.tableTotalSection}>
          <Text style={styles.tableTextHeader}>Total Fees</Text>
        </View>
      </View>

      <View style={styles.tableTotalRow}>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>Current Processor</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>
            {props.data.previousProcessorTotalBillAmount
              ? "$" +
                props.data.previousProcessorTotalBillAmount.toLocaleString(
                  undefined,
                  {
                    maximumFractionDigits: 2,
                  }
                )
              : "N/A"}
          </Text>
        </View>
      </View>

      <View style={styles.tableTotalRow}>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>New Quote</Text>
        </View>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>
            {props.data.selectedQuotePricing === "Interchange Plus" ? (
              <Text style={styles.tableText}>
                {props.data.interchangeQuoteTotals
                  ? "$" +
                    props.data.interchangeQuoteTotals.merchantTotalFees.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )
                  : "N/A"}
              </Text>
            ) : (
              <Text style={styles.textRight}></Text>
            )}

            {props.data.selectedQuotePricing === "Flat Rate" ? (
              <Text style={styles.tableText}>
                {props.data.flatRateQuoteTotals
                  ? "$" +
                    props.data.flatRateQuoteTotals.merchantTotalFees.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )
                  : "N/A"}
              </Text>
            ) : (
              <Text style={styles.textRight}></Text>
            )}

            {props.data.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
              <Text style={styles.tableText}>
                {props.data.flatRateDowngradeQuoteTotals
                  ? "$" +
                    props.data.flatRateDowngradeQuoteTotals.merchantTotalFees.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )
                  : "N/A"}
              </Text>
            ) : (
              <Text style={styles.textRight}></Text>
            )}

            {props.data.selectedQuotePricing === "Tier Pricing" ? (
              <Text style={styles.tableText}>
                {props.data.tierPricingQuoteTotals
                  ? "$" +
                    props.data.tierPricingQuoteTotals.merchantTotalFees.toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )
                  : "N/A"}
              </Text>
            ) : (
              <Text style={styles.textRight}></Text>
            )}
          </Text>
        </View>
      </View>

      <View style={styles.tableTotalRow}>
        <View style={styles.tableSection}>
          <Text style={styles.tableText}>Total Savings</Text>
        </View>
        <View style={styles.tableSection}>
          {props.data.selectedQuotePricing === "Interchange Plus" ? (
            <Text style={styles.tableText}>
              {"$" +
                (
                  props.data.previousProcessorTotalBillAmount -
                  props.data.interchangeQuoteTotals.merchantTotalFees
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
            </Text>
          ) : (
            <Text style={styles.tableText}></Text>
          )}

          {props.data.selectedQuotePricing === "Flat Rate" ? (
            <Text style={styles.tableText}>
              {"$" +
                (
                  props.data.previousProcessorTotalBillAmount -
                  props.data.flatRateQuoteTotals.merchantTotalFees
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
            </Text>
          ) : (
            <Text style={styles.tableText}></Text>
          )}
          {props.data.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
            <Text style={styles.tableText}>
              {"$" +
                (
                  props.data.previousProcessorTotalBillAmount -
                  props.data.flatRateDowngradeQuoteTotals.merchantTotalFees
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
            </Text>
          ) : (
            <Text style={styles.tableText}></Text>
          )}

          {props.data.selectedQuotePricing === "Tier Pricing" ? (
            <Text style={styles.tableText}>
              {"$" +
                (
                  props.data.previousProcessorTotalBillAmount -
                  props.data.tierPricingQuoteTotals.merchantTotalFees
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })}
            </Text>
          ) : (
            <Text style={styles.tableText}></Text>
          )}
        </View>
      </View>

      <View style={{ borderBottom: 1, paddingTop: 15 }}></View>
      <Text style={styles.textBottomNote}>
        - If you have any question regarding this quote, please contact{" "}
        {props.userContext.currentUser().firstName +
          " " +
          props.userContext.currentUser().lastName}{" "}
        at 786-828-0029.
      </Text>

      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
    </Page>
  </Document>
);

export default PdfGenerator;
