import React, { useContext, useState, useEffect } from "react";
import UserContext from "../../context/userContext";
import { getAdminDashboardWeeklyStats } from "../../services/reportService";
import auth from "../../services/authService";
import "./Dashboard.css";
import { Redirect } from "react-router-dom";
import RecentQuotesTable from "../../components/RecentQuotesTable";
import {
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBIcon,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBDatePicker,
  MDBTooltip,
  MDBSimpleChart,
  MDBBadge,
  MDBView,
  MDBBtn,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBCardHeader,
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBTable,
  MDBBtnFixed,
  MDBBtnFixedItem,
  MDBCardTitle,
  MDBCardText,
  MDBDatePickerV5,
} from "mdbreact";

import { Line } from "react-chartjs-2";

const Dashboard = () => {
  const userContext = useContext(UserContext);
  const [state, setState] = useState({
    toggle: false,
    windowWidth: 0,
    currentPage: "",
    sideNavToggled: false,
    breakWidth: 1400,
    buttonStyle: {
      transform: "scaleY(0.4) scaleX(0.4) translateY(40px) translateX(0)",
      opacity: "0",
    },
  });

  const [weeklyStats, setWeeklyStats] = useState({
    totalGeneratedQuotes: 0,
    totalSalesVolumeOfGeneratedQuotes: 0,
    totalEstimatedProfitsOfGeneratedQuotes: 0,
    totalQuotesPendingToBeApproved: 0,
    lastWeekTotalGeneratedQuotes: 0,
    lastWeektotalSalesVolumeOfGeneratedQuotes: 0,
    lastWeektotalEstimatedProfitsOfGeneratedQuotes: 0,
    lastWeektotalQuotesPendingToBeApproved: 0,
  });

  useEffect(() => {
    async function getWeeklyDashboardStats() {
      const weeklyQuoteStats = await getAdminDashboardWeeklyStats();

      setWeeklyStats(weeklyQuoteStats.data);
    }

    {
      userContext.currentUser().role === 1 && getWeeklyDashboardStats();
    }

    {
      userContext.currentUser().role === 2 && getWeeklyDashboardStats();
    }
  }, []);

  if (!auth.getCurrentUser()) return <Redirect to={"/Login"} />;

  const dynamicLeftPadding = {
    paddingLeft: state.windowWidth > state.breakWidth ? "240px" : "0",
  };

  const lineChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Total Quotes",
        backgroundColor: "rgba(255,255,255,0.4)",
        borderColor: "#fff",
        pointBackgroundColor: "transparent",
        borderWidth: 2,
        pointBorderColor: "#fff",
        pointBorderWidth: 1,
        data: [30, 41, 23, 34, 43, 56, 70],
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    legend: {
      labels: {
        fontColor: "white",
        fontSize: 18,
      },
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(255, 255, 255, 0.2)",
          },
          ticks: {
            fontColor: "#fff",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: true,
            color: "rgba(255, 255, 255, 0.2)",
          },
          ticks: {
            fontColor: "#fff",
          },
        },
      ],
    },
  };

  return (
    <div style={{ ...dynamicLeftPadding, margin: "8rem 6% 6rem" }}>
      <section>
        {" "}
        <MDBRow>
          <MDBCol>
            {" "}
            <MDBCard wide cascade className="m">
              <MDBView cascade className="gradient-card-header blue-gradient">
                <h3>
                  <strong>Merchant Pricing Tool</strong>
                </h3>

                {/* <MDBCardImage
            hover
            overlay="white-slight"
            className="card-img-top mx-auto "
            src="https://faithconnect.s3.us-east-2.amazonaws.com/images/blue-gradient-background.jpg"
            alt="Card cap"
            style={{ maxHeight: "100px"}}
          /> */}
              </MDBView>

              <MDBCardBody cascade className="text-center pt-4 pb-5">
                <h4 className="font-weight-bold blue-text pb-3">
                  {userContext.currentUser().role === 1 ? "Admin " : ""}
                  {userContext.currentUser().role === 2 ? "Support " : ""} Quote
                  Dashboard
                </h4>

                <MDBCardText>
                  <h5>
                    {" "}
                    Welcome to the Merchant Pricing Tool dashboard. Please
                    select any of the options below to get started.
                  </h5>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section>
      <section className="my-5">
        {userContext.currentUser().role === 1 ? (
          <MDBRow>
            {" "}
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon
                    icon="file-invoice-dollar"
                    className="primary-color mr-3 z-depth-2"
                  />
                  <div className="data">
                    <p># OF QUOTES</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalGeneratedQuotes
                        ? weeklyStats.totalGeneratedQuotes
                        : "Loading"}
                    </h4>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalGeneratedQuotes /
                        weeklyStats.lastWeekTotalGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalGeneratedQuotes /
                        weeklyStats.lastWeekTotalGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalGeneratedQuotes /
                    weeklyStats.lastWeekTotalGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalGeneratedQuotes /
                          weeklyStats.lastWeekTotalGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalGeneratedQuotes /
                          weeklyStats.lastWeekTotalGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon icon="money-bill-alt" className="warning-color" />
                  <div className="data">
                    <p>SALES VOLUME</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalSalesVolumeOfGeneratedQuotes
                        ? "$" +
                          weeklyStats.totalSalesVolumeOfGeneratedQuotes.toFixed(
                            2
                          )
                        : "Loading..."}
                    </h4>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                        weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                        weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                    weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                          weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                          weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon
                    icon="hand-holding-usd"
                    className="light-blue lighten-1"
                  />

                  <div className="data">
                    <p>ESTIMATED PROFIT</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalEstimatedProfitsOfGeneratedQuotes
                        ? "$" +
                          weeklyStats.totalEstimatedProfitsOfGeneratedQuotes.toFixed(
                            2
                          )
                        : "Loading..."}
                    </h4>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                        weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                        weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                    weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                          weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                          weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon icon="user-edit" className="red accent-2" />
                  <div className="data">
                    <p>PENDING APPROVAL</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {" "}
                      {weeklyStats.totalQuotesPendingToBeApproved
                        ? weeklyStats.totalQuotesPendingToBeApproved
                        : "Loading"}
                    </h4>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalQuotesPendingToBeApproved /
                        weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalQuotesPendingToBeApproved /
                        weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                        1) *
                        100 >
                      0
                        ? "red accent-2"
                        : "bg-primary"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalQuotesPendingToBeApproved /
                    weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                    1) *
                    100 <
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalQuotesPendingToBeApproved /
                          weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalQuotesPendingToBeApproved /
                          weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        )}
        {userContext.currentUser().role === 2 ? (
          <MDBRow>
            {" "}
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon
                    icon="file-invoice-dollar"
                    className="primary-color mr-3 z-depth-2"
                  />
                  <div className="data">
                    <p># OF QUOTES</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalGeneratedQuotes
                        ? weeklyStats.totalGeneratedQuotes
                        : "Loading"}
                    </h4>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalGeneratedQuotes /
                        weeklyStats.lastWeekTotalGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalGeneratedQuotes /
                        weeklyStats.lastWeekTotalGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalGeneratedQuotes /
                    weeklyStats.lastWeekTotalGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalGeneratedQuotes /
                          weeklyStats.lastWeekTotalGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalGeneratedQuotes /
                          weeklyStats.lastWeekTotalGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon icon="money-bill-alt" className="warning-color" />
                  <div className="data">
                    <p>SALES VOLUME</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalSalesVolumeOfGeneratedQuotes
                        ? "$" +
                          weeklyStats.totalSalesVolumeOfGeneratedQuotes.toFixed(
                            2
                          )
                        : "Loading..."}
                    </h4>
                  </div>
                </div>
                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                        weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                        weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                    weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                          weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalSalesVolumeOfGeneratedQuotes /
                          weeklyStats.lastWeektotalSalesVolumeOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon
                    icon="hand-holding-usd"
                    className="light-blue lighten-1"
                  />

                  <div className="data">
                    <p>ESTIMATED PROFIT</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {weeklyStats.totalEstimatedProfitsOfGeneratedQuotes
                        ? "$" +
                          weeklyStats.totalEstimatedProfitsOfGeneratedQuotes.toFixed(
                            2
                          )
                        : "Loading..."}
                    </h4>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                        weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                        weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                        1) *
                        100 >
                      0
                        ? "bg-primary"
                        : "red accent-2"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                    weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                    1) *
                    100 >
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                          weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalEstimatedProfitsOfGeneratedQuotes /
                          weeklyStats.lastWeektotalEstimatedProfitsOfGeneratedQuotes -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
            <MDBCol xl="3" md="6" className="mb-5">
              <MDBCard cascade className="cascading-admin-card">
                <div className="admin-up">
                  <MDBIcon icon="user-edit" className="red accent-2" />
                  <div className="data">
                    <p>PENDING APPROVAL</p>
                    <h4 className="font-weight-bold dark-grey-text">
                      {" "}
                      {weeklyStats.totalQuotesPendingToBeApproved
                        ? weeklyStats.totalQuotesPendingToBeApproved
                        : "Loading"}
                    </h4>
                  </div>
                </div>

                <MDBCardBody cascade>
                  <MDBProgress
                    value={Math.abs(
                      (weeklyStats.totalQuotesPendingToBeApproved /
                        weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                        1) *
                        100
                    )}
                    barClassName={
                      (weeklyStats.totalQuotesPendingToBeApproved /
                        weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                        1) *
                        100 >
                      0
                        ? "red accent-2"
                        : "bg-primary"
                    }
                    height="6px"
                    wrapperStyle={{ opacity: ".7" }}
                    className="mb-3"
                  />

                  {(weeklyStats.totalQuotesPendingToBeApproved /
                    weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                    1) *
                    100 <
                  0 ? (
                    <p className="card-text">
                      Better than last week (
                      {Number(
                        (weeklyStats.totalQuotesPendingToBeApproved /
                          weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  ) : (
                    <p className="card-text">
                      Worse than last week (
                      {Number(
                        (weeklyStats.totalQuotesPendingToBeApproved /
                          weeklyStats.lastWeektotalQuotesPendingToBeApproved -
                          1) *
                          100
                      ).toFixed()}
                      %)
                    </p>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          </MDBRow>
        ) : (
          ""
        )}
      </section>

      <section className="my-4">
        {/* <MDBCard cascade narrow>
          <section>
            <MDBRow>
              <MDBCol xl="5" lg="12" className="mr-0">
                <div className="view view-cascade gradient-card-header light-blue lighten-1">
                  <h2 className="h2-responsive mb-0">Quotes</h2>
                </div>
                <MDBCardBody cascade className="pb-0">
                  <MDBCardBody className="row pt-3">
                    <MDBCol md="6">
                      <p className="lead">
                        <span className="badge info-color p-2">Data range</span>
                      </p>
                      <MDBSelect>
                        <MDBSelectInput selected="Choose time period" />
                        <MDBSelectOptions>
                          <MDBSelectOption disabled>
                            Choose time period
                          </MDBSelectOption>
                          <MDBSelectOption value="1">Today</MDBSelectOption>
                          <MDBSelectOption value="2">Yesterday</MDBSelectOption>
                          <MDBSelectOption value="3">
                            Last 7 days
                          </MDBSelectOption>
                          <MDBSelectOption value="3">
                            Last 30 days
                          </MDBSelectOption>
                          <MDBSelectOption value="3">Last week</MDBSelectOption>
                          <MDBSelectOption value="3">
                            Last month
                          </MDBSelectOption>
                        </MDBSelectOptions>
                      </MDBSelect>

                      <p className="lead mt-5">
                        <span className="badge info-color p-2">
                          Custom date
                        </span>
                      </p>
                      <br />
                      <div className="mb-1">
                        <MDBRow>
                          <MDBCol size="12" className="mb-2">
                            <small className="grey-text">from:</small>
                            <MDBDatePicker className="my-0 d-inline ml-3" />
                          </MDBCol>
                          <MDBCol size="12">
                            <small className="grey-text">to:</small>
                            <MDBDatePicker className="my-0 d-inline ml-3" />
                          </MDBCol>
                        </MDBRow>
                      </div>
                    </MDBCol>
                    <MDBCol md="6" className="text-center">
                      <div style={{ marginBottom: "0.5rem" }}>
                        Monthly Sales: <strong>$2000</strong>
                        <MDBTooltip>
                          <MDBBtn color="info" className="btn-sm p2 d-inline">
                            <MDBIcon icon="question" />
                          </MDBBtn>
                          <div>
                            Total quoted monthly sales in the given period
                          </div>
                        </MDBTooltip>
                      </div>
                      <br />

                      <div style={{ marginBottom: "0.5rem" }}>
                        Est. Monthly Profit: <strong>$100</strong>
                        <MDBTooltip>
                          <MDBBtn color="info" className="btn-sm p2 d-inline">
                            <MDBIcon icon="question" />
                          </MDBBtn>
                          <div>
                            Total quoted monthly profit in the given period
                          </div>
                        </MDBTooltip>
                      </div>

                      <div style={{ marginBottom: "0.5rem" }}>
                        Est. Margin: <strong>PROFIT/SALES%</strong>
                        <MDBTooltip>
                          <MDBBtn color="info" className="btn-sm p2 d-inline">
                            <MDBIcon icon="question" />
                          </MDBBtn>
                          <div>
                            Total quoted monthly profit in the given period
                          </div>
                        </MDBTooltip>
                      </div>
                      <br />
                    </MDBCol>
                  </MDBCardBody>
                </MDBCardBody>
              </MDBCol>
              <MDBCol xl="7" lg="12" className="mb-4">
                <MDBView cascade className="gradient-card-header blue-gradient">
                  <Line data={lineChartData} options={lineChartOptions} />
                </MDBView>
              </MDBCol>
            </MDBRow>
          </section>
        </MDBCard> */}
        <section className="mt-5">
          <RecentQuotesTable />
        </section>
      </section>

      {/* <section>
        <MDBRow>
          <MDBCol xl="3" md="6" className="mb-4">
            <MDBCard
              color="primary-color"
              className="classic-admin-card white-text"
            >
              <MDBCardBody>
                <div className="float-right">
                  <MDBIcon icon="money-bill-alt" />
                </div>
                <p className="white-text">TOTAL QUOTES</p>
                <h4>$2000</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol xl="3" md="6" className="mb-4">
            <MDBCard
              color="warning-color"
              className="classic-admin-card white-text"
            >
              <MDBCardBody>
                <div className="float-right">
                  <MDBIcon icon="chart-line" />
                </div>
                <p className="white-text">TOTAL SALES</p>
                <h4>200</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol xl="3" md="6" className="mb-4">
            <MDBCard
              color="info-color"
              className="classic-admin-card white-text"
            >
              <MDBCardBody>
                <div className="float-right">
                  <MDBIcon icon="chart-pie" />
                </div>
                <p className="white-text">TOTAL PROFIT</p>
                <h4>20000</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol xl="3" md="6" className="mb-4">
            <MDBCard
              color="green accent-5"
              className="classic-admin-card white-text"
            >
              <MDBCardBody>
                <div className="float-right">
                  <MDBIcon icon="chart-bar" />
                </div>
                <p className="white-text">APPROVED QUOTES</p>
                <h4>2000</h4>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </section> */}
    </div>
  );
};

export default Dashboard;
