import React, { Component } from "react";
import UserContext from "../../context/userContext";
import MDBCustomStep from "../Step";
import { Redirect } from "react-router-dom";
import Loading from "../../pages/Loading";
import GaugeChart from "react-gauge-chart";
import {
  UpdateQuotePricingInformation,
  RetrieveSingleQuoteById,
  UpdateInitialQuoteInformation,
} from "../../services/quoteService";

import { getCostSettings } from "../../services/settingsService";
import { getCompetitionPricing } from "../../services/reportService";
import { UpdateQuoteStatusCommentById } from "../../services/quoteService";

import {
  MDBRow,
  MDBCol,
  MDBStepper,
  MDBStep,
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBSelect,
  MDBIcon,
  MDBNavItem,
  MDBNavLink,
  MDBContainer,
  MDBNav,
  MDBTabContent,
  MDBTabPane,
  MDBSticky,
  MDBStickyContent,
  MDBModal,
  MDBModalHeader,
  MDBModalFooter,
  MDBModalBody,
  MDBFormInline,
  MDBTable,
  MDBTableBody,
  MDBCardTitle,
  MDBCardImage,
  MDBCardHeader,
  MDBCardText,
  MDBTableHead,
  MDBBadge,
} from "mdbreact";
import "./EditQuoteForm.css";

class EditQuoteForm extends Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      quoteId: props.match.params.id,
      isOpen: false,
      isLoggedIn: false,
      formActivePanel3: 1,
      formActivePanel1Changed: false,
      redirectLogin: false,
      redirectQuote: false,

      stateOptions: [
        { text: "AL", value: "AL" },
        { text: "AK", value: "AK" },
        { text: "AZ", value: "AZ" },
        { text: "AR", value: "AR" },
        { text: "CA", value: "CA" },
        { text: "CO", value: "CO" },
        { text: "CT", value: "CT" },
        { text: "DE", value: "DE" },
        { text: "FL", value: "FL" },
        { text: "GA", value: "GA" },
        { text: "HI", value: "HI" },
        { text: "ID", value: "ID" },
        { text: "IL", value: "IL" },
        { text: "IN", value: "IN" },
        { text: "IA", value: "IA" },
        { text: "KS", value: "KS" },
        { text: "KY", value: "KY" },
        { text: "LA", value: "LA" },
        { text: "ME", value: "ME" },
        { text: "MD", value: "MD" },
        { text: "MA", value: "MA" },
        { text: "MI", value: "MI" },
        { text: "MN", value: "MN" },
        { text: "MS", value: "MS" },
        { text: "MO", value: "MO" },
        { text: "MT", value: "MT" },
        { text: "NE", value: "NE" },
        { text: "NV", value: "NV" },
        { text: "NH", value: "NH" },
        { text: "NJ", value: "NJ" },
        { text: "NM", value: "NM" },
        { text: "NY", value: "NY" },
        { text: "NC", value: "NC" },
        { text: "ND", value: "ND" },
        { text: "OH", value: "OH" },
        { text: "OK", value: "OK" },
        { text: "OR", value: "OR" },
        { text: "PA", value: "PA" },
        { text: "RI", value: "RI" },
        { text: "SC", value: "SC" },
        { text: "SD", value: "SD" },
        { text: "TN", value: "TN" },
        { text: "TX", value: "TX" },
        { text: "UT", value: "UT" },
        { text: "VT", value: "VT" },
        { text: "VA", value: "VA" },
        { text: "WA", value: "WA" },
        { text: "WV", value: "WV" },
        { text: "WI", value: "WI" },
        { text: "WY", value: "WY" },
      ],

      mccCategoryOptions: [
        {
          text: "Amusement and Entertainment",
          value: "1",
        },
        {
          text: "Associations",
          value: "2",
        },
        {
          text: "Business Services",
          value: "3",
        },
        {
          text: "Clothing and Accessories",
          value: "4",
        },
        {
          text: "Contracted Services",
          value: "5",
        },
        {
          text: "Direct Marketing",
          value: "6",
        },
        {
          text: "Eating and Drinking Places",
          value: "7",
        },
        {
          text: "Education",
          value: "8",
        },
        {
          text: "Government Services",
          value: "9",
        },
        {
          text: "Home and Garden",
          value: "10",
        },
        {
          text: "Miscellaneous Stores",
          value: "11",
        },
        {
          text: "Personal Services",
          value: "12",
        },
        {
          text: "Professional Services",
          value: "13",
        },
        {
          text: "Repair",
          value: "14",
        },
        {
          text: "Retail Store",
          value: "15",
        },
        {
          text: "Telecommunications",
          value: "16",
        },
        {
          text: "Transportation",
          value: "17",
        },
        {
          text: "Utilities",
          value: "18",
        },
        {
          text: "Wholesale",
          value: "19",
        },
      ],
      priceOptions: [
        {
          text: "Interchange Plus",
          value: "1",
        },
        {
          text: "Flat Rate",
          value: "2",
        },
        {
          text: "Flat Rate Plus Downgrade",
          value: "3",
        },
        {
          text: "Tier Pricing",
          value: "4",
        },
      ],
      merchantTypeOptions: [
        {
          text: "Retail",
          value: "1",
        },
        {
          text: "E-Commerce",
          value: "2",
        },
        {
          text: "Both",
          value: "3",
        },
      ],
      previousProcessorPricingModelOptions: [
        {
          text: "Interchange Plus",
          value: "1",
        },
        {
          text: "Flat Rate",
          value: "2",
        },
        {
          text: "Flat Rate Plus Downgrade",
          value: "3",
        },
        {
          text: "Tier Pricing",
          value: "4",
        },
      ],
      merchantServices: false,
      mccCode: [],
      validationSections: [],
      firstStepperStatus: "valid",
      secondStepperStatus: "valid",
      thirdStepperStatus: "valid",
      interchangePassThru: false,
      flatRate: false,
      flatRatePlusDowngrade: false,
      tierPricing: false,
      items: {
        gradient: "1",
      },
      profitabilityScoreRadio: 1,
      activeItem: "1",
      activeItemJustified: "1",
      validationModal: false,
      finalizingQuote: false,
      showMerchantInformation: false,
      costSettingsId: "5f64f69bb5f88c9b0a603fbe",
      interchangeQuoteTotals: "",
      flatRateQuoteTotals: "",
      flatRateDowngradeQuoteTotals: "",
      tierPricingQuoteTotals: "",
      selectedQuotePricing: "",
      quoteApprovalOptions: [
        {
          text: "Approved",
          value: "Approved",
        },
        {
          text: "Declined",
          value: "Declined",
        },
        {
          text: "Pending",
          value: "Pending",
        },
      ],
    };
  }

  componentDidMount() {
    //Logic to log user off if token has expired.
    const token = localStorage.getItem("jwtToken");

    this.retrieveQuoteById();
    this.retrieveCostSettings();
  }

  retrieveCostSettings = () => {
    getCostSettings(this.state.costSettingsId)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          alert(
            "An error has occured retrieving cost settings, please contact technical support."
          );
        } else if (!response.data) {
          alert("There are no cost settings :(");
        } else {
          const data = response.data;
          this.setState(
            {
              costSettingsId: data._id,
              costProfileName: data.profileName,
              costBasisPoints: data.basisPoint,
              costGoalMargin: data.goalMargin,
              costAuthorization: data.authorization,
              costMonthlyStatement: data.monthlyStatement,
              costMonthlyGateway: data.monthlyGateway,
              costChargeback: data.chargeback,
              costPciRegulatory: data.pciRegulatory,
              costEquipment: data.equipment,
              costOtherMonthly: data.otherMonthly,
              customCosts: data.customCosts,
            },
            () => {
              //------------------------------------------------------//
              //------------------Custom Cost Variables---------------//
              //------------------------------------------------------//
              let costCustomMonthly = 0;
              let costCustomPercent = 0;
              let costCustomPerTransaction = 0;
              if (this.state.customCosts.length > 0) {
                //Loop Through Each Cost
                for (const customCost of this.state.customCosts) {
                  //Check if cost is per month, transaction, percent
                  switch (customCost.type) {
                    case "Monthly":
                      costCustomMonthly = costCustomMonthly + customCost.rate;
                      break;
                    case "Percent":
                      costCustomPercent = costCustomPercent + customCost.rate;
                      break;
                    case "Per Transaction":
                      costCustomPerTransaction =
                        costCustomPerTransaction + customCost.rate;
                      break;
                    default:
                      break;
                  }
                }
              }
              this.setState({
                costCustomMonthly,
                costCustomPercent,
                costCustomPerTransaction,
              });
            }
          );
        }
      })
      .catch((error) =>
        alert("An error has occured, please contact technical support.")
      );
  };

  retrieveComparisonPricing = () => {
    getCompetitionPricing(this.state.mccCode, this.state.state).then(
      (response) => {
        const data = response.data;
        const isNull = (value) => typeof value === "object" && !value;
        this.setState({
          effectiveRate: isNull(data) ? 0.0252 : data.effectiveRate,
          discountRate: isNull(data) ? 0.0238 : data.discountRate,
          grossMargin: isNull(data) ? 0.0067 : data.grossMargin,
          transactionPercent: isNull(data) ? 0.0009 : data.transactionPercent,
          miscTransactions: isNull(data) ? 0.06 : data.miscTransactions,
          interchangeCost: isNull(data) ? 0.0166 : data.interchangeCost,
          duesCost: isNull(data) ? 0.0018 : data.duesCost,
          averageTicket: isNull(data) ? 89 : data.averageTicket,
          salesVolume: isNull(data) ? 29800 : data.salesVolume,
        });
      }
    );
  };

  retrieveQuoteById = () => {
    RetrieveSingleQuoteById(this.state.quoteId)
      .then((response) => {
        console.log(response);
        if (response.status !== 200) {
          this.setState({ authorized: false });
          alert("An error has occured, please contact technical support.");
        } else if (response.data.length == 0) {
          this.setState({ authorized: false });
        } else {
          console.log(response);
          let quote = response.data;
          this.setState(
            {
              //-------------------------------------------------------//
              //-----------------Merchant Information------------------//
              //-------------------------------------------------------//
              authorized: true,
              city: quote.city,
              state: quote.state,
              contactName: quote.contactName,
              originator: quote.originator,
              email: quote.email,
              merchantType: quote.merchantType,
              dba: quote.dba,
              // quoteOwnerFirstName: quote.ownerFirstName,
              // quoteOwnerLastName: quote.ownerlastName,
              estimatedAverageTicket: quote.estimatedAverageTicket.toFixed(2),
              estimatedSalesVolume: quote.estimatedSalesVolume,
              mccCategory: quote.mccCategory,
              mccCategoryDescription: quote.mccCategoryDescription,
              mccCode: [quote.mccCode],
              phoneNumber: quote.phoneNumber,
              physicalLocation: quote.physicalLocation,
              previousProcessor: quote.previousProcessor,
              zipcode: quote.zipcode,
              quoteGenerated: quote.quoteGenerated,
              quoteNumber: quote.quoteId,
              interchangePassThru: quote.interchangePassThru,
              flatRate: quote.flatRate,
              flatRatePlusDowngrade: quote.flatRatePlusDowngrade,
              tierPricing: quote.tierPricingOption,
              merchantServices: quote.merchantServices,
              cardPresentPercentage: quote.cardPresentPercentage,
              cardNotPresentPercentage: quote.cardNotPresentPercentage,
              interchangeQuoteTotals: quote.interchangeQuoteTotals,
              flatRateQuoteTotals: quote.flatRateQuoteTotals,
              flatRateDowngradeQuoteTotals:
                quote.flatRatePlusDowngradeQuoteTotals,
              tierPricingQuoteTotals: quote.tierPricingQuoteTotals,
              //-------------------------------------------------------//
              //----------------------Interchange----------------------//
              //-------------------------------------------------------//
              interchangeDiscount:
                quote.interchangePassthruPricing.discount == 0
                  ? 50
                  : quote.interchangePassthruPricing.discount,
              interchangeAuthorizationFee:
                quote.interchangePassthruPricing.authorizationFee == 0
                  ? 0.08
                  : quote.interchangePassthruPricing.authorizationFee,
              interchangeChargebackFees:
                quote.interchangePassthruPricing.chargebackFees,
              interchangeMonthlyEquipmentFees:
                quote.interchangePassthruPricing.equipmentFees,
              interchangeMonthlyGatewayFees:
                quote.interchangePassthruPricing.monthlyGatewayFees,
              interchangeMonthlyPciRegulatoryFees:
                quote.interchangePassthruPricing.monthlyPciRegulatoryFees,
              interchangeMonthlyServiceFee:
                quote.interchangePassthruPricing.monthlyStatementFeeServiceFee,
              interchangeNumberOfTransactions:
                quote.interchangePassthruPricing.numberOfTransactions,
              interchangeOtherMonthlyFees:
                quote.flatRatePassthruPricing.otherMonthlyFees,
              interchangeOtherTransactionFees:
                quote.interchangePassthruPricing.otherTransactionFees == 0
                  ? 0.05
                  : quote.interchangePassthruPricing.otherTransactionFees,
              //-------------------------------------------------------//
              //----------------------Flat Rate------------------------//
              //-------------------------------------------------------//
              flatRateDiscount:
                quote.flatRatePassthruPricing.discount == 0
                  ? 2.95
                  : quote.flatRatePassthruPricing.discount,
              flatRateAuthorizationFee:
                quote.flatRatePassthruPricing.authorizationFee == 0
                  ? 0.08
                  : quote.flatRatePassthruPricing.authorizationFee,
              flatRateChargebackFees:
                quote.flatRatePassthruPricing.chargebackFees,
              flatRateMonthlyEquipmentFees:
                quote.flatRatePassthruPricing.equipmentFees,
              flatRateMonthlyGatewayFees:
                quote.flatRatePassthruPricing.monthlyGatewayFees,
              flatRateMonthlyPciRegulatoryFees:
                quote.flatRatePassthruPricing.monthlyPciRegulatoryFees,
              flatRateMonthlyServiceFee:
                quote.flatRatePassthruPricing.monthlyStatementFeeServiceFee,
              flatRateNumberOfTransactions:
                quote.flatRatePassthruPricing.numberOfTransactions,
              flatRateOtherMonthlyFees:
                quote.flatRatePassthruPricing.otherMonthlyFees,
              flatRateOtherTransactionFees:
                quote.flatRatePassthruPricing.otherTransactionFees,
              //-------------------------------------------------------//
              //-----------------Flat Rate Downgrade-------------------//
              //-------------------------------------------------------//
              flatRateDowngradeAuthorizationFeeDowngrade:
                quote.flatRatePlusDowngradePricing.authorizationFeeDowngrade ==
                0
                  ? 0.15
                  : quote.flatRatePlusDowngradePricing
                      .authorizationFeeDowngrade,
              flatRateDowngradeAuthorizationFeeFlatRate:
                quote.flatRatePlusDowngradePricing.authorizationFeeFlatRate == 0
                  ? 0.08
                  : quote.flatRatePlusDowngradePricing.authorizationFeeFlatRate,
              flatRateDowngradeChargebackFees:
                quote.flatRatePlusDowngradePricing.chargeBackFees,
              flatRateDowngradeDowngrade:
                quote.flatRatePlusDowngradePricing.downgrade == 0
                  ? 3.5
                  : quote.flatRatePlusDowngradePricing.downgrade,
              flatRateDowngradeDowngradePercent:
                quote.flatRatePlusDowngradePricing.downgradePercent == 0
                  ? 20
                  : quote.flatRatePlusDowngradePricing.downgradePercent,
              flatRateDowngradeEstimateNumberOfTransactions:
                quote.flatRatePlusDowngradePricing.estimateNumberOfTransactions,
              flatRateDowngradeFlatRate:
                quote.flatRatePlusDowngradePricing.flatRate == 0
                  ? 2.5
                  : quote.flatRatePlusDowngradePricing.flatRate,
              flatRateDowngradeFlatRatePercent:
                quote.flatRatePlusDowngradePricing.flatRatePercent == 0
                  ? 80
                  : quote.flatRatePlusDowngradePricing.flatRatePercent,
              flatRateDowngradeMonthlyEquipmentFees:
                quote.flatRatePlusDowngradePricing.equipmentFees,
              flatRateDowngradeMonthlyGatewayFees:
                quote.flatRatePlusDowngradePricing.monthlyGatewayFees,
              flatRateDowngradeMonthlyPciRegulatoryFees:
                quote.flatRatePlusDowngradePricing.monthlyPciRegulatoryFees,
              flatRateDowngradeMonthlyServiceFee:
                quote.flatRatePlusDowngradePricing.monthlyStatementServiceFee,
              flatRateDowngradeOtherMonthlyFees:
                quote.flatRatePlusDowngradePricing.otherMonthlyFees,
              flatRateDowngradeOtherTransactionFee:
                quote.flatRatePlusDowngradePricing.otherTransactionFees,
              //-------------------------------------------------------//
              //---------------------Tier Pricing----------------------//
              //-------------------------------------------------------//
              tierPricingAuthorizationFee:
                quote.tierPricing.authorizationFee == 0
                  ? 0.08
                  : quote.tierPricing.authorizationFee,
              tierPricingChargebackFees: quote.tierPricing.chargebackFees,
              tierPricingEstimateNumberOfTransactions:
                quote.tierPricing.estimateNumberOfTransactions,
              tierPricingMidQualified:
                quote.tierPricing.midQualified == 0
                  ? 2.85
                  : quote.tierPricing.midQualified,
              tierPricingMidQualifiedPercent:
                quote.tierPricing.midQualifiedPercent == 0
                  ? 25
                  : quote.tierPricing.midQualifiedPercent,
              tierPricingMonthlyEquipmentFees: quote.tierPricing.equipmentFees,
              tierPricingMonthlyGatewayFees:
                quote.tierPricing.monthlyGatewayFees,
              tierPricingMonthlyPciRegulatoryFees:
                quote.tierPricing.monthlyPciRegulatoryFees,
              tierPricingMonthlyServiceFee:
                quote.tierPricing.monthlyStatementServiceFee,
              tierPricingNonQualified:
                quote.tierPricing.nonQualified == 0
                  ? 3.15
                  : quote.tierPricing.nonQualified,
              tierPricingNonQualifiedPercent:
                quote.tierPricing.nonQualifiedPercent == 0
                  ? 20
                  : quote.tierPricing.nonQualifiedPercent,
              tierPricingOtherMonthlyFees: quote.tierPricing.otherMonthlyFees,
              tierPricingOtherTransactionFees:
                quote.tierPricing.otherTransactionFees,
              tierPricingQualified:
                quote.tierPricing.qualified == 0
                  ? 2.55
                  : quote.tierPricing.qualified,
              tierPricingQualifiedPercent:
                quote.tierPricing.qualifiedPercent == 0
                  ? 55
                  : quote.tierPricing.qualifiedPercent,
              selectedQuotePricing: quote.quotePricingModel,
              quoteStatus: quote.quoteStatus,
              quoteApprovalNotes: quote.quoteApprovalNotes,
              previousProcessorSalesVolume: quote.previousProcessorSalesVolume,
              previousProcessorDiscountRate:
                quote.previousProcessorDiscountRate,
              previousProcessorTransactionFees:
                quote.previousProcessorTransactionFees,
              previousProcessorOtherFees: quote.previousProcessorOtherFees,
              previousProcessorTotalBillAmount:
                quote.previousProcessorTotalBillAmount,
              previousProcessorPricingModelOptionsType:
                quote.previousProcessorPricingModelOptionsType,
            },
            () => {
              this.retrieveComparisonPricing();
            }
          );
        }
      })
      .catch((error) => {
        console.log(error);
        alert(
          "An error has occured retrieving single quote by id, please contact technical support."
        );
      });
  };

  toggle = (tab) => () => {
    if (this.state.activeItem !== tab) {
      this.setState(
        {
          items: { gradient: tab },
          activeItem: tab,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  toggleValidationModal = () => {
    this.setState({
      validationModal: !this.state.validationModal,
    });
  };

  togglePills = (type, tab) => (e) => {
    e.preventDefault();
    if (this.state.items[type] !== tab) {
      let items = { ...this.state.items };
      items[type] = tab;

      this.setState(
        {
          items,
          activeItem: tab,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  onClickProfitabilityScoreOption = (nr) => () => {
    this.setState({
      profitabilityScoreRadio: nr,
    });
  };

  setRedirectLogin = () => {
    this.setState({
      redirectLogin: true,
    });
  };

  renderRedirectLogin = () => {
    if (this.state.redirectLogin) {
      return <Redirect to="/" />;
    }
  };

  setRedirectQuote = () => {
    this.setState({
      redirectQuote: true,
    });
  };

  renderRedirectQuote = () => {
    if (this.state.redirectQuote) {
      return <Redirect to={`${this.state.quoteId}/edit`} />;
    }
  };

  swapFormActive = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  handleNextPrevClick = (a) => (param) => (e) => {
    this.setState({
      ["formActivePanel" + a]: param,
      ["formActivePanel" + a + "Changed"]: true,
    });
  };

  calculateAutofocus = (a) => {
    if (this.state["formActivePanel" + a + "Changed"]) {
      return true;
    }
  };

  handlePreviousProcessorPricingModelOptions = (
    previousProcessorPricingModelOptionsType
  ) => {
    this.setState({ previousProcessorPricingModelOptionsType });
  };

  handleCitySelectChange = (city) => {
    this.setState({ city: city });
  };

  handleStateSelectChange = (state) => {
    if (this.state.state == undefined) {
      this.setState({ state: state });
    } else {
      this.setState(
        {
          state: state,
        },
        () => {
          if (this.state.state.length > 0 && this.state.state != "invalid") {
            this.setState({ secondStepperStatus: "valid" });
          }
        }
      );
    }
  };

  handleZipSelectChange = (zipcode) => {
    this.setState({ zipcode: zipcode });
  };

  handleMccCategorySelectChange = (mccCategory) => {
    switch (mccCategory) {
      case "Amusement and Entertainment":
        this.setState({
          mccDescriptionOptions: [
            { text: "Sporting and Recreational Camps", value: "7032-1" },
            { text: "Trailer Parks and Campgrounds", value: "7032-2" },
            {
              text: "Motion Picture & Video Tape Production and Distribution",
              value: "7829",
            },
            {
              text: "Recreation Services - Not Elsewhere Classified",
              value: "7999",
            },
            { text: "Motion Picture Theater", value: "7832-1" },
            { text: "DVD/Video Tape Rental Stores", value: "7832-2" },
            { text: "Dance Halls, Studios & Schools", value: "7911" },
            { text: "Bands, Orchestras & Misc Entertainment", value: "7929" },
            { text: "Billiards & Pool Establishments", value: "7941-1" },
            { text: "Bowling Alleys", value: "7941-2" },
            { text: "Commercial Sports", value: "7941-3" },
            { text: "Professional Sports Clubs", value: "7941-4" },
            { text: "Athletic Fields", value: "7941-5" },
            { text: "Sports Promoters", value: "7941-6" },
            { text: "Public Golf Courses", value: "7941-7" },
            {
              text: "Theatrical Producers (except Motion Pictures) and Ticket Agencies",
              value: "7922",
            },
          ],
        });
        break;
      case "Associations":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Charitable and Social Service Organizations",
              value: "8398",
            },
            {
              text: "Civic, Social, and Fraternal Associations",
              value: "8641",
            },
            { text: "Political Organizations", value: "8651" },
            { text: "Religious Organizations", value: "8661" },
            { text: "Automobile Associations", value: "8699" },
          ],
        });
        break;
      case "Business Services":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Miscellaneous Publishing and Printing Services",
              value: "2741-1",
            },
            {
              text: "Typesetting, Plate Making and Related Services",
              value: "2741-2",
            },
            { text: "Advertising Services", value: "7311" },
            {
              text: "Commercial Photography, Art, and Graphics",
              value: "7333-1",
            },
            {
              text: "Photofinishing Laboratories and Photo Developing",
              value: "7333-2",
            },
            { text: "Quick Copy, Reproduction Service", value: "7333-3" },
            {
              text: "Computer Programming, Data Processing, and Integrated Systems Design Services",
              value: "7372-1",
            },
            { text: "Information Retrieval Services", value: "7372-2" },
            { text: "Stenographic Service", value: "7339" },
            {
              text: "Exterminating and Disinfecting Services",
              value: "7349-1",
            },
            {
              text: "Cleaning, Maintenance, and Janitorial Services",
              value: "7349-2",
            },
            {
              text: "Specialty Cleaning, Polishing, and Sanitation Preparations",
              value: "7349-3",
            },
            {
              text: "Management, Consulting, and Public Relations Services",
              value: "7392",
            },
            {
              text: "Detective Agencies, Protective Agencies, and Security Services, including Armored Cars and Guard Dogs",
              value: "7393",
            },
            {
              text: "Business Services - Not Elsewhere Classified",
              value: "7399",
            },
          ],
        });
        break;
      case "Clothing and Accessories":
        this.setState({
          mccDescriptionOptions: [
            { text: "Children's and Infants' Wear Stores", value: "5691-1" },
            { text: "Family Clothing Stores", value: "5691-2" },
            { text: "Furriers & Fur Shops", value: "5691-3" },
            {
              text: "Men's & Boys' Clothing and Accessory Stores",
              value: "5691-4",
            },
            { text: "Men's and Women's Clothing Stores", value: "5691-5" },
            {
              text: "Miscellaneous Apparel and Accessory Stores",
              value: "5691-6",
            },
            { text: "Shoe Stores", value: "5691-7" },
            { text: "Sports and Riding Apparel Stores", value: "5691-8" },
            {
              text: "Tailors, Seamstresses, Mending, and Alterations",
              value: "5691-9",
            },
            { text: "Wig & Toupee Shops", value: "5691-10" },
            {
              text: "Women's Accessory and Specialty Stores",
              value: "5691-11",
            },
            { text: "Women's Ready-to-Wear Stores", value: "5691-12" },
          ],
        });
        break;
      case "Contracted Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Veterinary Services", value: "742" },
            { text: "Agricultural Cooperatives", value: "763" },
            { text: "Landscaping and Horticultural Services", value: "780" },
            { text: "Carpentry", value: "1520-1" },
            { text: "Contractors, Concrete", value: "1520-2" },
            { text: "Electrical Contractors", value: "1520-3" },
            {
              text: "General Contractor/Residential Building",
              value: "1520-4",
            },
            {
              text: "Heating, Plumbing, and Air Conditioning Contractors",
              value: "1520-5",
            },
            {
              text: "Masonry, Stonework, Tile Setting, Plastering, and Insulation Contractors",
              value: "1520-6",
            },
            {
              text: "Roof, Siding, and Sheet Metal Work Contractors",
              value: "1520-7",
            },
            {
              text: "Special Trade Contractor - Not Elsewhere Classified",
              value: "1520-8",
            },
          ],
        });
        break;
      case "Direct Marketing":
        this.setState({
          mccDescriptionOptions: [
            { text: "Catalog Merchant", value: "5964" },
            { text: "Combined Catalog and Retail Merchant", value: "5969-1" },
            { text: "Continuity/Subscription Merchants", value: "5969-2" },
            { text: "Inbound Telemarketing Merchants", value: "5969-3" },
            { text: "Travel Related Arrangement Services", value: "5969-4" },
            { text: "Insurance Services", value: "5969-5" },
            {
              text: "Direct Marketing/Direct Marketers - Not Elsewhere Classified",
              value: "5969-6",
            },
            {
              text: "Direct Selling Establishments/Door to Door Sales",
              value: "5969-7",
            },
            { text: "Mail Order", value: "5969-8" },
            { text: "Outbound Telemarketing Merchant", value: "5969-9" },
          ],
        });
        break;
      case "Eating and Drinking Places":
        this.setState({
          mccDescriptionOptions: [
            { text: "Caterers - Prepare & Delivery", value: "5811-1" },
            { text: "Eating Places and Restaurants", value: "5811-2" },
            {
              text: "Quick Payment Service-Fast Food Restaurants",
              value: "5811-3",
            },
            {
              text: "Drinking Places (Alcoholic Beverages) - Bars, Taverns, Nightclubs, Cocktail Lounges, and Discotheques",
              value: "5813",
            },
          ],
        });
        break;
      case "Education":
        this.setState({
          mccDescriptionOptions: [
            { text: "Business and Secretarial Schools", value: "8299-1" },
            {
              text: "Colleges, Universities, Professional Schools, and Junior Colleges",
              value: "8299-2",
            },
            { text: "Correspondence Schools", value: "8299-3" },
            { text: "Elementary and Secondary Schools", value: "8299-4" },
            {
              text: "Schools and Educational Services - Not Elsewhere Classified",
              value: "8299-5",
            },
            { text: "Trade and Vocational Schools", value: "8299-6" },
          ],
        });
        break;
      case "Government Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Tax Payments", value: "9311" },
            {
              text: "Court Costs, including Alimony and Child Support",
              value: "9399-1",
            },
            { text: "Bail & Bond Payments", value: "9399-2" },
            {
              text: "Government Services - Not Elsewhere Classified",
              value: "9399-3",
            },
            { text: "U.S. Federal Government Agencies", value: "9399-4" },
          ],
        });
        break;
      case "Home and Garden":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Furniture, Home Furnishings, and Equipment Stores, except Appliances",
              value: "5712",
            },
            {
              text: "Drapery, Window Covering, and Upholstery Stores",
              value: "5719-1",
            },
            {
              text: "Fireplaces, Fireplace Screens, and Accessories Stores",
              value: "5719-2",
            },
            { text: "Floor Coverings and Rugs", value: "5719-3" },
            {
              text: "Miscellaneous Home Furnishing Specialty Stores",
              value: "5719-4",
            },
            { text: "Household Appliance Stores", value: "5722" },
          ],
        });
        break;
      case "Miscellaneous Stores":
        this.setState({
          mccDescriptionOptions: [
            { text: "Electronics Stores", value: "5732" },
            {
              text: "Music Stores - Musical Instruments, Pianos, and Sheet Music",
              value: "5733",
            },
            { text: "Record Stores", value: "5735" },
            {
              text: "Sewing, Needlework, Fabric, and Piece Good Stores",
              value: "5970-1",
            },
            { text: "Artist Supply and Craft Stores", value: "5970-2" },
            { text: "Bicycle Shop-Sales and Services", value: "5941-1" },
            { text: "Sporting Goods Stores", value: "5941-2" },
            {
              text: "Stationery, Office, and School Supply Stores",
              value: "5943",
            },
            {
              text: "Jewelry, Watch, Clock, and Silverware Stores",
              value: "5944",
            },
            { text: "Hobby, Toy, and Game Stores", value: "5945" },
            { text: "Cosmetic Stores", value: "5977-1" },
            { text: "Electric Razor Stores Sales & Services", value: "5977-2" },
            { text: "Book Stores", value: "5942" },
            { text: "Camera and Photographic Supply Stores", value: "5999-1" },
            { text: "Florists", value: "5999-2" },
            {
              text: "Gift, Card, Novelty, and Souvenir Stores",
              value: "5999-3",
            },
            { text: "Glassware and Crystal Stores", value: "5999-4" },
            {
              text: "Hearing Aids - Sales, Service, and Supplies",
              value: "5999-5",
            },
            { text: "Luggage and Leather Goods Stores", value: "5999-6" },
            {
              text: "Miscellaneous & Specialty Retail Stores",
              value: "5999-7",
            },
            { text: "News Dealers & Newsstands", value: "5999-8" },
            {
              text: "Orthopedic Goods and Prosthetic Devices",
              value: "5999-9",
            },
            { text: "Pet Shops, Pet Food, and Supplies", value: "5999-10" },
            { text: "Religious Goods Stores", value: "5999-11" },
            {
              text: "Swimming Pools - Sales, Supplies, and Services",
              value: "5999-12",
            },
            { text: "Tent and Awning Stores", value: "5999-13" },
            {
              text: "Typewriter Stores - Sales, Service, and Rentals",
              value: "5999-14",
            },
            { text: "Wrecking and Salvage Yards", value: "5999-15" },
          ],
        });
        break;
      case "Personal Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Buying and Shopping Services and Clubs", value: "7299-1" },
            {
              text: "Counseling Services - Debt, Marriage, and Personal",
              value: "7299-2",
            },
            {
              text: "Miscellaneous Personal Services - Not Elsewhere Classified",
              value: "7299-3",
            },
          ],
        });
        break;
      case "Professional Services":
        this.setState({
          mccDescriptionOptions: [
            { text: "Chiropractors", value: "8041" },
            { text: "Dentists and Orthodontists", value: "8021" },
            {
              text: "Doctors and Physicians - Not Elsewhere Classified",
              value: "8011",
            },
            { text: "Hospitals", value: "8099-1" },
            { text: "Medical and Dental Laboratories", value: "8099-2" },
            {
              text: "Medical Services Health Practitioners - Not Elsewhere Classified",
              value: "8099-3",
            },
            { text: "Nursing and Personal Care Facilities", value: "8099-4" },
            {
              text: "Opticians, Optical Goods and Eyeglasses",
              value: "8099-5",
            },
            { text: "Optometrists and Ophthalmologists", value: "8099-6" },
            { text: "Osteopathic Physicians", value: "8099-7" },
            { text: "Podiatrists and Chiropodists", value: "8099-8" },
            { text: "Legal Services and Attorneys", value: "8111" },
            { text: "Child Care Services", value: "8351" },
            {
              text: "Accounting, Auditing, and Bookkeeping Services",
              value: "8931",
            },
            {
              text: "Architectural, Engineering, and Surveying Services",
              value: "8911",
            },
            {
              text: "Professional Services - Not Elsewhere Classified",
              value: "8999-1",
            },
            { text: "Testing Laboratories (Not Medical)", value: "8999-2" },
          ],
        });
        break;
      case "Repair":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Furniture - Reupholster, Repair, and Refinishing",
              value: "7641",
            },
            {
              text: "Air Conditioning and Refrigeration Repair Shops",
              value: "7699-1",
            },
            {
              text: "Electrical and Small Appliance Repair Shops",
              value: "7699-2",
            },
            { text: "Electronic Repair Shops", value: "7699-3" },
            {
              text: "Miscellaneous Repair Shops and Related Services",
              value: "7699-4",
            },
            { text: "Watch, Clock, and Jewelry Repair Shops", value: "7699-5" },
            { text: "Welding Services", value: "7699-6" },
          ],
        });
        break;
      case "Retail Store":
        this.setState({
          mccDescriptionOptions: [
            { text: "Candy, Nut, and Confectionery Stores", value: "5499-1" },
            { text: "Dairy Product Stores", value: "5499-2" },
            { text: "Freezer & Locker Meat Provisions", value: "5499-3" },
            {
              text: "Miscellaneous Food Stores-Convenience Stores and Specialty Markets",
              value: "5499-4",
            },
          ],
        });
        break;
      case "Telecommunications":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Telecommunication Equipment and Telephone Sales",
              value: "4812",
            },
          ],
        });
        break;
      case "Transportation":
        this.setState({
          mccDescriptionOptions: [
            { text: "Taxicabs and Limousines", value: "4121" },
            {
              text: "Motor Freight Carriers and Trucking-Local and Long Distance, Moving & Storage Companies, and Local Delivery",
              value: "4214",
            },
          ],
        });
        break;
      case "Utilities":
        this.setState({
          mccDescriptionOptions: [
            { text: "Electric, Gas, Water, and Sanitary", value: "4900" },
          ],
        });
        break;
      case "Wholesale":
        this.setState({
          mccDescriptionOptions: [
            {
              text: "Computers, Computer Peripheral Equipment, and Software",
              value: "5045",
            },
            {
              text: "Dental/Laboratory/Medical/Ophthalmic Hospital Equipment and Supplies",
              value: "5047",
            },
            {
              text: "Precious Stones, Metals, Watches and Jewelry",
              value: "5094",
            },
            { text: "Durable goods - Not Elsewhere Classified", value: "5099" },
            {
              text: "Books, Periodicals, and Newspapers Distributors",
              value: "5192",
            },
            {
              text: "Florist Suppliers, Nursery Stock & Flower",
              value: "5193",
            },
          ],
        });
        break;
      default:
      // code block
    }

    this.setState({ mccCategory: mccCategory });
  };

  handleQuoteStatusChange = (status) => {
    //Set the status of the quote in the this.state.

    this.setState({ quoteStatus: status });
  };

  handlePricingSelectChange = (pricing) => {
    this.setState({ selectedQuotePricing: pricing });
  };

  handleMccCodeSelectChange = (mccCode) => {
    if (mccCode.length > 0) {
      let code = mccCode[0].substring(0, 4);
      this.setState({ mccCode: code });
    }
  };

  handleMCCDescriptionSelectChange = (mccCategoryDescription) => {
    this.setState(
      {
        mccCategoryDescription,
      },
      () => {
        if (
          (this.state.dba != "invalid" || this.state.dba.length > 0) &&
          this.state.mccCategory != "invalid" &&
          this.state.mccCategoryDescription != "First Select MCC Category"
        ) {
          this.setState({ firstStepperStatus: "valid" });
        }
      }
    );
  };

  handleMerchantTypeSelectChange = (merchantType) => {
    this.setState({ merchantType });
  };

  handleMerchantCheckboxChange = () => {
    this.setState({
      merchantServices: !this.state.merchantServices,
    });
    // this.refs[e.target].checked = !this.refs[e.target].checked;
  };

  handleSubmitApprovalResponse = () => {
    let quoteApprovalNotes = this.state.quoteApprovalNotes;
    let quoteStatus = this.state.quoteStatus;

    //Submit response to backend API to save to Database
    let json = {
      quoteApprovalNotes: quoteApprovalNotes,
      quoteStatus: quoteStatus,
    };

    UpdateQuoteStatusCommentById(this.state.quoteId, json).then((response) => {
      console.log(response);
      if (response.status !== 200) {
        alert("There was an error updating the status of this quote");
      } else {
        alert("Quote status has been updated");
      }
    });

    // console.log(quoteApprovalNotes + quoteStatus);
  };

  handleInterchangePassThruCheckboxChange = () => {
    if (this.state.interchangePassThru == true) {
      this.setState(
        {
          interchangePassThru: !this.state.interchangePassThru,
          //activeItem: "1",
          //items: { gradient: "1" },
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          interchangePassThru: !this.state.interchangePassThru,
          activeItem: "1",
          items: { gradient: "1" },
        },
        () => {
          this.forceUpdate();
        }
      );
    }

    // this.setState(
    //   {
    //     interchangePassThru: !this.state.interchangePassThru,
    //     activeItem: "1",
    //     items: { gradient: "1" },
    //   },
    //   () => {
    //     this.forceUpdate();
    //   }
    // );
  };

  handleFlatRateCheckboxChange = () => {
    if (this.state.flatRate == true) {
      this.setState(
        {
          flatRate: !this.state.flatRate,
          //activeItem: "1",
          //items: { gradient: "1" },
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          flatRate: !this.state.flatRate,
          activeItem: "2",
          items: { gradient: "2" },
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  handleFlatRatePlusDowngradeCheckboxChange = () => {
    if (this.state.flatRatePlusDowngrade == true) {
      this.setState(
        {
          flatRatePlusDowngrade: !this.state.flatRatePlusDowngrade,
          //activeItem: "1",
          //items: { gradient: "1" },
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          flatRatePlusDowngrade: !this.state.flatRatePlusDowngrade,
          activeItem: "3",
          items: { gradient: "3" },
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  handleTierPricingCheckboxChange = () => {
    if (this.state.tierPricing == true) {
      this.setState(
        {
          tierPricing: !this.state.tierPricing,
          //activeItem: "1",
          //items: { gradient: "1" },
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      this.setState(
        {
          tierPricing: !this.state.tierPricing,
          activeItem: "4",
          items: { gradient: "4" },
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  };

  handlePreviousProcessorCheckboxChange = () => {
    this.setState({
      previousProcessor: !this.state.previousProcessor,
    });
    // this.refs[e.target].checked = !this.refs[e.target].checked;
  };

  changeHandler = (event) => {
    this.setState(
      {
        [event.target.name]: event.target.value,
      },
      () => {
        this.setState(
          { cardNotPresentPercentage: 100 - this.state.cardPresentPercentage },
          () => {
            if (
              (this.state.dba != "invalid" || this.state.dba.length > 0) &&
              this.state.mccCategory != "invalid" &&
              this.state.mccCategoryDescription != "invalid"
            ) {
              this.setState({
                firstStepperStatus: "valid",
              });
            }

            if (this.state.estimatedSalesVolume != undefined) {
              if (
                this.state.estimatedSalesVolume.length > 0 &&
                this.state.estimatedAverageTicket > 0
              ) {
                this.setState({ thirdStepperStatus: "valid" });
              }
            }
          }
        );
      }
    );
  };

  changeHandlerQuote = (event) => {
    console.log(event);
    this.setState({ [event.target.name]: event.target.value });
  };

  //Updat Quote to Database
  callUpdateInitialQuoteApi = () => {
    let previousProcessor = this.state.previousProcessor;

    //Check if previous processor information was left blank to set quote to false on previous processor information
    if (
      parseFloat(this.state.previousProcessorSalesVolume) +
        parseFloat(this.state.previousProcessorDiscountRate) +
        parseFloat(this.state.previousProcessorTransactionFees) +
        parseFloat(this.state.previousProcessorOtherFees) +
        parseFloat(this.state.previousProcessorTotalBillAmount) ===
      0
    ) {
      previousProcessor = false;
    }

    const json = {
      dba: this.state.dba,
      mccCode: this.state.mccCode[0],
      contactName: this.state.contactName,
      phoneNumber: this.state.phoneNumber,
      mccCategory: this.state.mccCategory,
      mccCategoryDescription: this.state.mccCategoryDescription,
      physicalLocation: this.state.physicalLocation,
      state: this.state.state,
      city: this.state.city,
      zipcode: this.state.zipcode,
      quoteStatus: this.state.quoteStatus,
      quoteApprovalNotes: this.state.quoteApprovalNotes,
      quoteGenerated: false,
      estimatedSalesVolume: this.state.estimatedSalesVolume,
      estimatedAverageTicket: this.state.estimatedAverageTicket,
      cardPresentPercentage: this.state.cardPresentPercentage,
      cardNotPresentPercentage: this.state.cardNotPresentPercentage,
      previousProcessor: previousProcessor,
      previousProcessorSalesVolume: this.state.previousProcessorSalesVolume
        ? this.state.previousProcessorSalesVolume
        : 0,
      previousProcessorDiscountRate: this.state.previousProcessorDiscountRate
        ? this.state.previousProcessorDiscountRate
        : 0,
      previousProcessorTransactionFees: this.state
        .previousProcessorTransactionFees
        ? this.state.previousProcessorTransactionFees
        : 0,
      previousProcessorOtherFees: this.state.previousProcessorOtherFees
        ? this.state.previousProcessorOtherFees
        : 0,
      previousProcessorTotalBillAmount: this.state
        .previousProcessorTotalBillAmount
        ? this.state.previousProcessorTotalBillAmount
        : 0,
      previousProcessorPricingModelOptionsType: this.state
        .previousProcessorPricingModelOptionsType
        ? this.state.previousProcessorPricingModelOptionsType
        : 0,
    };

    UpdateInitialQuoteInformation(this.state.quoteId, json).then((response) => {
      if (response.statusText === "OK") {
        this.setState(
          {
            showMerchantInformation: false,
            quoteGenerated: response.data.quoteGenerated,
          },
          () => {
            window.scrollTo(0, 0);
          }
        );
      } else if (response.status !== 200) {
        response.json().then((json) => {
          alert("An error has occurred, please contact technical support.");
        });
        // throw Error(response.message);
      }
    });
  };

  callUpdateQuotePricingInformationApi = (pricingSummaryObject) => {
    console.log(this.state.quoteGenerated);
    const json = JSON.stringify({
      interchangeAuthorizationFee: this.state.interchangeAuthorizationFee,
      interchangeChargebackFees: this.state.interchangeChargebackFees,
      interchangeDiscount: this.state.interchangeDiscount,
      interchangeMonthlyEquipmentFees:
        this.state.interchangeMonthlyEquipmentFees,
      interchangeMonthlyGatewayFees: this.state.interchangeMonthlyGatewayFees,
      interchangeMonthlyPciRegulatoryFees:
        this.state.interchangeMonthlyPciRegulatoryFees,
      interchangeMonthlyServiceFee: this.state.interchangeMonthlyServiceFee,
      interchangeNumberOfTransactions:
        this.state.interchangeNumberOfTransactions,
      interchangeOtherMonthlyFees: this.state.interchangeOtherMonthlyFees,
      interchangeOtherTransactionFees:
        this.state.interchangeOtherTransactionFees,

      flatRateAuthorizationFee: this.state.flatRateAuthorizationFee,
      flatRateChargebackFees: this.state.flatRateChargebackFees,
      flatRateDiscount: this.state.flatRateDiscount,
      flatRateMonthlyEquipmentFees: this.state.flatRateMonthlyEquipmentFees,
      flatRateMonthlyGatewayFees: this.state.flatRateMonthlyGatewayFees,
      flatRateMonthlyPciRegulatoryFees:
        this.state.flatRateMonthlyPciRegulatoryFees,
      flatRateMonthlyServiceFee: this.state.flatRateMonthlyServiceFee,
      flatRateNumberOfTransactions: this.state.flatRateNumberOfTransactions,
      flatRateOtherMonthlyFees: this.state.flatRateOtherMonthlyFees,
      flatRateOtherTransactionFees: this.state.flatRateOtherTransactionFees,
      flatRateDowngradeAuthorizationFeeDowngrade:
        this.state.flatRateDowngradeAuthorizationFeeDowngrade,
      flatRateDowngradeAuthorizationFeeFlatRate:
        this.state.flatRateDowngradeAuthorizationFeeFlatRate,
      flatRateDowngradeChargebackFees:
        this.state.flatRateDowngradeChargebackFees,
      flatRateDowngradeDowngrade: this.state.flatRateDowngradeDowngrade,
      flatRateDowngradeDowngradePercent:
        this.state.flatRateDowngradeDowngradePercent,
      flatRateDowngradeEstimateNumberOfTransactions:
        this.state.flatRateDowngradeEstimateNumberOfTransactions,
      flatRateDowngradeFlatRate: this.state.flatRateDowngradeFlatRate,
      flatRateDowngradeFlatRatePercent:
        this.state.flatRateDowngradeFlatRatePercent,
      flatRateDowngradeMonthlyEquipmentFees:
        this.state.flatRateDowngradeMonthlyEquipmentFees,
      flatRateDowngradeMonthlyGatewayFees:
        this.state.flatRateDowngradeMonthlyGatewayFees,
      flatRateDowngradeMonthlyPciRegulatoryFees:
        this.state.flatRateDowngradeMonthlyPciRegulatoryFees,
      flatRateDowngradeMonthlyServiceFee:
        this.state.flatRateDowngradeMonthlyServiceFee,
      flatRateDowngradeOtherMonthlyFees:
        this.state.flatRateDowngradeOtherMonthlyFees,
      flatRateDowngradeOtherTransactionFee:
        this.state.flatRateDowngradeOtherTransactionFee,
      tierPricingAuthorizationFee: this.state.tierPricingAuthorizationFee,
      tierPricingChargebackFees: this.state.tierPricingChargebackFees,
      tierPricingEstimateNumberOfTransactions:
        this.state.tierPricingEstimateNumberOfTransactions,
      tierPricingMidQualified: this.state.tierPricingMidQualified,
      tierPricingMidQualifiedPercent: this.state.tierPricingMidQualifiedPercent,
      tierPricingMonthlyEquipmentFees:
        this.state.tierPricingMonthlyEquipmentFees,
      tierPricingMonthlyGatewayFees: this.state.tierPricingMonthlyGatewayFees,
      tierPricingMonthlyPciRegulatoryFees:
        this.state.tierPricingMonthlyPciRegulatoryFees,
      tierPricingMonthlyServiceFee: this.state.tierPricingMonthlyServiceFee,
      tierPricingNonQualified: this.state.tierPricingNonQualified,
      tierPricingNonQualifiedPercent: this.state.tierPricingNonQualifiedPercent,
      tierPricingOtherMonthlyFees: this.state.tierPricingOtherMonthlyFees,
      tierPricingOtherTransactionFees:
        this.state.tierPricingOtherTransactionFees,
      tierPricingQualified: this.state.tierPricingQualified,
      tierPricingQualifiedPercent: this.state.tierPricingQualifiedPercent,
      interchangePassThru: this.state.interchangePassThru,
      flatRate: this.state.flatRate,
      flatRatePlusDowngrade: this.state.flatRatePlusDowngrade,
      tierPricingOption: this.state.tierPricing,
      merchantServices: this.state.merchantServices,
      quoteGenerated: this.state.quoteGenerated,
      quoteTotals: pricingSummaryObject,
      quotePricingModel: this.state.selectedQuotePricing,
    });
    UpdateQuotePricingInformation(this.state.quoteId, json).then((response) => {
      console.log("RESPONSE RESPONSE", response);
      if (response.statusText === "OK") {
        this.setState(
          {
            quoteGenerated: json.quoteGenerated,
            finalizingQuote: false,
          },
          () => {
            // const token = localStorage.getItem("jwtToken");
            // retrieve quote again
            this.retrieveQuoteById();
            window.scrollTo(0, 0);
          }
        );
      } else if (response.status !== 200) {
        response.json().then((json) => {
          alert("An error has occurred, please contact technical support.");
        });
        // throw Error(response.message);
      }
    });
  };

  showMerchantInformation = () => {
    this.setState({ showMerchantInformation: true });
  };

  submitPricingHandler = (event) => {
    if (event.nativeEvent.submitter.id == "draft") {
      event.preventDefault();
      event.target.className += " was-validated";

      let submitForm = false;
      let missingSections = [];

      //Validate Interchange Passthru
      if (this.state.interchangePassThru === true) {
        if (
          this.state.interchangeDiscount !== 0 &&
          this.state.interchangeDiscount !== "" &&
          this.state.interchangeAuthorizationFee !== 0 &&
          this.state.interchangeAuthorizationFee !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Interchange Pass-Thru");
        }
      }

      //Validate Flat Rate
      if (this.state.flatRate === true) {
        if (
          this.state.flatRateDiscount !== 0 &&
          this.state.flatRateDiscount !== "" &&
          this.state.flatRateAuthorizationFee !== 0 &&
          this.state.flatRateAuthorizationFee !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Flat Rate");
          submitForm = false;
        }
      }

      //Validate Flat Rate Plus Downgrade
      if (this.state.flatRatePlusDowngrade === true) {
        if (
          this.state.flatRateDowngradeAuthorizationFeeDowngrade !== 0 &&
          this.state.flatRateDowngradeAuthorizationFeeDowngrade !== "" &&
          this.state.flatRateDowngradeAuthorizationFeeFlatRate !== 0 &&
          this.state.flatRateDowngradeAuthorizationFeeFlatRate !== "" &&
          this.state.flatRateDowngradeDowngrade !== 0 &&
          this.state.flatRateDowngradeDowngrade !== "" &&
          this.state.flatRateDowngradeDowngradePercent !== 0 &&
          this.state.flatRateDowngradeDowngradePercent !== "" &&
          this.state.flatRateDowngradeFlatRate !== 0 &&
          this.state.flatRateDowngradeFlatRate !== "" &&
          this.state.flatRateDowngradeFlatRatePercent !== 0 &&
          this.state.flatRateDowngradeFlatRatePercent !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Flat Rate Plus Downgrade");
          submitForm = false;
        }
      }

      //Validate Tier Pricing
      if (this.state.tierPricing === true) {
        if (
          this.state.tierPricing !== "" &&
          this.state.tierPricingAuthorizationFee !== 0 &&
          this.state.tierPricingAuthorizationFee !== "" &&
          this.state.tierPricingMidQualified !== 0 &&
          this.state.tierPricingMidQualified !== "" &&
          this.state.tierPricingMidQualifiedPercent !== 0 &&
          this.state.tierPricingMidQualifiedPercent !== "" &&
          this.state.tierPricingNonQualified !== 0 &&
          this.state.tierPricingNonQualified !== "" &&
          this.state.tierPricingNonQualifiedPercent !== 0 &&
          this.state.tierPricingNonQualifiedPercent !== "" &&
          this.state.tierPricingQualified !== 0 &&
          this.state.tierPricingQualified !== "" &&
          this.state.tierPricingQualifiedPercent !== 0 &&
          this.state.tierPricingQualifiedPercent !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Tier Pricing");
          submitForm = false;
        }
      }

      // if (this.state.selectedQuotePricing === "") {
      //   submitForm = false;
      //   missingSections.push("Please Select Final Quote for Submission");
      // } else {
      //   submitForm = true;
      // }

      if (submitForm === true) {
        //Call API and Update Pricing Information

        //Call the API to Save the Information
        this.callUpdateQuotePricingInformationApi();
      } else {
        this.setState(
          {
            validationSections: missingSections,
          },
          () => {
            this.toggleValidationModal();
          }
        );
      }
    } else if (event.nativeEvent.submitter.id == "generate") {
      event.preventDefault();
      event.target.className += " was-validated";

      //Capture generated pricing data from totals table and stored in object
      let interchangeEffectiveRate = document
        .getElementById("interchangeEffectiveRate")
        .innerHTML.replace("%", "")
        .trim();

      let interchangeGrossMargin = document
        .getElementById("interchangeGrossMargin")
        .innerHTML.replace("%", "")
        .trim();

      let interchangeMerchantTotalFees = document
        .getElementById("interchangeMerchantTotalFees")
        .innerHTML.replace("$", "")
        .trim();

      let interchangeEstimatedProfit = document
        .getElementById("interchangeEstimatedProfit")
        .innerHTML.replace("$", "")
        .trim();

      let flatRateEffectiveRate = document
        .getElementById("flatRateEffectiveRate")
        .innerHTML.replace("%", "")
        .trim();

      let flatRateGrossMargin = document
        .getElementById("flatRateGrossMargin")
        .innerHTML.replace("%", "")
        .trim();

      let flatRateMerchantTotalFees = document
        .getElementById("flatRateMerchantTotalFees")
        .innerHTML.replace("$", "")
        .trim();

      let flatRateEstimatedProfit = document
        .getElementById("flatRateEstimatedProfit")
        .innerHTML.replace("$", "")
        .trim();

      let flatRateDowngradeEffectiveRate = document
        .getElementById("flatRateDowngradeEffectiveRate")
        .innerHTML.replace("%", "")
        .trim();

      let flatRateDowngradeGrossMargin = document
        .getElementById("flatRateDowngradeGrossMargin")
        .innerHTML.replace("%", "")
        .trim();

      let flatRateDowngradeMerchantTotalFees = document
        .getElementById("flatRateDowngradeMerchantTotalFees")
        .innerHTML.replace("$", "")
        .trim();

      let flatRateDowngradeEstimatedProfit = document
        .getElementById("flatRateDowngradeEstimatedProfit")
        .innerHTML.replace("$", "")
        .trim();

      let tierPricingEffectiveRate = document
        .getElementById("tierPricingEffectiveRate")
        .innerHTML.replace("%", "")
        .trim();

      let tierPricingGrossMargin = document
        .getElementById("tierPricingGrossMargin")
        .innerHTML.replace("%", "")
        .trim();

      let tierPricingMerchantTotalFees = document
        .getElementById("tierPricingMerchantTotalFees")
        .innerHTML.replace("$", "")
        .trim();

      let tierPricingEstimatedProfit = document
        .getElementById("tierPricingEstimatedProfit")
        .innerHTML.replace("$", "")
        .trim();

      //Create Pricing Summary Object
      let pricingSummaryObject = {
        interchange: {
          effectiveRate: interchangeEffectiveRate,
          grossMargin: interchangeGrossMargin,
          merchantTotalFees: interchangeMerchantTotalFees,
          estimatedProfit: interchangeEstimatedProfit,
        },
        flatRate: {
          effectiveRate: flatRateEffectiveRate,
          grossMargin: flatRateGrossMargin,
          merchantTotalFees: flatRateMerchantTotalFees,
          estimatedProfit: flatRateEstimatedProfit,
        },
        flatRateDowngrade: {
          effectiveRate: flatRateDowngradeEffectiveRate,
          grossMargin: flatRateDowngradeGrossMargin,
          merchantTotalFees: flatRateDowngradeMerchantTotalFees,
          estimatedProfit: flatRateDowngradeEstimatedProfit,
        },
        tierPricing: {
          effectiveRate: tierPricingEffectiveRate,
          grossMargin: tierPricingGrossMargin,
          merchantTotalFees: tierPricingMerchantTotalFees,
          estimatedProfit: tierPricingEstimatedProfit,
        },
      };

      console.log(pricingSummaryObject);

      let submitForm = false;
      let missingSections = [];

      //Validate Interchange Passthru
      if (this.state.interchangePassThru === true) {
        if (
          this.state.interchangeDiscount !== 0 &&
          this.state.interchangeDiscount !== "" &&
          this.state.interchangeAuthorizationFee !== 0 &&
          this.state.interchangeAuthorizationFee !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Interchange Pass-Thru");
        }
      }

      //Validate Flat Rate
      if (this.state.flatRate === true) {
        if (
          this.state.flatRateDiscount !== 0 &&
          this.state.flatRateDiscount !== "" &&
          this.state.flatRateAuthorizationFee !== 0 &&
          this.state.flatRateAuthorizationFee !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Flat Rate");
          submitForm = false;
        }
      }

      //Validate Flat Rate Plus Downgrade
      if (this.state.flatRatePlusDowngrade === true) {
        if (
          this.state.flatRateDowngradeAuthorizationFeeDowngrade !== 0 &&
          this.state.flatRateDowngradeAuthorizationFeeDowngrade !== "" &&
          this.state.flatRateDowngradeAuthorizationFeeFlatRate !== 0 &&
          this.state.flatRateDowngradeAuthorizationFeeFlatRate !== "" &&
          this.state.flatRateDowngradeDowngrade !== 0 &&
          this.state.flatRateDowngradeDowngrade !== "" &&
          this.state.flatRateDowngradeDowngradePercent !== 0 &&
          this.state.flatRateDowngradeDowngradePercent !== "" &&
          this.state.flatRateDowngradeFlatRate !== 0 &&
          this.state.flatRateDowngradeFlatRate !== "" &&
          this.state.flatRateDowngradeFlatRatePercent !== 0 &&
          this.state.flatRateDowngradeFlatRatePercent !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Flat Rate Plus Downgrade");
          submitForm = false;
        }
      }

      //Validate Tier Pricing
      if (this.state.tierPricing === true) {
        if (
          this.state.tierPricing !== "" &&
          this.state.tierPricingAuthorizationFee !== 0 &&
          this.state.tierPricingAuthorizationFee !== "" &&
          this.state.tierPricingMidQualified !== 0 &&
          this.state.tierPricingMidQualified !== "" &&
          this.state.tierPricingMidQualifiedPercent !== 0 &&
          this.state.tierPricingMidQualifiedPercent !== "" &&
          this.state.tierPricingNonQualified !== 0 &&
          this.state.tierPricingNonQualified !== "" &&
          this.state.tierPricingNonQualifiedPercent !== 0 &&
          this.state.tierPricingNonQualifiedPercent !== "" &&
          this.state.tierPricingQualified !== 0 &&
          this.state.tierPricingQualified !== "" &&
          this.state.tierPricingQualifiedPercent !== 0 &&
          this.state.tierPricingQualifiedPercent !== ""
        ) {
          submitForm = true;
        } else {
          missingSections.push("Tier Pricing");
          submitForm = false;
        }
      }

      if (this.state.selectedQuotePricing === "") {
        submitForm = false;
        missingSections.push("Please Select Final Quote for Submission");
      } else {
        submitForm = true;
      }

      if (submitForm === true) {
        console.log(pricingSummaryObject);
        //Call API and Update Pricing Information
        this.setState({ finalizingQuote: true, quoteGenerated: true }, () => {
          this.callUpdateQuotePricingInformationApi(pricingSummaryObject);
        });
        //Call the API to Save the Information
      } else {
        this.setState(
          {
            validationSections: missingSections,
          },
          () => {
            this.toggleValidationModal();
          }
        );
      }
    }
  };

  submitHandler = (event) => {
    event.preventDefault();
    event.target.className += " was-validated";

    let firstStepperStatus = "";
    let secondStepperStatus = "";
    let thirdStepperStatus = "";

    let state = "";

    let mccCategory = "";
    let mccCategoryDescription = "";

    //First Stepper Validation
    if (
      this.state.dba == undefined ||
      this.state.dba == "" ||
      this.state.mccCode == 0
    ) {
      firstStepperStatus = "invalid";
    } else {
      firstStepperStatus = "valid";
    }

    if (this.state.mccCategory == undefined || this.state.mccCategory == "") {
      mccCategory = "invalid";
    } else {
      mccCategory = this.state.mccCategory;
    }

    if (
      this.state.mccCategoryDescription == undefined ||
      this.state.mccCategoryDescription == ""
    ) {
      mccCategoryDescription = "invalid";
    } else {
      mccCategoryDescription = this.state.mccCategoryDescription;
    }

    //Second Stepper Validation
    if (
      this.state.state == undefined ||
      this.state.state == "" ||
      this.state.state == "invalid"
    ) {
      secondStepperStatus = "invalid";
      state = "invalid";
    } else {
      secondStepperStatus = "valid";
      state = this.state.state;
    }

    //Third Stepper Validation

    if (
      this.state.estimatedAverageTicket == undefined ||
      this.state.estimatedAverageTicket == ""
    ) {
      thirdStepperStatus = "invalid";
    } else {
      thirdStepperStatus = "valid";
    }

    this.setState(
      {
        firstStepperStatus: firstStepperStatus,
        secondStepperStatus: secondStepperStatus,
        thirdStepperStatus: thirdStepperStatus,
        state: state,
        mccCategory: mccCategory,
        mccCategoryDescription: mccCategoryDescription,
      },
      () => {
        if (
          this.state.firstStepperStatus == "valid" &&
          this.state.secondStepperStatus == "valid" &&
          this.state.thirdStepperStatus == "valid"
        ) {
          this.callUpdateInitialQuoteApi();
        }
      }
    );
  };

  render() {
    let pricingModelPricingTable = "";

    if (
      this.state.interchangePassThru == true ||
      this.state.flatRate == true ||
      this.state.flatRatePlusDowngrade == true ||
      this.state.tierPricing == true
    ) {
      pricingModelPricingTable = (
        <MDBRow>
          <MDBContainer>
            <MDBRow className="text-center">
              <MDBCol middle>
                <h3 className="pt-5 ">Pricing Table(s)</h3>
                <MDBStickyContent
                  style={{ background: "#fff", height: "1500px" }}
                >
                  <MDBSticky>
                    {({ style }) => {
                      return (
                        <div
                          style={{
                            ...style,
                          }}
                        >
                          <p className="pt-1 pb-5">
                            Please enter the pricing information for the
                            sections below.
                          </p>
                          <MDBNav
                            pills
                            className="section-preview nav-justified pills-blue-gradient"
                          >
                            {this.state.interchangePassThru == true ? (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  active={this.state.items["gradient"] === "1"}
                                  onClick={this.togglePills("gradient", "1")}
                                >
                                  Interchange Pass-thru
                                </MDBNavLink>
                              </MDBNavItem>
                            ) : (
                              ""
                            )}

                            {this.state.flatRate == true ? (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  active={this.state.items["gradient"] === "2"}
                                  onClick={this.togglePills("gradient", "2")}
                                >
                                  Flat-Rate
                                </MDBNavLink>
                              </MDBNavItem>
                            ) : (
                              ""
                            )}

                            {this.state.flatRatePlusDowngrade == true ? (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  active={this.state.items["gradient"] === "3"}
                                  onClick={this.togglePills("gradient", "3")}
                                >
                                  Flat Fate + Downgrade
                                </MDBNavLink>
                              </MDBNavItem>
                            ) : (
                              ""
                            )}

                            {this.state.tierPricing == true ? (
                              <MDBNavItem>
                                <MDBNavLink
                                  link
                                  to="#"
                                  active={this.state.items["gradient"] === "4"}
                                  onClick={this.togglePills("gradient", "4")}
                                >
                                  Tier Pricing
                                </MDBNavLink>
                              </MDBNavItem>
                            ) : (
                              ""
                            )}
                          </MDBNav>
                          <form
                            className="needs-validation"
                            onSubmit={this.submitPricingHandler}
                            noValidate
                          >
                            <MDBTabContent
                              className="card mt-2"
                              activeItem={this.state.activeItem}
                            >
                              <MDBTabPane tabId="1" role="tabpanel">
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeDiscount"
                                      required={this.state.interchangePassThru}
                                      value={this.state.interchangeDiscount}
                                      onChange={this.changeHandlerQuote}
                                      label="Discount (basis points)"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeAuthorizationFee"
                                      required={this.state.interchangePassThru}
                                      value={
                                        this.state.interchangeAuthorizationFee
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Authorization Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeOtherTransactionFees"
                                      value={
                                        this.state
                                          .interchangeOtherTransactionFees
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Transaction Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeNumberOfTransactions"
                                      value={
                                        this.state
                                          .interchangeNumberOfTransactions > 0
                                          ? this.state
                                              .interchangeNumberOfTransactions
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Number of Trasactions (Estimated)"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <p className="pt-5 text-center">
                                  <b>Other Fees:</b>
                                </p>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeMonthlyServiceFee"
                                      value={
                                        this.state
                                          .interchangeMonthlyServiceFee > 0
                                          ? this.state
                                              .interchangeMonthlyServiceFee
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Statement/Service Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeMonthlyGatewayFees"
                                      value={
                                        this.state
                                          .interchangeMonthlyGatewayFees > 0
                                          ? this.state
                                              .interchangeMonthlyGatewayFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Gateway Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeChargebackFees"
                                      value={
                                        this.state.interchangeChargebackFees > 0
                                          ? this.state.interchangeChargebackFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Chargeback Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeMonthlyPciRegulatoryFees"
                                      value={
                                        this.state
                                          .interchangeMonthlyPciRegulatoryFees >
                                        0
                                          ? this.state
                                              .interchangeMonthlyPciRegulatoryFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly PCI/Regulatory Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeMonthlyEquipmentFees"
                                      value={
                                        this.state
                                          .interchangeMonthlyEquipmentFees > 0
                                          ? this.state
                                              .interchangeMonthlyEquipmentFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Equipment Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="interchangeOtherMonthlyFees"
                                      value={
                                        this.state.interchangeOtherMonthlyFees >
                                        0
                                          ? this.state
                                              .interchangeOtherMonthlyFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Montly Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                              <MDBTabPane tabId="2" role="tabpanel">
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDiscount"
                                      required={this.state.flatRate}
                                      value={this.state.flatRateDiscount}
                                      onChange={this.changeHandlerQuote}
                                      label="Flat Rate (percent)"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.flatRate}
                                      name="flatRateAuthorizationFee"
                                      value={
                                        this.state.flatRateAuthorizationFee
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Authorization Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      type="number"
                                      name="flatRateOtherTransactionFees"
                                      value={
                                        this.state.flatRateOtherTransactionFees
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Transaction Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateNumberOfTransactions"
                                      value={
                                        this.state
                                          .flatRateNumberOfTransactions > 0
                                          ? this.state
                                              .flatRateNumberOfTransactions
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Number of Trasactions (Estimated)"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <p className="pt-5 text-center">
                                  <b>Other Fees:</b>
                                </p>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateMonthlyServiceFee"
                                      value={
                                        this.state.flatRateMonthlyServiceFee > 0
                                          ? this.state.flatRateMonthlyServiceFee
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Statement/Service Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateMonthlyGatewayFees"
                                      value={
                                        this.state.flatRateMonthlyGatewayFees >
                                        0
                                          ? this.state
                                              .flatRateMonthlyGatewayFees
                                          : "'"
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Gateway Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateChargebackFees"
                                      value={
                                        this.state.flatRateChargebackFees > 0
                                          ? this.state.flatRateChargebackFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Chargeback Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateMonthlyPciRegulatoryFees"
                                      value={
                                        this.state
                                          .flatRateMonthlyPciRegulatoryFees > 0
                                          ? this.state
                                              .flatRateMonthlyPciRegulatoryFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly PCI/Regulatory Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateMonthlyEquipmentFees"
                                      value={
                                        this.state
                                          .flatRateMonthlyEquipmentFees > 0
                                          ? this.state
                                              .flatRateMonthlyEquipmentFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Equipment Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateOtherMonthlyFees"
                                      value={
                                        this.state.flatRateOtherMonthlyFees > 0
                                          ? this.state.flatRateOtherMonthlyFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Montly Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                              <MDBTabPane tabId="3" role="tabpanel">
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeFlatRate"
                                      value={
                                        this.state.flatRateDowngradeFlatRate
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Flat Rate"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeDowngrade"
                                      value={
                                        this.state.flatRateDowngradeDowngrade
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Downgrade"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeFlatRatePercent"
                                      value={
                                        this.state
                                          .flatRateDowngradeFlatRatePercent
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Flat Rate percent"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeDowngradePercent"
                                      value={
                                        this.state
                                          .flatRateDowngradeDowngradePercent
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Downgrade percent"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeAuthorizationFeeFlatRate"
                                      value={
                                        this.state
                                          .flatRateDowngradeAuthorizationFeeFlatRate
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Authorization Fee - Flat Rate"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={
                                        this.state.flatRatePlusDowngrade
                                      }
                                      name="flatRateDowngradeAuthorizationFeeDowngrade"
                                      value={
                                        this.state
                                          .flatRateDowngradeAuthorizationFeeDowngrade
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Authorization Fee - Downgrade"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeOtherTransactionFee"
                                      value={
                                        this.state
                                          .flatRateDowngradeOtherTransactionFee >
                                        0
                                          ? this.state
                                              .flatRateDowngradeOtherTransactionFee
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Transaction Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeEstimateNumberOfTransactions"
                                      value={
                                        this.state
                                          .flatRateDowngradeEstimateNumberOfTransactions >
                                        0
                                          ? this.state
                                              .flatRateDowngradeEstimateNumberOfTransactions
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Estimate Number of Transactions"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <p className="pt-5 text-center">
                                  <b>Other Fees:</b>
                                </p>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeMonthlyServiceFee"
                                      value={
                                        this.state
                                          .flatRateDowngradeMonthlyServiceFee >
                                        0
                                          ? this.state
                                              .flatRateDowngradeMonthlyServiceFee
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Statement/Service Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeMonthlyGatewayFees"
                                      value={
                                        this.state
                                          .flatRateDowngradeMonthlyGatewayFees >
                                        0
                                          ? this.state
                                              .flatRateDowngradeMonthlyGatewayFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Gateway Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeChargebackFees"
                                      value={
                                        this.state
                                          .flatRateDowngradeChargebackFees > 0
                                          ? this.state
                                              .flatRateDowngradeChargebackFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Chargeback Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeMonthlyPciRegulatoryFees"
                                      value={
                                        this.state
                                          .flatRateDowngradeMonthlyPciRegulatoryFees >
                                        0
                                          ? this.state
                                              .flatRateDowngradeMonthlyPciRegulatoryFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly PCI/Regulatory Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeMonthlyEquipmentFees"
                                      value={
                                        this.state
                                          .flatRateDowngradeMonthlyEquipmentFees >
                                        0
                                          ? this.state
                                              .flatRateDowngradeMonthlyEquipmentFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Equipment Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="flatRateDowngradeOtherMonthlyFees"
                                      value={
                                        this.state
                                          .flatRateDowngradeOtherMonthlyFees > 0
                                          ? this.state
                                              .flatRateDowngradeOtherMonthlyFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Montly Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                              <MDBTabPane tabId="4" role="tabpanel">
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingQualified"
                                      value={this.state.tierPricingQualified}
                                      onChange={this.changeHandlerQuote}
                                      label="Qualified"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingMidQualified"
                                      value={this.state.tierPricingMidQualified}
                                      onChange={this.changeHandlerQuote}
                                      label="Mid-Qualified"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingNonQualified"
                                      value={this.state.tierPricingNonQualified}
                                      onChange={this.changeHandlerQuote}
                                      label="Non-Qualified"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingQualifiedPercent"
                                      value={
                                        this.state.tierPricingQualifiedPercent
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Qualified Percent"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingMidQualifiedPercent"
                                      value={
                                        this.state
                                          .tierPricingMidQualifiedPercent
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Mid-Qualified Percent"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingNonQualifiedPercent"
                                      value={
                                        this.state
                                          .tierPricingNonQualifiedPercent
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Non-Qualified Percent"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      required={this.state.tierPricing}
                                      name="tierPricingAuthorizationFee"
                                      value={
                                        this.state.tierPricingAuthorizationFee
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Authorization Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingOtherTransactionFees"
                                      value={
                                        this.state
                                          .tierPricingOtherTransactionFees > 0
                                          ? this.state
                                              .tierPricingOtherTransactionFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Transaction Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingEstimateNumberOfTransactions"
                                      value={
                                        this.state
                                          .tierPricingEstimateNumberOfTransactions >
                                        0
                                          ? this.state
                                              .tierPricingEstimateNumberOfTransactions
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Estimate Number of Transactions"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>

                                <p className="pt-5 text-center">
                                  <b>Other Fees:</b>
                                </p>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingMonthlyServiceFee"
                                      value={
                                        this.state
                                          .tierPricingMonthlyServiceFee > 0
                                          ? this.state
                                              .tierPricingMonthlyServiceFee
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Statement/Service Fee"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingMonthlyGatewayFees"
                                      value={
                                        this.state
                                          .tierPricingMonthlyGatewayFees > 0
                                          ? this.state
                                              .tierPricingMonthlyGatewayFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly Gateway Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingChargebackFees"
                                      value={
                                        this.state.tierPricingChargebackFees > 0
                                          ? this.state.tierPricingChargebackFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Chargeback Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingMonthlyPciRegulatoryFees"
                                      value={
                                        this.state
                                          .tierPricingMonthlyPciRegulatoryFees >
                                        0
                                          ? this.state
                                              .tierPricingMonthlyPciRegulatoryFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Monthly PCI/Regulatory Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingMonthlyEquipmentFees"
                                      value={
                                        this.state
                                          .tierPricingMonthlyEquipmentFees > 0
                                          ? this.state
                                              .tierPricingMonthlyEquipmentFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Equipment Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                  <MDBCol>
                                    <MDBInput
                                      name="tierPricingOtherMonthlyFees"
                                      value={
                                        this.state.tierPricingOtherMonthlyFees >
                                        0
                                          ? this.state
                                              .tierPricingOtherMonthlyFees
                                          : ""
                                      }
                                      onChange={this.changeHandlerQuote}
                                      label="Other Montly Fees"
                                      type="number"
                                      className="mt-4"
                                    />
                                  </MDBCol>
                                </MDBRow>
                              </MDBTabPane>
                            </MDBTabContent>
                            <MDBRow center className="pt-5">
                              <MDBCol md="6">
                                <MDBSelect
                                  options={this.state.priceOptions}
                                  required
                                  id="materialInvalidCheck"
                                  label={
                                    this.state.selectedQuotePricing === ""
                                      ? "Please Select Final Quote for Submission"
                                      : this.state.selectedQuotePricing
                                  }
                                  getTextContent={
                                    this.handlePricingSelectChange
                                  }
                                />
                              </MDBCol>
                            </MDBRow>
                            <MDBRow className="pt-3 text-center">
                              <MDBCol className="p-3">
                                <MDBBtn
                                  id="draft"
                                  color="success"
                                  rounded
                                  type="submit"
                                >
                                  Save Draft
                                </MDBBtn>
                                <MDBBtn
                                  // onClick={this.finalizeQuote}
                                  id="generate"
                                  rounded
                                  color="primary"
                                  type="submit"
                                >
                                  Finalize Quote
                                </MDBBtn>
                              </MDBCol>
                            </MDBRow>
                          </form>
                        </div>
                      );
                    }}
                  </MDBSticky>
                </MDBStickyContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </MDBRow>
      );
    }

    let pricingModel = (
      <div>
        <hr />
        <MDBRow className="text-center">
          <MDBCol middle>
            <h3 className="pt-5"> Merchant Services Pricing Models</h3>
            <p className="pt-1">
              Select which pricing models you would like to evaluate for the
              quote.
            </p>
          </MDBCol>
        </MDBRow>
        <MDBRow className="p-5">
          <MDBCol>
            <MDBInput
              label="Interchange Pass-thru"
              type="checkbox"
              id="interchangePassThru"
              checked={this.state.interchangePassThru}
              getValue={this.handleInterchangePassThruCheckboxChange}
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              label="Flat Rate"
              type="checkbox"
              id="flatRate"
              checked={this.state.flatRate}
              getValue={this.handleFlatRateCheckboxChange}
            />
          </MDBCol>

          <MDBCol>
            <MDBInput
              label="Flat Rate + Downgrade"
              type="checkbox"
              id="flatRateDowngrade"
              checked={this.state.flatRatePlusDowngrade}
              getValue={this.handleFlatRatePlusDowngradeCheckboxChange}
            />
          </MDBCol>
          <MDBCol>
            <MDBInput
              checked={this.state.tierPricing}
              getValue={this.handleTierPricingCheckboxChange}
              label="Tier Pricing"
              type="checkbox"
              id="tierpricing"
            />
          </MDBCol>
        </MDBRow>
        <hr />
        {pricingModelPricingTable}
      </div>
    );

    //Import Calculation Modules

    let {
      quoteInterchangeEffectiveRate,
      quoteInterchangeMargin,
      quoteInterchangeEstMonthlyFees,
      interchangeCompetitionScore,
      interchangeProfitabilityScore,
      interchangeProfitabilityGoalScore,
      interchangeEstimatedProfit,
      quoteFlatRateEffectiveRate,
      quoteFlatRateMargin,
      quoteFlatRateEstMonthlyFees,
      flatRateCompetitionScore,
      flatRateProfitabilityScore,
      flatRateProfitabilityGoalScore,
      flatRateEstimatedProfit,
      quoteFlatRateDowngradeEffectiveRate,
      quoteFlatRateDowngradeMargin,
      quoteFlatRateDowngradeEstMonthlyFees,
      flatRateDowngradeCompetitionScore,
      flatRateDowngradeProfitabilityScore,
      flatRateDowngradeProfitabilityGoalScore,
      flatRateDowngradeEstimatedProfit,
      quoteTierPricingEffectiveRate,
      quoteTierPricingMargin,
      quoteTierPricingEstMonthlyFees,
      tierPricingCompetitionScore,
      tierPricingProfitabilityScore,
      tierPricingProfitabilityGoalScore,
      tierPricingEstimatedProfit,
    } = require("./pricingCalculations")(this.state);

    //Generated Quote Data Calculation
    const data_panel = {
      columns: [
        {
          label: "",
          field: "",
        },
        {
          label: "Interchange Plus",
          field: "Interchange Plus",
        },
        {
          label: "Flat Rate",
          field: "Flat Rate",
        },
        {
          label: "Flat Rate + Downgrade",
          field: "Flat Rate + Downgrade",
        },
        {
          label: "Tier Pricing",
          field: "Tier Pricing",
        },

        {
          label: "Market",
          field: "Market",
        },
        {
          label: "Previous Processor",
          field: "Previous Processor",
        },
      ],
      rates_rows: [
        {
          id: "Discount",
          InterchangePlus: "0." + this.state.interchangeDiscount + "%",
          FlatRate: this.state.flatRateDiscount + "%",
          FlatRateDowngrade: (
            <div>
              <div>
                <strong>Qual:</strong> {this.state.flatRateDowngradeFlatRate}%
              </div>{" "}
              <div>
                <strong>Non-Qual: </strong>
                {this.state.flatRateDowngradeDowngrade}%
              </div>
            </div>
          ),
          TierPricing: (
            <div>
              <div>
                <strong>Qual:</strong> {this.state.tierPricingQualified}%
              </div>{" "}
              <div>
                <strong>Mid-Qual: </strong>
                {this.state.tierPricingMidQualified}%
              </div>{" "}
              <div>
                <strong>Non-Qual: </strong>
                {this.state.tierPricingNonQualified}%
              </div>
            </div>
          ),
          Benchmark: (this.state.discountRate * 100).toFixed(2) + "%",
          previousProcessor:
            this.state.previousProcessor === true
              ? parseFloat(this.state.previousProcessorDiscountRate).toFixed(
                  2
                ) + "%"
              : "N/A",
        },

        {
          id: "Authorization",
          InterchangePlus: this.state.interchangeAuthorizationFee * 100 + "¢",
          FlatRate: this.state.flatRateAuthorizationFee * 100 + "¢",
          FlatRateDowngrade:
            this.state.flatRateDowngradeAuthorizationFeeDowngrade * 100 + "¢",
          TierPricing: this.state.tierPricingAuthorizationFee * 100 + "¢",
          Benchmark:
            (
              this.state.transactionPercent *
              this.state.averageTicket *
              100
            ).toFixed(0) + "¢",

          previousProcessor:
            this.state.previousProcessor === true
              ? parseFloat(this.state.previousProcessorTransactionFees).toFixed(
                  2
                ) *
                  100 +
                "¢"
              : "N/A",
        },
        {
          id: "Other Transactions",
          InterchangePlus:
            this.state.interchangeOtherTransactionFees * 100 + "¢",
          FlatRate: this.state.flatRateOtherTransactionFees * 100 + "¢",
          FlatRateDowngrade:
            this.state.flatRateDowngradeOtherTransactionFee * 100 + "¢",
          TierPricing: this.state.tierPricingOtherTransactionFees * 100 + "¢",
          Benchmark: this.state.miscTransactions * 100 + "¢",
          previousProcessor: "N/A",
        },

        {
          id: <strong>Effective Rate</strong>,
          InterchangePlus:
            this.state.selectedQuotePricing === "Interchange Plus" ? (
              <strong style={{ color: "green" }}>
                {" "}
                {this.state.interchangeQuoteTotals.effectiveRate + "%"}{" "}
              </strong>
            ) : (
              <strong>
                {this.state.interchangeQuoteTotals.effectiveRate + "%"}{" "}
              </strong>
            ),
          FlatRate:
            this.state.selectedQuotePricing === "Flat Rate" ? (
              <strong style={{ color: "green" }}>
                {this.state.flatRateQuoteTotals.effectiveRate + "%"}
              </strong>
            ) : (
              <strong>
                {this.state.flatRateQuoteTotals.effectiveRate + "%"}
              </strong>
            ),

          FlatRateDowngrade:
            this.state.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
              <strong style={{ color: "green" }}>
                {this.state.flatRateDowngradeQuoteTotals.effectiveRate + "%"}
              </strong>
            ) : (
              <strong>
                {this.state.flatRateDowngradeQuoteTotals.effectiveRate + "%"}
              </strong>
            ),

          TierPricing:
            this.state.selectedQuotePricing === "Tier Pricing" ? (
              <strong style={{ color: "green" }}>
                {this.state.tierPricingQuoteTotals.effectiveRate + "%"}
              </strong>
            ) : (
              <strong>
                {this.state.tierPricingQuoteTotals.effectiveRate + "%"}
              </strong>
            ),

          Benchmark: (
            <strong>{(this.state.effectiveRate * 100).toFixed(2) + "%"}</strong>
          ),
          previousProcessor:
            this.state.previousProcessor === true ? (
              <strong>
                {parseFloat(
                  ((this.state.previousProcessorTotalBillAmount -
                    this.state.previousProcessorOtherFees) /
                    this.state.previousProcessorSalesVolume) *
                    100
                ).toFixed(2) + "%"}
              </strong>
            ) : (
              <strong>N/A</strong>
            ),
        },
      ],
      monthlyFees_rows: [
        {
          id: "Statement Fee",
          InterchangePlus: "$" + this.state.interchangeMonthlyServiceFee,
          FlatRate: "$" + this.state.flatRateMonthlyServiceFee,
          FlatRateDowngrade:
            "$" + this.state.flatRateDowngradeMonthlyServiceFee,
          TierPricing: "$" + this.state.tierPricingMonthlyServiceFee,
          Benchmark: "$18",
          previousProcessor: "N/A",
        },

        {
          id: "Gateway Fee",
          InterchangePlus: "$" + this.state.interchangeMonthlyGatewayFees,
          FlatRate: "$" + this.state.flatRateMonthlyGatewayFees,
          FlatRateDowngrade:
            "$" + this.state.flatRateDowngradeMonthlyGatewayFees,
          TierPricing: "$" + this.state.tierPricingMonthlyGatewayFees,
          Benchmark: "N/A",
          previousProcessor: "N/A",
        },
        {
          id: "Chargeback Fee",
          InterchangePlus: "$" + this.state.interchangeChargebackFees,
          FlatRate: "$" + this.state.flatRateChargebackFees,
          FlatRateDowngrade: "$" + this.state.flatRateDowngradeChargebackFees,
          TierPricing: "$" + this.state.tierPricingChargebackFees,
          Benchmark: "N/A",
          previousProcessor: "N/A",
        },

        {
          id: "PCI/Regulatory Fees",
          InterchangePlus: "$" + this.state.interchangeMonthlyPciRegulatoryFees,
          FlatRate: "$" + this.state.flatRateMonthlyPciRegulatoryFees,
          FlatRateDowngrade:
            "$" + this.state.flatRateDowngradeMonthlyPciRegulatoryFees,
          TierPricing: "$" + this.state.tierPricingMonthlyPciRegulatoryFees,
          Benchmark: "$5",
          previousProcessor: "N/A",
        },
        {
          id: "Equipment Fees",
          InterchangePlus: "$" + this.state.interchangeMonthlyEquipmentFees,
          FlatRate: "$" + this.state.flatRateMonthlyEquipmentFees,
          FlatRateDowngrade:
            "$" + this.state.flatRateDowngradeMonthlyEquipmentFees,
          TierPricing: "$" + this.state.tierPricingMonthlyEquipmentFees,
          Benchmark: "$29",
          previousProcessor: "N/A",
        },
        {
          id: "Other Monthly Fees",
          InterchangePlus: "$" + this.state.interchangeOtherMonthlyFees,
          FlatRate: "$" + this.state.flatRateOtherMonthlyFees,
          FlatRateDowngrade: "$" + this.state.flatRateDowngradeOtherMonthlyFees,
          TierPricing: "$" + this.state.tierPricingOtherMonthlyFees,
          Benchmark: "$3",
          previousProcessor:
            this.state.previousProcessor === true
              ? "$" +
                parseFloat(this.state.previousProcessorOtherFees)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "N/A",
        },
      ],
      totals_rows: [
        {
          id: "Total Merchant Fees",
          InterchangePlus:
            this.state.selectedQuotePricing === "Interchange Plus" ? (
              <strong style={{ color: "green" }}>
                {" "}
                {"$" +
                  parseFloat(
                    this.state.interchangeQuoteTotals.merchantTotalFees
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.interchangeQuoteTotals.merchantTotalFees)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          FlatRate:
            this.state.selectedQuotePricing === "Flat Rate" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(this.state.flatRateQuoteTotals.merchantTotalFees)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.flatRateQuoteTotals.merchantTotalFees)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          FlatRateDowngrade:
            this.state.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(
                    this.state.flatRateDowngradeQuoteTotals.merchantTotalFees
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </strong>
            ) : (
              "$" +
              parseFloat(
                this.state.flatRateDowngradeQuoteTotals.merchantTotalFees
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          TierPricing:
            this.state.selectedQuotePricing === "Tier Pricing" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(
                    this.state.tierPricingQuoteTotals.merchantTotalFees
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.tierPricingQuoteTotals.merchantTotalFees)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          Benchmark:
            "$" +
            (0.0252 * this.state.estimatedSalesVolume + 54.79)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          previousProcessor:
            this.state.previousProcessor === true
              ? "$" +
                parseFloat(this.state.previousProcessorTotalBillAmount)
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "N/A",
        },

        {
          id: "Estimated Margin",
          InterchangePlus:
            this.state.selectedQuotePricing === "Interchange Plus" ? (
              <strong style={{ color: "green" }}>
                {parseFloat(
                  this.state.interchangeQuoteTotals.grossMargin
                ).toFixed(2) + "%"}
              </strong>
            ) : (
              parseFloat(this.state.interchangeQuoteTotals.grossMargin).toFixed(
                2
              ) + "%"
            ),

          FlatRate:
            this.state.selectedQuotePricing === "Flat Rate" ? (
              <strong style={{ color: "green" }}>
                {parseFloat(this.state.flatRateQuoteTotals.grossMargin).toFixed(
                  2
                ) + "%"}
              </strong>
            ) : (
              parseFloat(this.state.flatRateQuoteTotals.grossMargin).toFixed(
                2
              ) + "%"
            ),

          FlatRateDowngrade:
            this.state.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
              <strong style={{ color: "green" }}>
                {parseFloat(
                  this.state.flatRateDowngradeQuoteTotals.grossMargin
                ).toFixed(2) + "%"}
              </strong>
            ) : (
              parseFloat(
                this.state.flatRateDowngradeQuoteTotals.grossMargin
              ).toFixed(2) + "%"
            ),
          TierPricing:
            this.state.selectedQuotePricing === "Tier Pricing" ? (
              <strong style={{ color: "green" }}>
                {parseFloat(
                  this.state.tierPricingQuoteTotals.grossMargin
                ).toFixed(2) + "%"}
              </strong>
            ) : (
              parseFloat(this.state.tierPricingQuoteTotals.grossMargin).toFixed(
                2
              ) + "%"
            ),

          Benchmark: "0.67%",
          previousProcessor:
            this.state.previousProcessor === true
              ? parseFloat(
                  ((this.state.previousProcessorTotalBillAmount -
                    this.state.previousProcessorOtherFees) /
                    this.state.previousProcessorSalesVolume -
                    this.state.interchangeCost -
                    this.state.duesCost) *
                    100
                ).toFixed(2) + "%"
              : "N/A",
        },
        {
          id: "Estimated Profit",
          InterchangePlus:
            this.state.selectedQuotePricing === "Interchange Plus" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(
                    this.state.interchangeQuoteTotals.estimatedNetProfit
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.interchangeQuoteTotals.estimatedNetProfit)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          FlatRate:
            this.state.selectedQuotePricing === "Flat Rate" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(this.state.flatRateQuoteTotals.estimatedNetProfit)
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.flatRateQuoteTotals.estimatedNetProfit)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          FlatRateDowngrade:
            this.state.selectedQuotePricing === "Flat Rate Plus Downgrade" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(
                    this.state.flatRateDowngradeQuoteTotals.estimatedNetProfit
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </strong>
            ) : (
              "$" +
              parseFloat(
                this.state.flatRateDowngradeQuoteTotals.estimatedNetProfit
              )
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          TierPricing:
            this.state.selectedQuotePricing === "Tier Pricing" ? (
              <strong style={{ color: "green" }}>
                {"$" +
                  parseFloat(
                    this.state.tierPricingQuoteTotals.estimatedNetProfit
                  )
                    .toFixed(2)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </strong>
            ) : (
              "$" +
              parseFloat(this.state.tierPricingQuoteTotals.estimatedNetProfit)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            ),

          Benchmark:
            "$" +
            (0.0067 * this.state.estimatedSalesVolume)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
          previousProcessor:
            this.state.previousProcessor === true
              ? "$" +
                parseFloat(
                  ((this.state.previousProcessorTotalBillAmount -
                    this.state.previousProcessorOtherFees) /
                    this.state.previousProcessorSalesVolume -
                    this.state.interchangeCost -
                    this.state.duesCost) *
                    this.state.previousProcessorSalesVolume
                )
                  .toFixed(2)
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : "N/A",
        },
      ],
    };

    return (
      <UserContext.Consumer>
        {(userContext) => (
          <div>
            {this.state.authorized == true ? (
              this.state.quoteGenerated == true &&
              this.state.finalizingQuote == true ? (
                <Loading message={"Finalizing Quote"} />
              ) : this.state.quoteGenerated == true ? (
                <MDBContainer
                  fluid
                  className="pt-5"
                  // style={{ paddingLeft: "7%", paddingRight: "7%" }}
                >
                  <MDBRow className="pt-5">
                    <MDBCol md="8">
                      <MDBRow className="">
                        <MDBCol>
                          <h2 className="h1-responsive font-weight-bold text-center my-5">
                            Quote Summary #{this.state.quoteNumber}{" "}
                            <MDBBadge color="primary">Generated</MDBBadge>
                          </h2>
                        </MDBCol>
                      </MDBRow>
                      {userContext.currentUser().role === 1 ||
                      userContext.currentUser().role === 2 ? (
                        <MDBRow>
                          <MDBCol>
                            {" "}
                            <MDBCard style={{ marginTop: "1rem" }}>
                              <MDBCardHeader
                                className="text-center"
                                color="primary-color"
                                tag="h4"
                              >
                                <b>Quote Approval Options</b>
                              </MDBCardHeader>
                              <MDBCardBody>
                                <MDBCardText>
                                  <div>
                                    {/* <h5>
                                      Sales Agent:{" "}
                                      <b>
                                        {this.state.quoteOwnerFirstName +
                                          " " +
                                          this.state.quoteOwnerLastName}
                                      </b>
                                    </h5> */}
                                    <MDBSelect
                                      options={this.state.quoteApprovalOptions}
                                      selected={this.state.quoteStatus}
                                      getTextContent={
                                        this.handleQuoteStatusChange
                                      }
                                    />
                                    <div className="form-group">
                                      <label htmlFor="exampleFormControlTextarea1">
                                        Notes
                                      </label>
                                      <textarea
                                        value={this.state.quoteApprovalNotes}
                                        name="quoteApprovalNotes"
                                        className="form-control"
                                        id="exampleFormControlTextarea1"
                                        rows="3"
                                        onChange={this.changeHandlerQuote}
                                      />
                                    </div>
                                  </div>
                                  <div className="text-center">
                                    <MDBBtn
                                      onClick={
                                        this.handleSubmitApprovalResponse
                                      }
                                      color="primary"
                                    >
                                      Update Quote
                                    </MDBBtn>
                                    <MDBBtn
                                      onClick={this.callUpdateInitialQuoteApi}
                                    >
                                      Edit Quote
                                    </MDBBtn>
                                  </div>
                                </MDBCardText>
                              </MDBCardBody>
                            </MDBCard>
                          </MDBCol>
                        </MDBRow>
                      ) : (
                        ""
                      )}
                      <MDBRow>
                        <MDBCol>
                          {" "}
                          <MDBCard style={{ marginTop: "1rem" }}>
                            <MDBCardHeader color="primary-color" tag="h4">
                              Merchant Information
                            </MDBCardHeader>
                            <MDBCardBody>
                              <MDBCardText>
                                <div>
                                  <strong>Merchant DBA:</strong>{" "}
                                  {this.state.dba}
                                </div>
                                <div>
                                  <strong>Address:</strong>{" "}
                                  {this.state.physicalLocation +
                                    ", 9" +
                                    this.state.city +
                                    ", " +
                                    this.state.state +
                                    " " +
                                    this.state.zipcode}
                                </div>
                                <div>
                                  <strong>Phone Number:</strong>{" "}
                                  {this.state.phoneNumber}
                                </div>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                        <MDBCol>
                          {" "}
                          <MDBCard style={{ marginTop: "1rem" }}>
                            <MDBCardHeader color="primary-color" tag="h4">
                              Processing Information
                            </MDBCardHeader>
                            <MDBCardBody>
                              <MDBCardText>
                                <MDBRow>
                                  <MDBCol>
                                    <div>
                                      <strong>Sales Volume:</strong> $
                                      {parseFloat(
                                        this.state.estimatedSalesVolume
                                      )
                                        .toFixed(2)
                                        .trim()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div>
                                      <strong>Average Ticket:</strong> $
                                      {parseFloat(
                                        this.state.estimatedAverageTicket
                                      )
                                        .toFixed(2)
                                        .trim()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </div>
                                    <div>
                                      <strong>Approval Status:</strong>{" "}
                                      {this.state.quoteStatus === "Approved" ? (
                                        <strong style={{ color: "green" }}>
                                          Approved
                                        </strong>
                                      ) : this.state.quoteStatus ===
                                        "Declined" ? (
                                        <strong style={{ color: "Red" }}>
                                          Declined
                                        </strong>
                                      ) : this.state.quoteStatus ===
                                        "Pending" ? (
                                        <strong style={{ color: "blue" }}>
                                          Pending
                                        </strong>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </MDBCol>
                                  <MDBCol>
                                    <div>
                                      <strong>Quote: </strong>
                                      <strong style={{ color: "blue" }}>
                                        {this.state.selectedQuotePricing}
                                      </strong>
                                    </div>
                                    <div>
                                      <strong>Effective Rate: </strong>
                                      {this.state.selectedQuotePricing ===
                                      "Interchange Plus"
                                        ? parseFloat(
                                            this.state.interchangeQuoteTotals
                                              .effectiveRate
                                          ).toFixed(2) + "%"
                                        : ""}

                                      {this.state.selectedQuotePricing ===
                                      "Flat Rate"
                                        ? parseFloat(
                                            this.state.flatRateQuoteTotals
                                              .effectiveRate
                                          ).toFixed(2) + "%"
                                        : ""}

                                      {this.state.selectedQuotePricing ===
                                      "Flat Rate Plus Downgrade"
                                        ? parseFloat(
                                            this.state
                                              .flatRateDowngradeQuoteTotals
                                              .effectiveRate
                                          ).toFixed(2) + "%"
                                        : ""}

                                      {this.state.selectedQuotePricing ===
                                      "Tier Pricing"
                                        ? parseFloat(
                                            this.state.tierPricingQuoteTotals
                                              .effectiveRate
                                          ).toFixed(2) + "%"
                                        : ""}
                                    </div>
                                  </MDBCol>
                                </MDBRow>
                                <MDBRow className="pt-2">
                                  <MDBCol>
                                    {" "}
                                    <b>Support Notes:</b>{" "}
                                    {this.state.quoteApprovalNotes
                                      ? this.state.quoteApprovalNotes
                                      : "None"}
                                  </MDBCol>
                                </MDBRow>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>{" "}
                      <MDBRow className="py-3">
                        <MDBCol md="12">
                          <MDBCard narrow>
                            <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3 mt-3">
                              <div></div>
                              <h4>Pricing Information</h4>
                              <div></div>
                            </MDBCardHeader>
                            <MDBCardBody cascade>
                              <MDBTable small btn fixed>
                                <MDBTableHead columns={data_panel.columns} />
                                <MDBTableBody rows={data_panel.rates_rows} />
                              </MDBTable>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pt-2">
                        <MDBCol>
                          <MDBCard narrow>
                            <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4">
                              <div></div>
                              <h5>Monthly Fees</h5>
                              <div></div>
                            </MDBCardHeader>

                            <MDBCardBody className="pt-0" cascade>
                              <MDBTable small btn fixed>
                                <MDBTableHead columns={data_panel.columns} />
                                <MDBTableBody
                                  rows={data_panel.monthlyFees_rows}
                                />
                              </MDBTable>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="pt-4">
                        <MDBCol>
                          <MDBCard narrow>
                            <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4">
                              <div></div>
                              <h5>Merchant Totals</h5>
                              <div></div>
                            </MDBCardHeader>

                            <MDBCardBody className="pt-0" cascade>
                              <MDBTable small btn fixed>
                                <MDBTableHead columns={data_panel.columns} />
                                <MDBTableBody rows={data_panel.totals_rows} />
                              </MDBTable>
                            </MDBCardBody>
                          </MDBCard>
                        </MDBCol>
                      </MDBRow>
                      <MDBRow className="text-center pt-3 pb-3">
                        <MDBCol>
                          <div>
                            <MDBBtn
                              href={`/quote/${this.state.quoteId}/pdf`}
                              color="primary"
                            >
                              Download PDF
                            </MDBBtn>
                            <MDBBtn onClick={() => alert("Coming Soon!")}>
                              Clone Quote
                            </MDBBtn>
                          </div>
                        </MDBCol>
                      </MDBRow>
                    </MDBCol>
                    <MDBCol md="4">
                      {" "}
                      <div style={{ height: "1550px" }}>
                        <div>
                          <div>
                            <div>
                              <MDBStickyContent
                                style={{ background: "#fff", height: "1500px" }}
                              >
                                <MDBSticky>
                                  {({ style }) => {
                                    return (
                                      <div
                                        style={{
                                          ...style,
                                        }}
                                      >
                                        <h2 className="text-center pb-3">
                                          Pricing Scores
                                        </h2>
                                        <MDBNav
                                          className="mt-3"
                                          tabs
                                          color="primary"
                                        >
                                          {this.state.tierPricing == false &&
                                          this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.flatRate == false ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={false}
                                                role="tab"
                                              >
                                                Please Select a Pricing Model!
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}

                                          {this.state.interchangePassThru ==
                                          true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "1"
                                                }
                                                onClick={this.toggle("1")}
                                                role="tab"
                                              >
                                                INTX
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.flatRate == true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "2"
                                                }
                                                onClick={this.toggle("2")}
                                                role="tab"
                                              >
                                                FLAT
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.flatRatePlusDowngrade ==
                                          true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "3"
                                                }
                                                onClick={this.toggle("3")}
                                                role="tab"
                                              >
                                                FLT+D
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.tierPricing == true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "4"
                                                }
                                                onClick={this.toggle("4")}
                                                role="tab"
                                              >
                                                TIER
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                        </MDBNav>
                                        <MDBTabContent
                                          className="card"
                                          activeItem={this.state.activeItem}
                                        >
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="1"
                                              role="tabpanel"
                                            >
                                              <h5 className="pt-1">
                                                Competition Score
                                              </h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        interchangeCompetitionScore
                                                      )
                                                        ? null
                                                        : interchangeCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart1"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          interchangeProfitabilityScore
                                                        )
                                                          ? null
                                                          : interchangeProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart2"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          interchangeProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : interchangeProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart3"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio1"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio6"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="2"
                                              role="tabpanel"
                                            >
                                              <h5 className="pt-1">
                                                Competition Score
                                              </h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        flatRateCompetitionScore
                                                      )
                                                        ? null
                                                        : flatRateCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart4"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          flatRateProfitabilityScore
                                                        )
                                                          ? null
                                                          : flatRateProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart5"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          flatRateProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : flatRateProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart6"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio7"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio8"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              tabId="3"
                                              role="tabpanel"
                                              className="text-center"
                                            >
                                              <h5 className="pt-1">
                                                Competition Score
                                              </h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        flatRateDowngradeCompetitionScore
                                                      )
                                                        ? null
                                                        : flatRateDowngradeCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart7"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          flatRateDowngradeProfitabilityScore
                                                        )
                                                          ? null
                                                          : flatRateDowngradeProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart8"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          flatRateDowngradeProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : flatRateDowngradeProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart9"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio3"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="9"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="4"
                                              role="tabpanel"
                                            >
                                              <h5 className="pt-1">
                                                Competition Score
                                              </h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        tierPricingCompetitionScore
                                                      )
                                                        ? null
                                                        : tierPricingCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart10"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          tierPricingProfitabilityScore
                                                        )
                                                          ? null
                                                          : tierPricingProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart11"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          tierPricingProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : tierPricingProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart12"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio4"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio5"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                        </MDBTabContent>
                                      </div>
                                    );
                                  }}
                                </MDBSticky>
                              </MDBStickyContent>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </MDBContainer>
              ) : (
                <div>
                  <MDBRow className="pt-4">
                    <MDBCol className="pt-5" md="8" center="true">
                      <h2 className="h1-responsive font-weight-bold text-center my-5">
                        Merchant Quote #{this.state.quoteNumber}
                      </h2>
                    </MDBCol>
                    <MDBCol></MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="8">
                      <div>
                        {this.state.quoteGenerated !== false ? (
                          <p className="lead w-responsive text-center mx-auto mb-5">
                            Please enter all the required information in order
                            to generate a quote.
                          </p>
                        ) : (
                          ""
                        )}
                        <MDBCard narrow>
                          <MDBCardImage
                            className="view view-cascade gradient-card-header primary-color"
                            tag="div"
                          >
                            <h3 className="h3-responsive">
                              Merchant Information
                            </h3>
                          </MDBCardImage>
                          <MDBCardBody>
                            {this.state.showMerchantInformation !== false ? (
                              <div>
                                {" "}
                                <MDBRow className="pt-5 justify-content-center">
                                  <MDBCol md="2" className="pl-5 pl-md-0 pb-5">
                                    <MDBStepper icon vertical>
                                      {this.state.firstStepperStatus ===
                                      "valid" ? (
                                        <MDBCustomStep
                                          far
                                          icon="user"
                                          iconStyle=""
                                          stepName="General Information"
                                          onClick={this.swapFormActive(3)(1)}
                                          vertical
                                        />
                                      ) : (
                                        <MDBCustomStep
                                          far
                                          icon="user"
                                          iconStyle="red-text"
                                          stepName="General Information"
                                          onClick={this.swapFormActive(3)(1)}
                                          vertical
                                        />
                                      )}

                                      {this.state.secondStepperStatus ===
                                      "valid" ? (
                                        <MDBCustomStep
                                          icon="map-marker-alt"
                                          stepName="Business Location"
                                          onClick={this.swapFormActive(3)(2)}
                                          vertical
                                        />
                                      ) : (
                                        <MDBCustomStep
                                          icon="map-marker-alt"
                                          iconStyle="red-text"
                                          stepName="Business Location"
                                          onClick={this.swapFormActive(3)(2)}
                                          vertical
                                        />
                                      )}

                                      {this.state.thirdStepperStatus ===
                                      "valid" ? (
                                        <MDBCustomStep
                                          far
                                          icon="credit-card"
                                          stepName="Merchant Processing Information"
                                          onClick={this.swapFormActive(3)(3)}
                                          vertical
                                        />
                                      ) : (
                                        <MDBCustomStep
                                          far
                                          icon="credit-card"
                                          stepName="Merchant Processing Information"
                                          onClick={this.swapFormActive(3)(3)}
                                          iconStyle="red-text"
                                          vertical
                                        />
                                      )}

                                      <MDBStep
                                        icon="check"
                                        stepName="Save and Continue"
                                        onClick={this.swapFormActive(3)(4)}
                                        vertical
                                      />
                                    </MDBStepper>
                                  </MDBCol>

                                  <MDBCol md="7">
                                    <form
                                      className="needs-validation"
                                      onSubmit={this.submitHandler}
                                      noValidate
                                    >
                                      {this.state.formActivePanel3 === 1 && (
                                        <MDBCol md="12">
                                          <h3 className="font-weight-bold pl-0 my-4">
                                            <strong>General Information</strong>
                                          </h3>

                                          <MDBInput
                                            name="dba"
                                            value={this.state.dba}
                                            onChange={this.changeHandler}
                                            label="DBA"
                                            type="text"
                                            autoFocus={this.calculateAutofocus(
                                              3
                                            )}
                                            required
                                            id="materialInvalidCheck"
                                            className="mt-4"
                                          >
                                            <div className="invalid-feedback">
                                              ff DBA Name is Required
                                            </div>
                                          </MDBInput>

                                          <MDBInput
                                            name="contactName"
                                            value={this.state.contactName}
                                            onChange={this.changeHandler}
                                            label="Merchant Contact"
                                            type="text"
                                            className="mt-4"
                                          />

                                          <MDBInput
                                            name="contactName"
                                            value={this.state.email}
                                            onChange={this.changeHandler}
                                            label="Email"
                                            type="email"
                                            className="mt-4"
                                          />

                                          <MDBInput
                                            name="phoneNumber"
                                            value={this.state.phoneNumber}
                                            onChange={this.changeHandler}
                                            label="Phone Number"
                                            type="text"
                                            className="mt-4"
                                          />
                                          <MDBRow center>
                                            <p className="mcc">
                                              {" "}
                                              {!this.state.mccCode ===
                                                undefined ||
                                              !this.state.mccCode.length == 0
                                                ? "MCC Code Assigned: " +
                                                  this.state.mccCode
                                                : ""}
                                            </p>{" "}
                                          </MDBRow>

                                          <MDBSelect
                                            options={
                                              this.state.mccCategoryOptions
                                            }
                                            search
                                            required
                                            id="materialInvalidCheck"
                                            label={this.state.mccCategory}
                                            getTextContent={
                                              this.handleMccCategorySelectChange
                                            }
                                          ></MDBSelect>

                                          {this.state.mccCategory ==
                                          "invalid" ? (
                                            <div className="invalid-feedback2">
                                              MCC Category is Required
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          <MDBSelect
                                            options={
                                              this.state.mccDescriptionOptions
                                            }
                                            label={
                                              this.state.mccCategoryDescription
                                            }
                                            search
                                            required
                                            selected="First Select MCC Category"
                                            getValue={
                                              this.handleMccCodeSelectChange
                                            }
                                            getTextContent={
                                              this
                                                .handleMCCDescriptionSelectChange
                                            }
                                          />

                                          {this.state.mccCategoryDescription ==
                                          "invalid" ? (
                                            <div className="invalid-feedback2">
                                              MCC Category is Required
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {/* <MDBInput
       label="MCC Number"
       className="mcc"
       value={
         !this.state.mccCode === undefined ||
         !this.state.mccCode.length == 0
           ? this.state.mccCode
           : ""
       }
       required
       disabled={
         this.state.firstStepperStatus == "invalid"
           ? false
           : true
       }
     >
       <div className="invalid-feedback">
         Please select "MCC Category" and "MCC Description"
       </div>
     </MDBInput> */}

                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-right"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(2)}
                                          >
                                            next
                                          </MDBBtn>
                                        </MDBCol>
                                      )}
                                      {this.state.formActivePanel3 === 2 && (
                                        <MDBCol md="12">
                                          <h3 className="font-weight-bold pl-0 my-4">
                                            <strong>Business Location</strong>
                                          </h3>
                                          <MDBInput
                                            name="physicalLocation"
                                            value={this.state.physicalLocation}
                                            onChange={this.changeHandler}
                                            label="Physical Address"
                                            type="text"
                                            className="mt-4"
                                            autoFocus={this.calculateAutofocus(
                                              3
                                            )}
                                          />
                                          <MDBInput
                                            name="city"
                                            value={this.state.city}
                                            onChange={this.changeHandler}
                                            label="city"
                                            type="text"
                                            id="materialInvalidCheck"
                                            className="mt-4"
                                          ></MDBInput>

                                          <MDBSelect
                                            className="customSelect"
                                            options={this.state.stateOptions}
                                            search
                                            required
                                            selected="Choose your option"
                                            label={this.state.state}
                                            getTextContent={
                                              this.handleStateSelectChange
                                            }
                                          />

                                          {this.state.state == "invalid" ? (
                                            <div className="invalid-feedback2">
                                              State is Required
                                            </div>
                                          ) : (
                                            ""
                                          )}

                                          {/* {this.state.city == undefined || this.state.city ? (
          ""
        ) : (
          <div className="invalid-feedback2">
            City is Required
          </div>
        )} */}

                                          <MDBInput
                                            name="zipcode"
                                            value={this.state.zipcode}
                                            onChange={this.changeHandler}
                                            label="Zipcode"
                                            type="text"
                                            id="materialInvalidCheck"
                                            className="mt-4"
                                          ></MDBInput>

                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-left"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(1)}
                                          >
                                            previous
                                          </MDBBtn>
                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-right"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(3)}
                                          >
                                            next
                                          </MDBBtn>
                                        </MDBCol>
                                      )}
                                      {this.state.formActivePanel3 === 3 && (
                                        <MDBCol md="12">
                                          <h3 className="font-weight-bold pl-0 my-4">
                                            <strong>
                                              Merchant Processing Information
                                            </strong>
                                          </h3>

                                          <MDBInput
                                            name="estimatedSalesVolume"
                                            value={
                                              this.state.estimatedSalesVolume
                                            }
                                            onChange={this.changeHandler}
                                            label="Estimated Sales Volume"
                                            type="number"
                                            className="mt-4"
                                            required
                                            icon="dollar-sign"
                                            iconClass="green-text"
                                          >
                                            {" "}
                                            <div className="invalid-feedback">
                                              Estimated Sales Volume Required
                                            </div>
                                          </MDBInput>
                                          <MDBInput
                                            name="estimatedAverageTicket"
                                            value={
                                              this.state.estimatedAverageTicket
                                            }
                                            onChange={this.changeHandler}
                                            label="Estimated Average Ticket"
                                            type="number"
                                            className="mt-4"
                                            iconClass="green-text"
                                            icon="file-invoice-dollar"
                                            required
                                          >
                                            {" "}
                                            <div className="invalid-feedback">
                                              Estimated Average Ticket Required
                                            </div>
                                          </MDBInput>

                                          <MDBInput
                                            name="cardPresentPercentage"
                                            value={
                                              this.state.cardPresentPercentage
                                            }
                                            onChange={this.changeHandler}
                                            label="Card Present Trasactions %"
                                            type="number"
                                            className="mt-4"
                                            iconClass="green-text"
                                            icon="cash-register"
                                          ></MDBInput>

                                          <MDBInput
                                            disabled
                                            name="cardNotPresentPercentage"
                                            value={
                                              this.state
                                                .cardNotPresentPercentage
                                            }
                                            label="Card NOT Present Trasactions %"
                                            type="number"
                                            className="mt-4"
                                            iconClass="green-text"
                                            icon="laptop"
                                          ></MDBInput>

                                          <MDBSelect
                                            options={
                                              this.state.merchantTypeOptions
                                            }
                                            id="materialInvalidCheck"
                                            selected={this.state.merchantType}
                                            getTextContent={
                                              this
                                                .handleMerchantTypeSelectChange
                                            }
                                          ></MDBSelect>

                                          <MDBRow center className="mb-4">
                                            <MDBInput
                                              label="Comparison quote with previous processor"
                                              type="checkbox"
                                              id="previousProcessorCheckboxChange"
                                              checked={
                                                this.state.previousProcessor
                                              }
                                              getValue={
                                                this
                                                  .handlePreviousProcessorCheckboxChange
                                              }
                                            />
                                          </MDBRow>

                                          {this.state.previousProcessor ===
                                          true ? (
                                            <MDBRow>
                                              <MDBCol>
                                                <h4>
                                                  Previous Processor Information
                                                </h4>
                                                <MDBInput
                                                  name="previousProcessorSalesVolume"
                                                  value={
                                                    this.state
                                                      .previousProcessorSalesVolume
                                                  }
                                                  onChange={this.changeHandler}
                                                  label="Sales Volume"
                                                  type="number"
                                                  className="mt-4"
                                                  required
                                                  icon="dollar-sign"
                                                  iconClass="green-text"
                                                />

                                                <MDBInput
                                                  name="previousProcessorDiscountRate"
                                                  value={
                                                    this.state
                                                      .previousProcessorDiscountRate
                                                  }
                                                  onChange={this.changeHandler}
                                                  label="Discount Rate (%)"
                                                  type="number"
                                                  className="mt-4"
                                                  required
                                                  icon="percent"
                                                  iconClass="green-text"
                                                />
                                                <MDBInput
                                                  name="previousProcessorTransactionFees"
                                                  value={
                                                    this.state
                                                      .previousProcessorTransactionFees
                                                  }
                                                  onChange={this.changeHandler}
                                                  label="Transaction Fees"
                                                  type="number"
                                                  className="mt-4"
                                                  required
                                                  icon="cash-register"
                                                  iconClass="green-text"
                                                />
                                                <MDBInput
                                                  name="previousProcessorOtherFees"
                                                  value={
                                                    this.state
                                                      .previousProcessorOtherFees
                                                  }
                                                  onChange={this.changeHandler}
                                                  label="Other Fees"
                                                  type="number"
                                                  className="mt-4"
                                                  required
                                                  icon="hand-holding-usd"
                                                  iconClass="green-text"
                                                />
                                                <MDBInput
                                                  name="previousProcessorTotalBillAmount"
                                                  value={
                                                    this.state
                                                      .previousProcessorTotalBillAmount
                                                  }
                                                  onChange={this.changeHandler}
                                                  label="Total Bill Amount"
                                                  type="number"
                                                  className="mt-4"
                                                  required
                                                  icon="file-invoice-dollar"
                                                  iconClass="green-text"
                                                />
                                                <MDBSelect
                                                  options={
                                                    this.state
                                                      .previousProcessorPricingModelOptions
                                                  }
                                                  id="materialInvalidCheck"
                                                  label={
                                                    this.state
                                                      .previousProcessorPricingModelOptionsType
                                                  }
                                                  getTextContent={
                                                    this
                                                      .handlePreviousProcessorPricingModelOptions
                                                  }
                                                ></MDBSelect>
                                              </MDBCol>
                                            </MDBRow>
                                          ) : (
                                            ""
                                          )}

                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-left"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(2)}
                                          >
                                            previous
                                          </MDBBtn>
                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-right"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(4)}
                                          >
                                            next
                                          </MDBBtn>
                                        </MDBCol>
                                      )}
                                      {this.state.formActivePanel3 === 4 && (
                                        <MDBCol md="12">
                                          <h3 className="font-weight-bold pl-0 my-4">
                                            <strong>Save and Continue</strong>
                                          </h3>
                                          <h2 className="teal-text text-center  my-4 mt-5 mb-5">
                                            Please save the merchant information
                                            to continue to product and pricing
                                            selection.
                                          </h2>

                                          <MDBBtn
                                            color="mdb-color"
                                            rounded
                                            className="float-left"
                                            onClick={this.handleNextPrevClick(
                                              3
                                            )(3)}
                                          >
                                            previous
                                          </MDBBtn>
                                          <MDBBtn
                                            color="success"
                                            rounded
                                            className="float-right"
                                            type="submit"
                                            // onClick={this.submitHandler}
                                          >
                                            Update Info
                                          </MDBBtn>
                                        </MDBCol>
                                      )}
                                    </form>
                                  </MDBCol>
                                </MDBRow>
                                <hr />
                              </div>
                            ) : (
                              <MDBRow className="text-center pt-5 pb-4">
                                <MDBCol middle>
                                  <MDBCardTitle className="pb-3 green-text">
                                    Merchant Information Has Been Saved!
                                  </MDBCardTitle>
                                  <MDBBtn
                                    onClick={this.showMerchantInformation}
                                    color="primary"
                                    rounded
                                  >
                                    Edit Merchant Information
                                  </MDBBtn>
                                </MDBCol>
                              </MDBRow>
                            )}
                            <MDBModal
                              isOpen={this.state.validationModal}
                              toggle={this.toggleValidationModal}
                            >
                              <MDBModalHeader
                                toggle={this.toggleValidationModal}
                              >
                                Missing Information{" "}
                              </MDBModalHeader>
                              <MDBModalBody>
                                <MDBRow center>
                                  <MDBIcon
                                    className="amber-text"
                                    size="3x"
                                    icon="exclamation-triangle"
                                  />
                                </MDBRow>
                                <p className="mt-3">
                                  Required fields are missing for the sections
                                  listed below, please review and resubmit.
                                </p>

                                {this.state.validationSections.map(
                                  (validationSections, index) => {
                                    return (
                                      <div className="red-text" key={index}>
                                        - {validationSections}
                                      </div>
                                    );
                                  }
                                )}
                              </MDBModalBody>
                              <MDBModalFooter>
                                <MDBBtn
                                  color="secondary"
                                  onClick={this.toggleValidationModal}
                                >
                                  Close
                                </MDBBtn>
                              </MDBModalFooter>
                            </MDBModal>
                          </MDBCardBody>
                        </MDBCard>
                        <MDBRow>
                          <hr></hr>
                        </MDBRow>
                        <MDBCard narrow className="mt-5">
                          <MDBCardImage
                            className="view view-cascade gradient-card-header primary-color"
                            tag="div"
                          >
                            <h3 className="h3-responsive">
                              Pricing Information
                            </h3>
                          </MDBCardImage>
                          <MDBCardBody>
                            <MDBRow center>
                              <h3 className="pt-5">Select Product(s)</h3>
                            </MDBRow>

                            <MDBRow className="p-5">
                              <MDBCol>
                                <MDBInput
                                  checked={this.state.merchantServices}
                                  label="Merch. Services"
                                  type="checkbox"
                                  id="merchantServices"
                                  getValue={this.handleMerchantCheckboxChange}
                                />
                              </MDBCol>
                              <MDBCol>
                                <MDBInput
                                  label="MCA"
                                  type="checkbox"
                                  id="cashAdvance"
                                  disabled
                                />
                              </MDBCol>

                              <MDBCol>
                                <MDBInput
                                  label="Equipment Sales"
                                  type="checkbox"
                                  id="equipmentSales"
                                  disabled
                                />
                              </MDBCol>
                              <MDBCol>
                                <MDBInput
                                  label="Leasing"
                                  type="checkbox"
                                  id="equipmentLeasing"
                                  disabled
                                />
                              </MDBCol>
                            </MDBRow>
                            {this.state.merchantServices == true
                              ? pricingModel
                              : ""}
                          </MDBCardBody>
                        </MDBCard>
                      </div>
                    </MDBCol>

                    <MDBCol md="4">
                      {" "}
                      <div style={{ height: "2650px" }}>
                        <div>
                          <div>
                            <div>
                              <MDBStickyContent
                                style={{ background: "#fff", height: "2500px" }}
                              >
                                <MDBSticky>
                                  {({ style }) => {
                                    return (
                                      <div
                                        style={{
                                          ...style,
                                        }}
                                      >
                                        <h2 className="text-center pt-3 pb-3">
                                          Current Pricing Summary
                                        </h2>
                                        <MDBNav tabs color="primary">
                                          {this.state.tierPricing == false &&
                                          this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.flatRate == false ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={false}
                                                role="tab"
                                              >
                                                Please Select a Pricing Model!
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}

                                          {this.state.interchangePassThru ==
                                          true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "1"
                                                }
                                                onClick={this.toggle("1")}
                                                role="tab"
                                              >
                                                INTX
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.flatRate == true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "2"
                                                }
                                                onClick={this.toggle("2")}
                                                role="tab"
                                              >
                                                FLAT
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.flatRatePlusDowngrade ==
                                          true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "3"
                                                }
                                                onClick={this.toggle("3")}
                                                role="tab"
                                              >
                                                FLT+D
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                          {this.state.tierPricing == true ? (
                                            <MDBNavItem
                                              style={{ margin: "auto" }}
                                            >
                                              <MDBNavLink
                                                link
                                                to="#"
                                                active={
                                                  this.state.activeItem === "4"
                                                }
                                                onClick={this.toggle("4")}
                                                role="tab"
                                              >
                                                TIER
                                              </MDBNavLink>
                                            </MDBNavItem>
                                          ) : (
                                            ""
                                          )}
                                        </MDBNav>
                                        <MDBTabContent
                                          className="card"
                                          activeItem={this.state.activeItem}
                                        >
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="1"
                                              role="tabpanel"
                                            >
                                              <p className="pt-2">
                                                {" "}
                                                <b>Interchange Passthrough</b>
                                              </p>

                                              <MDBTable small hover bordered>
                                                <MDBTableBody>
                                                  <tr>
                                                    <td>Sales Volume:</td>
                                                    <td>
                                                      $
                                                      {this.state.estimatedSalesVolume.toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Average Ticket:</td>
                                                    <td>
                                                      $
                                                      {
                                                        this.state
                                                          .estimatedAverageTicket
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Effective Rate:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="interchangeEffectiveRate">
                                                        {quoteInterchangeEffectiveRate.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Gross Margin:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="interchangeGrossMargin">
                                                        {quoteInterchangeMargin.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Merchant Total Fees (mon):
                                                    </td>
                                                    <td id="interchangeMerchantTotalFees">
                                                      {" "}
                                                      $
                                                      {quoteInterchangeEstMonthlyFees.toFixed(
                                                        2
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Est. Net Profit:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="interchangeEstimatedProfit">
                                                        $
                                                        {interchangeEstimatedProfit.toFixed(
                                                          2
                                                        )}
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </MDBTableBody>
                                              </MDBTable>

                                              <h5>Competition Score</h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        interchangeCompetitionScore
                                                      )
                                                        ? null
                                                        : interchangeCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart1"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          interchangeProfitabilityScore
                                                        )
                                                          ? null
                                                          : interchangeProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart2"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          interchangeProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : interchangeProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart3"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio1"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio6"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="2"
                                              role="tabpanel"
                                            >
                                              <p className="pt-2">
                                                {" "}
                                                <b>Flat Rate</b>
                                              </p>

                                              <MDBTable small hover bordered>
                                                <MDBTableBody>
                                                  <tr>
                                                    <td>Sales Volume:</td>
                                                    <td>
                                                      $
                                                      {this.state.estimatedSalesVolume.toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Average Ticket:</td>
                                                    <td>
                                                      $
                                                      {
                                                        this.state
                                                          .estimatedAverageTicket
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Effective Rate:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateEffectiveRate">
                                                        {" "}
                                                        {quoteFlatRateEffectiveRate.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Gross Margin:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateGrossMargin">
                                                        {quoteFlatRateMargin.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Merchant Total Fees (mon):
                                                    </td>
                                                    <td id="flatRateMerchantTotalFees">
                                                      {" "}
                                                      $
                                                      {quoteFlatRateEstMonthlyFees.toFixed(
                                                        2
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Est. Net Profit:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateEstimatedProfit">
                                                        $
                                                        {flatRateEstimatedProfit.toFixed(
                                                          2
                                                        )}
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </MDBTableBody>
                                              </MDBTable>

                                              <h5>Competition Score</h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        flatRateCompetitionScore
                                                      )
                                                        ? null
                                                        : flatRateCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart4"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          flatRateProfitabilityScore
                                                        )
                                                          ? null
                                                          : flatRateProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart5"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          flatRateProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : flatRateProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart6"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio7"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio8"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              tabId="3"
                                              role="tabpanel"
                                              className="text-center"
                                            >
                                              <p className="pt-2">
                                                {" "}
                                                <b>Flat Rate Downgrade</b>
                                              </p>

                                              <MDBTable small hover bordered>
                                                <MDBTableBody>
                                                  <tr>
                                                    <td>Sales Volume:</td>
                                                    <td>
                                                      $
                                                      {this.state.estimatedSalesVolume.toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Average Ticket:</td>
                                                    <td>
                                                      $
                                                      {
                                                        this.state
                                                          .estimatedAverageTicket
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Effective Rate:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateDowngradeEffectiveRate">
                                                        {" "}
                                                        {quoteFlatRateDowngradeEffectiveRate.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Gross Margin:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateDowngradeGrossMargin">
                                                        {quoteFlatRateDowngradeMargin.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Merchant Total Fees (mon):
                                                    </td>
                                                    <td id="flatRateDowngradeMerchantTotalFees">
                                                      $
                                                      {quoteFlatRateDowngradeEstMonthlyFees.toFixed(
                                                        2
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Est. Net Profit:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="flatRateDowngradeEstimatedProfit">
                                                        $
                                                        {flatRateDowngradeEstimatedProfit.toFixed(
                                                          2
                                                        )}
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </MDBTableBody>
                                              </MDBTable>

                                              <h5>Competition Score</h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        flatRateDowngradeCompetitionScore
                                                      )
                                                        ? null
                                                        : flatRateDowngradeCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart7"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          flatRateDowngradeProfitabilityScore
                                                        )
                                                          ? null
                                                          : flatRateDowngradeProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart8"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          flatRateDowngradeProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : flatRateDowngradeProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart9"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio3"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="9"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                          {this.state.interchangePassThru ==
                                            false &&
                                          this.state.flatRate == false &&
                                          this.state.flatRatePlusDowngrade ==
                                            false &&
                                          this.state.tierPricing == false ? (
                                            ""
                                          ) : (
                                            <MDBTabPane
                                              className="text-center"
                                              tabId="4"
                                              role="tabpanel"
                                            >
                                              <p className="pt-2">
                                                {" "}
                                                <b>Tier Pricing</b>
                                              </p>

                                              <MDBTable small hover bordered>
                                                <MDBTableBody>
                                                  <tr>
                                                    <td>Sales Volume:</td>
                                                    <td>
                                                      $
                                                      {this.state.estimatedSalesVolume.toLocaleString(
                                                        undefined,
                                                        {
                                                          maximumFractionDigits: 2,
                                                        }
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Average Ticket:</td>
                                                    <td>
                                                      $
                                                      {
                                                        this.state
                                                          .estimatedAverageTicket
                                                      }
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Effective Rate:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="tierPricingEffectiveRate">
                                                        {" "}
                                                        {quoteTierPricingEffectiveRate.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Gross Margin:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="tierPricingGrossMargin">
                                                        {quoteTierPricingMargin.toFixed(
                                                          2
                                                        )}
                                                        %
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      Merchant Total Fees (mon):
                                                    </td>
                                                    <td id="tierPricingMerchantTotalFees">
                                                      $
                                                      {quoteTierPricingEstMonthlyFees.toFixed(
                                                        2
                                                      )}
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>
                                                      <strong>
                                                        Est. Net Profit:
                                                      </strong>
                                                    </td>
                                                    <td>
                                                      <strong id="tierPricingEstimatedProfit">
                                                        $
                                                        {tierPricingEstimatedProfit.toFixed(
                                                          2
                                                        )}
                                                      </strong>
                                                    </td>
                                                  </tr>
                                                </MDBTableBody>
                                              </MDBTable>

                                              <h5>Competition Score</h5>

                                              <MDBRow center>
                                                <MDBCol>
                                                  <GaugeChart
                                                    // style={chartStyle}
                                                    // nrOfLevels={30}

                                                    colors={[
                                                      "#4caf50",
                                                      "#ffc107",
                                                      "#f44336",
                                                    ]}
                                                    hideText
                                                    arcsLength={[0.5, 0.1, 0.4]}
                                                    percent={
                                                      isNaN(
                                                        tierPricingCompetitionScore
                                                      )
                                                        ? null
                                                        : tierPricingCompetitionScore
                                                    }
                                                    needleColor={"#D3D3D3"}
                                                    textColor={"black"}
                                                    id="gauge-chart10"
                                                  />
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Competitive
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Not Competitive
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                              <hr />
                                              <h5>Profitability Score</h5>
                                              <MDBRow center>
                                                <MDBCol>
                                                  {this.state
                                                    .profitabilityScoreRadio ==
                                                  1 ? (
                                                    <GaugeChart
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      // style={chartStyle}
                                                      percent={
                                                        isNaN(
                                                          tierPricingProfitabilityScore
                                                        )
                                                          ? null
                                                          : tierPricingProfitabilityScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      hideText
                                                      textColor={"black"}
                                                      id="gauge-chart11"
                                                      arcsLength={[
                                                        0.2, 0.3, 0.5,
                                                      ]}
                                                    />
                                                  ) : (
                                                    <GaugeChart
                                                      // style={chartStyle}
                                                      colors={[
                                                        "#f44336",
                                                        "#ffc107",
                                                        "#4caf50",
                                                      ]}
                                                      hideText
                                                      percent={
                                                        isNaN(
                                                          tierPricingProfitabilityGoalScore
                                                        )
                                                          ? null
                                                          : tierPricingProfitabilityGoalScore
                                                      }
                                                      needleColor={"#D3D3D3"}
                                                      textColor={"black"}
                                                      id="gauge-chart12"
                                                      arcsLength={[
                                                        0.3, 0.2, 0.5,
                                                      ]}
                                                    />
                                                  )}
                                                </MDBCol>
                                              </MDBRow>
                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center">
                                                  <MDBFormInline>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="red-text pr-1"
                                                        icon="ban"
                                                      ></MDBIcon>
                                                      Less Profitable
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="amber-text pr-1"
                                                        icon="exclamation-triangle"
                                                      ></MDBIcon>
                                                      Average
                                                    </p>
                                                    <p className="p-3">
                                                      <MDBIcon
                                                        className="green-text pr-1"
                                                        icon="check"
                                                      ></MDBIcon>
                                                      Profitable
                                                    </p>
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>

                                              <p>Chart Type</p>
                                              <hr style={{ width: "50%" }}></hr>

                                              <MDBRow>
                                                <MDBCol className="d-flex justify-content-center radioButtonAlignments">
                                                  <MDBFormInline>
                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        1
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        1
                                                          ? true
                                                          : false
                                                      }
                                                      label="Market"
                                                      type="radio"
                                                      id="radio4"
                                                      containerClass="mr-5"
                                                    />

                                                    <MDBInput
                                                      onClick={this.onClickProfitabilityScoreOption(
                                                        2
                                                      )}
                                                      checked={
                                                        this.state
                                                          .profitabilityScoreRadio ===
                                                        2
                                                          ? true
                                                          : false
                                                      }
                                                      label="Goal"
                                                      type="radio"
                                                      id="radio5"
                                                      containerClass="mr-5"
                                                    />
                                                  </MDBFormInline>
                                                </MDBCol>
                                              </MDBRow>
                                            </MDBTabPane>
                                          )}
                                        </MDBTabContent>
                                      </div>
                                    );
                                  }}
                                </MDBSticky>
                              </MDBStickyContent>
                            </div>
                          </div>
                        </div>
                      </div>
                    </MDBCol>
                  </MDBRow>
                </div>
              )
            ) : this.state.authorized == false ? (
              <MDBRow center className="pt-5">
                <section className="my-5">
                  <h2 className="h1-responsive font-weight-bold text-center my-5">
                    Unable to Locate Quote
                    <p className="pt-5">
                      <MDBIcon
                        className="red-text"
                        size="2x"
                        icon="exclamation-triangle"
                      />
                    </p>
                  </h2>

                  <p className="lead grey-text w-responsive text-center mx-auto">
                    Either the quote was deleted or you are not authorize to
                    view this quote. Please contact technical support if the
                    problem persists.
                  </p>

                  <MDBRow center>
                    <MDBBtn rounded href="/Dashboard" color="primary" size="lg">
                      Dashboard
                    </MDBBtn>
                  </MDBRow>
                </section>
              </MDBRow>
            ) : (
              <Loading message={"Generating Merchant Quote..."} />
            )}

            {this.renderRedirectLogin()}
            {this.renderRedirectQuote()}
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default EditQuoteForm;
