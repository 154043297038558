import React, { useState, useEffect, useContext, Fragment } from "react";
import UserContext from "../../context/userContext";
import {
  getRecentQuotes,
  getAllQuotes,
  searchQuotes,
} from "../../services/quoteService";
import { getAllUsers } from "../../services/userService";
import "./RecentQuotesTable.css";
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardHeader,
  MDBDataTableV5,
  MDBBadge,
  MDBCardTitle,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBContainer,
  MDBSelect,
  MDBSelectInput,
  MDBSelectOptions,
  MDBSelectOption,
  MDBProgress,
  MDBInput,
  MDBDatePicker,
  MDBDatePickerV5,
} from "mdbreact";

const RecentQuoteTable = () => {
  const userContext = useContext(UserContext);
  let recentQuoteDataInitial = {
    columns: [
      {
        label: "Quote Number",
        field: "quoteId",
        sort: "asc",
      },
      {
        label: "Quote Status",
        field: "quoteStatus",
        sort: "asc",
      },
      {
        label: "Merchant DBA",
        field: "dba",
        sort: "asc",
      },

      {
        label: "Sales Volume",
        field: "estimatedSalesVolume",
        sort: "asc",
      },
      {
        label: "MCC Category",
        field: "mccCategory",
        sort: "asc",
      },
      {
        label: "Effective Rate",
        field: "effectiveRate",
        sort: "asc",
      },
      {
        label: "Gross Margin",
        field: "grossMargin",
        sort: "asc",
      },

      {
        label: "Approval Status",
        field: "approvalStatus",
        sort: "asc",
      },

      {
        label: "Quote Options",
        field: "open",
        sort: "asc",
      },
    ],
    rows: [],
  };
  const [recentQuoteData, setRecentQuoteData] = useState(
    recentQuoteDataInitial
  );

  const [listOfAgentNames, setListOfAgentNames] = useState("");

  const [quoteFilter, setQuoteFilter] = useState("all");
  const [dbaSearch, setDbaSearch] = useState("");
  const [agentNameSearch, setNameSearch] = useState("All");
  const [fromSearch, setFromSearch] = useState(
    "01/01/" + new Date().getFullYear()
  );
  const [toSearch, setToSearch] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    async function getAllUsersNames() {
      const names = await getAllUsers();
      const preparedUserListArray = prepareAgentListArray(names.data);
      setListOfAgentNames(preparedUserListArray);
    }

    async function getAllQuotesData() {
      const recentQuotes = await getAllQuotes();
      const prepareRecentArray = UpdateStateQuoteData(recentQuotes.data);

      if (quoteFilter === "all") {
        //return all quotes
        setRecentQuoteData({ ...recentQuoteData, rows: prepareRecentArray });
      } else {
        let filteredQuotes = recentQuotes.data.filter(
          (q) => q.quoteStatus == quoteFilter && q.quoteGenerated == true
        );
        const preparedFilteredArray = UpdateStateQuoteData(filteredQuotes);

        setRecentQuoteData({ ...recentQuoteData, rows: preparedFilteredArray });
      }
    }

    async function getRecentQuoteData() {
      const recentQuotes = await getRecentQuotes();
      const prepareRecentArray = UpdateStateQuoteData(recentQuotes.data);

      if (quoteFilter === "all") {
        //return all quotes
        setRecentQuoteData({ ...recentQuoteData, rows: prepareRecentArray });
      } else {
        let filteredQuotes = recentQuotes.data.filter(
          (q) => q.quoteStatus == quoteFilter && q.quoteGenerated == true
        );
        const preparedFilteredArray = UpdateStateQuoteData(filteredQuotes);

        setRecentQuoteData({ ...recentQuoteData, rows: preparedFilteredArray });
      }
    }

    {
      userContext.currentUser().role === 1 && getAllQuotesData();
    }
    {
      userContext.currentUser().role === 1 && getAllUsersNames();
    }

    {
      userContext.currentUser().role === 2 && getAllQuotesData();
    }

    {
      userContext.currentUser().role === 3 && getRecentQuoteData();
    }
  }, [quoteFilter]);

  function UpdateStateQuoteData(data) {
    let recentQuoteTempRowsArray = [];
    let quotes = data;

    for (const quote in data) {
      let tempQuote = {
        quoteId: data[quote].quoteId,
        dba: data[quote].dba,
        estimatedSalesVolume:
          "$" +
          data[quote].estimatedSalesVolume.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          }),
        mccCategory: data[quote].mccCategory,
        effectiveRate:
          data[quote].quoteGenerated === true
            ? data[quote].quotePricingModel === "Interchange Plus"
              ? data[quote].interchangeQuoteTotals.effectiveRate + "%"
              : data[quote].quotePricingModel === "Flat Rate"
              ? data[quote].flatRateQuoteTotals.effectiveRate + "%"
              : data[quote].quotePricingModel === "Flat Rate Plus Downgrade"
              ? data[quote].flatRatePlusDowngradeQuoteTotals.effectiveRate + "%"
              : data[quote].quotePricingModel === "Tier Pricing"
              ? data[quote].tierPricingQuoteTotals.effectiveRate + "%"
              : ""
            : "N/A",
        grossMargin:
          data[quote].quoteGenerated === true
            ? data[quote].quotePricingModel === "Interchange Plus"
              ? data[quote].interchangeQuoteTotals.grossMargin + "%"
              : data[quote].quotePricingModel === "Flat Rate"
              ? data[quote].flatRateQuoteTotals.grossMargin + "%"
              : data[quote].quotePricingModel === "Flat Rate Plus Downgrade"
              ? data[quote].flatRatePlusDowngradeQuoteTotals.grossMargin + "%"
              : data[quote].quotePricingModel === "Tier Pricing"
              ? data[quote].tierPricingQuoteTotals.grossMargin + "%"
              : ""
            : "N/A",
        open: [
          <a key={data[quote]._id} href={`/Quote/${data[quote]._id}/Edit`}>
            <i
              key={data[quote]._id}
              className="far fa-envelope-open mr-2 indigo-text"
              aria-hidden="true"
            ></i>
            View
          </a>,
          "",
        ],
        approvalStatus:
          data[quote].quoteStatus === "Approved" ? (
            <strong style={{ color: "green" }}>Approved</strong>
          ) : data[quote].quoteStatus === "Declined" ? (
            <strong style={{ color: "Red" }}>Declined</strong>
          ) : data[quote].quoteStatus === "Pending" ? (
            <strong style={{ color: "blue" }}>Pending</strong>
          ) : (
            ""
          ),
        quoteStatus:
          data[quote].quoteGenerated === true ? (
            <MDBBadge color="primary">Generated</MDBBadge>
          ) : (
            <MDBBadge color="orange">Draft</MDBBadge>
          ),
      };
      recentQuoteTempRowsArray.push(tempQuote);
    }

    return recentQuoteTempRowsArray;
    // this.setState({
    //   recentQuotes: quotes,
    //   recentQuoteData: {
    //     ...this.state.recentQuoteData,
    //     rows: recentQuoteTempRowsArray,
    //   },
    // });
  }

  function prepareAgentListArray(data) {
    let agentArrayListData = [{ checked: true, text: "All", value: "All" }];

    for (const user in data) {
      let userObject = {
        text: data[user].firstName + " " + data[user].lastName,
        value: data[user]._id,
      };
      agentArrayListData.push(userObject);
    }

    return agentArrayListData;
  }

  const handleSearchSubmit = async (e) => {
    e.preventDefault();

    const quotes = await searchQuotes({
      _id: agentNameSearch,
      dba: dbaSearch,
      startDate: fromSearch,
      endDate: toSearch,
    });

    const preparedSearchResultsArray = UpdateStateQuoteData(quotes.data);

    setRecentQuoteData({
      ...recentQuoteData,
      rows: preparedSearchResultsArray.reverse(),
    });
  };

  const clearQuoteTableResults = () => {
    async function getAllQuotesData() {
      const recentQuotes = await getAllQuotes();
      const prepareRecentArray = UpdateStateQuoteData(recentQuotes.data);

      if (quoteFilter === "all") {
        //return all quotes
        setRecentQuoteData({ ...recentQuoteData, rows: prepareRecentArray });
      } else {
        let filteredQuotes = recentQuotes.data.filter(
          (q) => q.quoteStatus == quoteFilter && q.quoteGenerated == true
        );
        const preparedFilteredArray = UpdateStateQuoteData(filteredQuotes);

        setRecentQuoteData({ ...recentQuoteData, rows: preparedFilteredArray });
      }
    }

    getAllQuotesData();
    setDbaSearch("");
    setNameSearch("All");

    setFromSearch("01/01/" + new Date().getFullYear());
    setToSearch(new Date().toLocaleDateString());

    async function getAllUsersNames() {
      const names = await getAllUsers();
      const preparedUserListArray = prepareAgentListArray(names.data);
      setListOfAgentNames(preparedUserListArray);
    }
    getAllUsersNames();
  };

  return (
    <MDBCard narrow className="text-center">
      <MDBCardHeader className="view view-cascade gradient-card-header blue-gradient d-flex justify-content-between align-items-center py-2 mx-4 mb-3">
        <div>
          <MDBBtn outline rounded size="sm" color="white" className="px-2">
            <i className="fa fa-th-large mt-0"></i>
          </MDBBtn>
        </div>
        <h5>
          <strong>Quote Management</strong>
        </h5>
        <div>
          <MDBBtn outline rounded size="sm" color="white" className="px-2">
            <i className="fa fa-info-circle mt-0"></i>
          </MDBBtn>
        </div>
      </MDBCardHeader>
      <MDBCardBody cascade>
        {userContext.currentUser().role === 1 && (
          <>
            <MDBContainer>
              <section>
                <MDBRow>
                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a
                        style={{ color: "black", textDecoration: "none" }}
                        href="/Quote/New"
                      >
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="blue-grey-text"
                                icon="file-invoice-dollar"
                              />{" "}
                              New Quote
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("Pending")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="light-blue-text"
                                icon="clock"
                              />{" "}
                              Pending
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("Approved")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="green-text"
                                icon="check-circle"
                              />{" "}
                              Approved
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("all")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="orange-text"
                                icon="bars"
                              />{" "}
                              View All
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </section>
            </MDBContainer>
            <div
              className="p-2 mx-4 mb-4"
              style={{ border: "1px solid #e0e0e9" }}
            >
              <form
                onSubmit={(e) => {
                  handleSearchSubmit(e);
                }}
                className="dba"
              >
                <MDBRow>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div>
                      <MDBInput
                        value={dbaSearch}
                        onChange={(e) => setDbaSearch(e.target.value)}
                        label="DBA"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <MDBSelect
                      getValue={(event) => {
                        setNameSearch(event);
                      }}
                      search
                      options={listOfAgentNames}
                      selected="Please select agent name"
                      label="Agent Name"
                    />
                  </MDBCol>
                  <MDBCol xl="3" lg="3" md="3" sm="3">
                    <div className="form-group md-form py-0 pt-2">
                      <small className="grey-text">from:</small>
                      <MDBDatePicker
                        disableFuture
                        className="my-0 d-inline ml-3"
                        value={fromSearch}
                        getValue={(event) => {
                          let newEvent = new Date(event);
                          setFromSearch(newEvent.toLocaleDateString());
                        }}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div className="form-group md-form py-0 pt-2">
                      <small className="grey-text">to:</small>
                      <MDBDatePicker
                        disableFuture
                        className="my-0 d-inline ml-3"
                        value={toSearch}
                        getValue={(event) => {
                          let newEvent = new Date(event);
                          setToSearch(newEvent.toLocaleDateString());
                        }}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="3" lg="3" md="3" sm="3">
                    <div className="form-group md-form py-0">
                      <Fragment>
                        <MDBBtn type="submit" color="primary">
                          Search
                        </MDBBtn>
                        <MDBBtn onClick={clearQuoteTableResults}>Clear</MDBBtn>
                      </Fragment>
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </div>
          </>
        )}
        {userContext.currentUser().role === 2 && (
          <>
            <MDBContainer>
              <section>
                <MDBRow>
                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a
                        style={{ color: "black", textDecoration: "none" }}
                        href="/Quote/New"
                      >
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="blue-grey-text"
                                icon="file-invoice-dollar"
                              />{" "}
                              New Quote
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("Pending")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="light-blue-text"
                                icon="clock"
                              />{" "}
                              Pending
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("Approved")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="green-text"
                                icon="check-circle"
                              />{" "}
                              Approved
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>

                  <MDBCol xl="3" md="6" className="mb-4">
                    <MDBCard cascade className="cascading-admin-card">
                      <a onClick={() => setQuoteFilter("all")}>
                        <MDBCardBody cascade className="text-center">
                          <MDBCardTitle>
                            <p className="mb-1">
                              <MDBIcon
                                size="2x"
                                className="orange-text"
                                icon="bars"
                              />{" "}
                              View All
                            </p>
                          </MDBCardTitle>
                        </MDBCardBody>
                      </a>
                    </MDBCard>
                  </MDBCol>
                </MDBRow>
              </section>
            </MDBContainer>
            <div
              className="p-2 mx-4 mb-4"
              style={{ border: "1px solid #e0e0e9" }}
            >
              <form
                onSubmit={(e) => {
                  handleSearchSubmit(e);
                }}
                className="dba"
              >
                <MDBRow>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div>
                      <MDBInput
                        value={dbaSearch}
                        onChange={(e) => setDbaSearch(e.target.value)}
                        label="DBA"
                        group
                        type="text"
                        validate
                        error="wrong"
                        success="right"
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <MDBSelect
                      getValue={(event) => {
                        setNameSearch(event);
                      }}
                      search
                      options={listOfAgentNames}
                      selected="Please select an agent"
                      label="Agent Name"
                    />
                  </MDBCol>
                  <MDBCol xl="3" lg="3" md="3" sm="3">
                    <div className="form-group md-form py-0 pt-2">
                      <small className="grey-text">from:</small>
                      <MDBDatePicker
                        disableFuture
                        className="my-0 d-inline ml-3"
                        value={fromSearch}
                        getValue={(event) => {
                          let newEvent = new Date(event);
                          setFromSearch(newEvent.toLocaleDateString());
                        }}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="2" lg="2" md="2" sm="2">
                    <div className="form-group md-form py-0 pt-2">
                      <small className="grey-text">to:</small>
                      <MDBDatePicker
                        disableFuture
                        className="my-0 d-inline ml-3"
                        value={toSearch}
                        getValue={(event) => {
                          let newEvent = new Date(event);
                          setToSearch(newEvent.toLocaleDateString());
                        }}
                      />
                    </div>
                  </MDBCol>
                  <MDBCol xl="3" lg="3" md="3" sm="3">
                    <div className="form-group md-form py-0">
                      <Fragment>
                        <MDBBtn type="submit" color="primary">
                          Search
                        </MDBBtn>
                        <MDBBtn onClick={clearQuoteTableResults}>Clear</MDBBtn>
                      </Fragment>
                    </div>
                  </MDBCol>
                </MDBRow>
              </form>
            </div>
          </>
        )}
        {userContext.currentUser().role === 3 && (
          <MDBContainer style={{ marginBottom: "10px" }}>
            <MDBRow center className="">
              <MDBCol className="p-2" md="3">
                <a href="/Quote/New">
                  <MDBCard wide cascade>
                    <MDBCardBody cascade className="text-center">
                      <MDBCardTitle>
                        <p className="mb-1">
                          <MDBIcon
                            size="2x"
                            className="blue-grey-text"
                            icon="file-invoice-dollar"
                          />{" "}
                          New Quote
                        </p>
                      </MDBCardTitle>
                    </MDBCardBody>
                  </MDBCard>
                </a>
              </MDBCol>

              <MDBCol className="p-2" md="3">
                <a onClick={() => setQuoteFilter("Pending")}>
                  <MDBCard wide cascade>
                    <MDBCardBody cascade className="text-center">
                      <MDBCardTitle>
                        <p className="mb-1">
                          <MDBIcon
                            size="2x"
                            className="lightblue-text"
                            icon="clock"
                          />{" "}
                          Pending
                        </p>
                      </MDBCardTitle>
                    </MDBCardBody>
                  </MDBCard>
                </a>
              </MDBCol>
              <MDBCol className="p-2" md="3">
                <a onClick={() => setQuoteFilter("Approved")}>
                  <MDBCard wide cascade>
                    <MDBCardBody cascade className="text-center">
                      <MDBCardTitle>
                        <p className="mb-1">
                          <MDBIcon
                            size="2x"
                            className="green-text"
                            icon="check-circle"
                          />{" "}
                          Approved
                        </p>
                      </MDBCardTitle>
                    </MDBCardBody>
                  </MDBCard>
                </a>
              </MDBCol>

              <MDBCol className="p-2" md="3">
                <a onClick={() => setQuoteFilter("all")}>
                  <MDBCard wide cascade>
                    <MDBCardBody cascade className="text-center">
                      <MDBCardTitle>
                        <p className="mb-1">
                          <MDBIcon
                            size="2x"
                            className="orange-text"
                            icon="bars"
                          />{" "}
                          View All
                        </p>
                      </MDBCardTitle>
                    </MDBCardBody>
                  </MDBCard>
                </a>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        )}

        <MDBDataTableV5
          responsive
          displayEntries={false}
          materialSearch
          data={recentQuoteData}
          sortable
          btn
          entries={10}
          searching={false}
          fullPagination={true}
        />
      </MDBCardBody>
    </MDBCard>
  );
};

export default RecentQuoteTable;
